import React, { useState, useRef } from "react";

const OtpInput = ({ length = 6, onChangeOtp }) => {
  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste action

    // Get the pasted text
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    // Optionally, you can insert the pasted text into an input/textarea
    console.log("Pasted Text:", pastedText);

    if (Number(pastedText)) {
      // setOtp(pastedText);
    }
  };
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (!value) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < length - 1 && value) {
      inputRefs.current[index + 1].focus(); // Move to next input
    }

    onChangeOtp(newOtp.join("")); // Pass OTP value to parent component
  };

  const handleBackspace = (element, index) => {
    const newOtp = [...otp];

    if (element.value === "") {
      // Move to previous input only if the current one is already empty
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else {
      // Clear the current input on backspace
      newOtp[index] = "";
      setOtp(newOtp);
      onChangeOtp(newOtp.join(""));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "5px",
        marginTop: "24px",
      }}
      onPaste={handlePaste}
    >
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          ref={(el) => (inputRefs.current[index] = el)}
          value={otp[index]}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) =>
            e.key === "Backspace" && handleBackspace(e.target, index)
          }
          style={{
            width: "52px",
            height: "52px",
            fontSize: "24px",
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: "10px",
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
