import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  CONFIGS,
  BOOL_DROPDOWN,
  EMPLOYMENT_TYPE,
  JOB_LOCATIONS,
  OVERALL_JOB_LOCATIONS,
} from "../../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import small_left from "../../../images/left-arr-white.svg";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import "./placementprofile.css";
import Profile_Edit from "../../../images/Profile_Edit.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
} from "reactstrap";
import { CCODS, TABS } from "../../../constants/constants";
import Select from "react-select";
import { setCurrentTab } from "../../../actions/utilAction";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveProfileDetails,
  uploadUserAgreement,
} from "../../../actions/profileAction";
import Loader from "../../utils/Loader.js";

const ProfessionalDetails = (props) => {
  // const dispatch = useDispatch();
  let fileInput = useRef(null);
  let profile = props.profile;
  let courses = props.courses;
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  // const [ifSubscribed, setIfSubscribed] = useState(profile.ifSubscribed);
  //states
  const [loading, setLoading] = useState(true);
  const [crs_pg_id, setCrsPgId] = useState(props.crs_pg_id);
  const [initialLoading, setIntitalLoading] = useState(false);
  const [percCompleted, setPercCompleted] = useState(0);
  const [learnerAccess, setLearnerAccess] = useState(false);
  const [redirectVal, setRedirectVal] = useState("");
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [openICRUpload, setOpenICRUpload] = useState(false);
  const [professionalDetails, setProfessionalDetails] = useState({
    working: "false",
    notice_period: 0,
    last_salary: 0,
    default_jobexp: {
      curr_working: true,
      company_name: "",
      job_title: "",
      job_location: "",
      employment_type: "0",
      year: 0,
      month: 0,
    },
    additional_jobexp: [],
    total_experience: {
      year: 0,
      month: 0,
    },
    preferences: {
      relocate: "0",
      night_shift: "0",
      intern: "0",
      contract: "0",
      loc_pref: [],
    },
    links: {
      linkedin: "",
      github: "",
      kaggle: "",
      icr_agreement: "",
    },
  });

  const [icrAgreeURL, setICRAgreeURl] = useState("");
  const [openICRConfirm, setOpenICRConfirm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  // const [anyInvalid,setAnyInvalid] = useState(false);
  const [errorMessageWorkEx, setErrorMessageWorkEx] = useState("");
  const [errorMessageDefComName, setErrorMessageDefComName] = useState("");
  const [errorMessageDefJobTitle, setErrorMessageDefJobTitle] = useState("");
  const [errorMessageDefJobLocation, setErrorMessageDefJobLocation] =
    useState("");
  const [errorMessageDefEmploymentType, setErrorMessageDefEmploymentType] =
    useState("");
  const [errorMessageNotice, setErrorMessageNotice] = useState("");
  const [errorMessageSalary, setErrorMessageSalary] = useState("");
  const [errorMessageCurrLoc, setErrorMessageCurrLoc] = useState("");
  const [errorMessageRelocate, setErrorMessageRelocate] = useState("");
  const [errorMessageLocPref, setErrorMessageLocPref] = useState("");
  const [errorMessageNight, setErrorMessageNight] = useState("");
  const [errorMessageIntern, setErrorMessageIntern] = useState("");
  const [errorMessageContract, setErrorMessageContract] = useState("");
  const [errorMessageICR, setErrorMessageICR] = useState("");
  console.log({ profile });
  let creationDate = new Date(profile.profile_map.cat).toString();
  let anyInvalid = false;
  useEffect(() => {
    props.setCurrentTab(TABS.PROFESSIONAL_DETAILS);
    if (props.uid == "") {
      props.getProfileInfo();
    }
    // const crs_pgid = searchParams.get("crs_pg_id");
    // setCrsPgId(crs_pgid);
    // const redirect = searchParams.get("redirect");
    // setRedirectVal(redirect);
    setIntitalLoading(true);
  }, []);
  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfile("professional-details", crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          if (
            res &&
            res.learnerInfo &&
            Object.keys(res.learnerInfo).length > 0
          ) {
            setProfessionalDetails((prevDetails) => ({
              ...prevDetails,
              working:
                res.learnerInfo?.working ?? prevDetails.working ?? "false",
              last_salary: res.learnerInfo?.last_salary ?? 0,
              notice_period: res.learnerInfo?.notice_period ?? 0,
              default_jobexp: {
                curr_working:
                  res.learnerInfo?.default_jobexp?.curr_working ??
                  prevDetails.default_jobexp.curr_working ??
                  false,
                company_name:
                  res.learnerInfo?.default_jobexp?.company_name ??
                  prevDetails.default_jobexp.company_name ??
                  "",
                job_title:
                  res.learnerInfo?.default_jobexp?.job_title ??
                  prevDetails.default_jobexp.job_title ??
                  "",
                job_location:
                  res.learnerInfo?.default_jobexp?.job_location ??
                  prevDetails.default_jobexp.job_location ??
                  "",
                employment_type:
                  res.learnerInfo?.default_jobexp?.employment_type ??
                  prevDetails.default_jobexp.employment_type ??
                  "0",
                year:
                  res.learnerInfo?.default_jobexp?.year ??
                  prevDetails.default_jobexp.year ??
                  0,
                month:
                  res.learnerInfo?.default_jobexp?.month ??
                  prevDetails.default_jobexp.month ??
                  0,
              },
              additional_jobexp: res.learnerInfo?.additional_jobexp,
              total_experience: {
                year:
                  res.learnerInfo?.total_experience?.year ??
                  prevDetails.total_experience.year ??
                  0,
                month:
                  res.learnerInfo?.total_experience?.month ??
                  prevDetails.total_experience.month ??
                  0,
              },
              preferences: {
                relocate:
                  res.learnerInfo?.preferences?.relocate ??
                  prevDetails.preferences.relocate ??
                  "0",
                night_shift:
                  res.learnerInfo?.preferences?.night_shift ??
                  prevDetails.preferences.night_shift ??
                  "0",
                intern:
                  res.learnerInfo?.preferences?.intern ??
                  prevDetails.preferences.intern ??
                  "0",
                contract:
                  res.learnerInfo?.preferences?.contract ??
                  prevDetails.preferences.contract ??
                  "0",
                loc_pref:
                  res.learnerInfo?.preferences?.loc_pref ??
                  prevDetails.preferences.loc_pref,
              },
              links: {
                linkedin:
                  res.learnerInfo?.links?.linkedin ??
                  prevDetails.links.linkedin ??
                  "",
                github:
                  res.learnerInfo?.links?.github ??
                  prevDetails.links.github ??
                  "",
                kaggle:
                  res.learnerInfo?.links?.kaggle ??
                  prevDetails.links.kaggle ??
                  "",
                icr_agreement:
                  res.learnerInfo?.links?.icr_agreement ??
                  prevDetails.links.icr_agreement ??
                  "",
              },
            }));
            setICRAgreeURl(res.learnerInfo?.links?.icr_agreement);
          }
          setPercCompleted(res.perc_completed);
          setLearnerAccess(res.learnerAccess);
          setLastFilledBlockIndex(res.lastFilledBlockIndex);
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);

  const validateProfessionalDetails = () => {
    if (professionalDetails.working === "true") {
      validateCompName();
      validateJobTitle();
      validateJobLocation();
      validateEmpType();
      validateSalary();
    }
    validateWorkEx();

    validateRelocate();
    validateLocPref();
    validateNight();
    validateIntern();
    validateContract();
    validateICR();
    console.log({ anyInvalid });
  };

  const validateWorkEx = () => {
    if (professionalDetails.working && professionalDetails.working === "0") {
      setErrorMessageWorkEx("Working Status cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageWorkEx("");
    }
  };

  const validateCompName = () => {
    if (
      professionalDetails.default_jobexp.company_name &&
      professionalDetails.default_jobexp.company_name.trim() === ""
    ) {
      setErrorMessageDefComName("Company Name cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageDefComName("");
    }
  };

  const validateJobTitle = () => {
    if (
      professionalDetails.default_jobexp.job_title &&
      professionalDetails.default_jobexp.job_title.trim() === ""
    ) {
      setErrorMessageDefJobTitle("Job Title cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageDefJobTitle("");
    }
  };

  const validateJobLocation = () => {
    if (
      professionalDetails.default_jobexp.job_location &&
      professionalDetails.default_jobexp.job_location.trim() === ""
    ) {
      setErrorMessageDefJobLocation("Job Location cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageDefJobLocation("");
    }
  };

  const validateEmpType = () => {
    if (
      professionalDetails.default_jobexp.employment_type &&
      professionalDetails.default_jobexp.employment_type === "0"
    ) {
      setErrorMessageDefEmploymentType("Employment Type cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageDefEmploymentType("");
    }
  };

  const validateSalary = () => {
    if (
      professionalDetails.last_salary === "" ||
      isNaN(professionalDetails.last_salary)
    ) {
      setErrorMessageSalary("Salary Field can't be empty");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageSalary("");
    }
  };
  const validateRelocate = () => {
    if (
      professionalDetails.preferences.relocate &&
      professionalDetails.preferences.relocate === "0"
    ) {
      setErrorMessageRelocate("Relocation Preferences cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageRelocate("");
    }
  };
  const validateLocPref = () => {
    if (
      professionalDetails.preferences.loc_pref &&
      professionalDetails.preferences.loc_pref.length == 0
    ) {
      setErrorMessageLocPref("Location Preferences cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageLocPref("");
    }
  };
  const validateNight = () => {
    if (
      professionalDetails.preferences.night_shift &&
      professionalDetails.preferences.night_shift === "0"
    ) {
      setErrorMessageNight("Night Shift preference cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageNight("");
    }
  };
  const validateIntern = () => {
    if (
      professionalDetails.preferences.intern &&
      professionalDetails.preferences.intern === "0"
    ) {
      setErrorMessageIntern("Internship preference cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageIntern("");
    }
  };
  const validateContract = () => {
    if (
      professionalDetails.preferences.contract &&
      professionalDetails.preferences.contract === "0"
    ) {
      setErrorMessageContract("Contractual Roles preference cant be blank");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageContract("");
    }
  };
  const validateICR = () => {
    if (
      !professionalDetails?.links?.icr_agreement ||
      professionalDetails?.links?.icr_agreement.trim() === ""
    ) {
      setErrorMessageICR("ICR Agreement needs to be uploaded");
      // setAnyInvalid(true)
      anyInvalid = true;
    } else {
      setErrorMessageICR("");
    }
  };

  const handleSaveProfessionalDetails = () => {
    console.log("Inside handle save");
    console.log({ anyInvalid });
    validateProfessionalDetails();
    console.log({ anyInvalid });
    // setTimeout(() => {
    if (!anyInvalid) {
      console.log({ anyInvalid });
      saveProfessionalDetails();
    } else {
      console.log({ anyInvalid });
      window.alert("Please fill all the required details before saving.");
    }
    // }, 200);

    setTimeout(() => {
      console.log({ anyInvalid });
    }, 3000);
  };

  const isValidLinkedinURL = (url) => {
    const linkedinRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
    return linkedinRegex.test(url);
  };

  const isValidGithubURL = (url) => {
    const linkedinRegex = /^(https?:\/\/)?(www\.)?github\.com\/.*$/;
    return linkedinRegex.test(url);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSubmit = () => {
    if (selectedFile) {
      chooseAgreement(selectedFile);
    } else {
      alert("Please select a file before submitting.");
    }
  };

  const handleCancel = () => {
    setOpenICRUpload(false);
    setSelectedFile(null);
  };

  const isValidKaggleURL = (url) => {
    const linkedinRegex = /^(https?:\/\/)?(www\.)?kaggle\.com\/.*$/;
    return linkedinRegex.test(url);
  };

  let chooseAgreement = (selectedFile) => {
    const file = selectedFile;
    let res = validAgreementFile(file);

    if (!res.valid) {
      window.alert(res.errorString);
    } else {
      setICRAgreeURl(file);
      uploadUserAgreement(file)
        .then(({ success, data, message }) => {
          // setIsLoading(false)
          console.log({ success: success });
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert("Something went wrong. Please try again.");
            }
          } else {
            setICRAgreeURl(data.img_url);
            handleLinksChange("icr_agreement", data.img_url);
            setOpenICRUpload(false);
            setOpenICRConfirm(true);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          // Handle any errors that occurred during the async operation
        });
    }
  };

  let validAgreementFile = (file) => {
    const supportedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    let errorList = [];

    if (file.size > 3 * 1024 * 1024) {
      errorList.push("File Size exceeds limit");
    }

    if (supportedFileTypes.indexOf(file.type) == -1) {
      errorList.push("File type is unsupported");
    }

    let errorString = errorList.join(" and ");
    if (errorList.length > 0) {
      return {
        valid: false,
        errorString,
      };
    } else {
      return {
        valid: true,
      };
    }
  };

  const saveProfessionalDetails = async () => {
    if (
      professionalDetails.links.linkedin &&
      professionalDetails.links.linkedin != ""
    ) {
      let valid = isValidLinkedinURL(professionalDetails.links.linkedin);
      if (!valid) {
        window.alert("Enter a valid LinkedIn URL or leave it blank");
        return;
      }
    }

    if (
      professionalDetails.links.github &&
      professionalDetails.links.github != ""
    ) {
      let valid = isValidGithubURL(professionalDetails.links.github);
      if (!valid) {
        window.alert("Enter a valid Github URL or leave it blank");
        return;
      }
    }

    if (
      professionalDetails.links.kaggle &&
      professionalDetails.links.kaggle != ""
    ) {
      let valid = isValidKaggleURL(professionalDetails.links.kaggle);
      if (!valid) {
        window.alert("Enter a valid Kaggle URL or leave it blank");
        return;
      }
    }

    if (
      professionalDetails?.links?.icr_agreement &&
      professionalDetails?.links?.icr_agreement == ""
    ) {
      window.alert("ICR Agreement needs to be added");
      return;
    }
    saveProfileDetails(
      crs_pg_id,
      "professional-details",
      professionalDetails
    ).then((res) => {
      if (res && res.data.success) {
        // history.push(`/dashboard/edit`);
        window.location.reload();
      }
    });
  };

  const handleWorkingStatusChange = (key, value) => {
    anyInvalid = true;
    // let newValue = value;
    // let updatedJobStatus = {
    //   ...professionalDetails.default_jobexp,
    //   [key]: newValue,
    // };
    let updatedDetails = {
      ...professionalDetails,
      working: value,
    };

    if (value === "false") {
      updatedDetails = {
        ...updatedDetails,
        additional_jobexp: [],
      };
    }

    setProfessionalDetails(updatedDetails);
  };

  const handleLastSalaryChange = (key, value) => {
    anyInvalid = true;
    // let newValue = value;
    // let updatedJobStatus = {
    //   ...professionalDetails.default_jobexp,
    //   [key]: newValue,
    // };
    let newValue = Math.max(0, Math.min(100, parseFloat(value)));

    let updatedDetails = {
      ...professionalDetails,
      last_salary: newValue,
    };

    setProfessionalDetails(updatedDetails);
  };

  const handleNoticePeriodChange = (key, value) => {
    anyInvalid = true;
    // let newValue = value;
    // let updatedJobStatus = {
    //   ...professionalDetails.default_jobexp,
    //   [key]: newValue,
    // };
    let newValue = Math.max(0, Math.min(100, parseFloat(value)));
    let updatedDetails = {
      ...professionalDetails,
      notice_period: newValue,
    };

    setProfessionalDetails(updatedDetails);
  };

  const handleRemoveAdditionalJobDetails = (index) => {
    setProfessionalDetails((prevDetails) => ({
      ...prevDetails,
      additional_jobexp: prevDetails.additional_jobexp.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleDefaultExperienceChange = (key, value) => {
    anyInvalid = true;
    let newValue = value;
    if (key === "year") {
      newValue = Math.max(0, Math.min(50, parseFloat(value)));
    }
    if (key === "month") {
      newValue = Math.max(0, Math.min(11, parseFloat(value)));
    }
    let updatedJobStatus = {
      ...professionalDetails.default_jobexp,
      [key]: newValue,
    };
    setProfessionalDetails({
      ...professionalDetails,
      default_jobexp: updatedJobStatus,
    });
  };

  const handleAddMoreWorkExperience = () => {
    setProfessionalDetails({
      ...professionalDetails,
      additional_jobexp: [
        ...(professionalDetails.additional_jobexp || []),
        {
          company_name: "",
          job_title: "",
          job_location: "",
          employment_type: "",
          year: 0,
          month: 0,
        },
      ],
    });
  };

  const handleAdditionalExperienceChange = (index, key, value) => {
    anyInvalid = true;
    let newValue = value;
    if (key === "year") {
      newValue = Math.max(0, Math.min(50, parseFloat(value)));
    }
    if (key === "month") {
      newValue = Math.max(0, Math.min(11, parseFloat(value)));
    }
    const updatedJobExp = [...professionalDetails.additional_jobexp];
    updatedJobExp[index][key] = newValue;
    setProfessionalDetails({
      ...professionalDetails,
      additional_jobexp: updatedJobExp,
    });
  };

  const handlePreferencesChange = (key, value) => {
    // setAnyInvalid(true)
    anyInvalid = true;
    setProfessionalDetails({
      ...professionalDetails,
      preferences: {
        ...professionalDetails.preferences,
        [key]: value,
      },
    });
  };
  const handleLocPrefChange = (e) => {
    // setAnyInvalid(true)
    anyInvalid = true;
    console.log(professionalDetails.preferences.loc_pref);
    const selectedLocations = e.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    setProfessionalDetails({
      ...professionalDetails,
      preferences: {
        ...professionalDetails.preferences,
        ["loc_pref"]: selectedLocations,
      },
    });
    console.log(
      "Updated Preferences:",
      professionalDetails.preferences.loc_pref
    );
  };

  const handleLinksChange = (key, value) => {
    // setAnyInvalid(true)
    anyInvalid = true;
    setProfessionalDetails({
      ...professionalDetails,
      links: {
        ...professionalDetails.links,
        [key]: value,
      },
    });
  };

  useEffect(() => {
    // Calculate the sum of years and months from default_jobexp
    const defaultExperienceYear = professionalDetails.default_jobexp.year || 0;
    const defaultExperienceMonth =
      professionalDetails.default_jobexp.month || 0;

    // Calculate the sum of years and months from additional_jobexp array
    const additionalExperience = Array.isArray(
      professionalDetails?.additional_jobexp
    )
      ? professionalDetails.additional_jobexp.reduce(
          (total, exp) => {
            return {
              year: total.year + (exp.year || 0),
              month: total.month + (exp.month || 0),
            };
          },
          { year: 0, month: 0 }
        )
      : { year: 0, month: 0 };

    // Calculate total years and months
    let totalYears = defaultExperienceYear + additionalExperience.year;
    let totalMonths = defaultExperienceMonth + additionalExperience.month;

    // Convert excess months into years
    if (totalMonths >= 12) {
      totalYears += Math.floor(totalMonths / 12);
      totalMonths = totalMonths % 12;
    }

    // Set the total experience in years and months
    setProfessionalDetails((prevDetails) => ({
      ...prevDetails,
      total_experience: {
        year: totalYears,
        month: totalMonths,
      },
    }));
  }, [
    professionalDetails.default_jobexp,
    professionalDetails.additional_jobexp,
  ]);

  const redirectToCourse = () => {
    const course = courses.find((course) => course.crs_pg_id === crs_pg_id);
    if (course) {
      history.push(`/view/?cid=${course._id}`);
    }
  };

  const [editable, setEditable] = useState(false);
  return (
    <>
      {!loading ? (
        <>
          {/* <div className="group-db-content-wrapper-2"> */}

          <p className="edit-profile-heading">
            <span style={{ fontSize: "20px" }}>Professional Details </span>
            <span
              onClick={(e) => setEditable(!editable)}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                border: "solid 1px",
                borderRadius: "8px",
                borderColor: "rgba(60,72,82,0.8)",
                fontSize: "12px",
                padding: "8px 12px",
                color: "rgba(60,72,82,0.8)",
              }}
            >
              <img width="12px" height="12px" src={Profile_Edit} alt="" />
              Edit
            </span>
          </p>
          {/* </div> */}
          {/* <div className="group-db-content-wrapper-3"> */}
          <div className="container-fluid dashboard-content-2 p-0 m-0">
            <div className=" profile-setting-container-row p-0 m-0">
              <div className="profile-setting-container-7 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 m-0">
                <div className="justify-content-center p-0 m-0">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width p-0 m-0">
                    <div className="edit-window-profile-details p-0 m-0">
                      <li className="row p-0 m-0">
                        <div className="col-md-12 p-0 m-0">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                          >
                            Do you have any work
                            experience?(Full-time+Internship)
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                className="form-control profile_box_updated"
                                value={professionalDetails.working}
                                onChange={(e) =>
                                  handleWorkingStatusChange(
                                    "working",
                                    e.target.value
                                  )
                                }
                              >
                                {BOOL_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}
                                      // disabled={cc.value == "_"}
                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageWorkEx != "" && (
                              <FormText color="danger">
                                {errorMessageWorkEx}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>

                      {professionalDetails.working &&
                        professionalDetails.working == "true" && (
                          <>
                            <li className="row">
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2"
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Last Drawn Salary? (in Lacs)
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <Input
                                    disabled={!editable}
                                    type="number"
                                    name="text"
                                    id="name"
                                    max="50"
                                    min="0"
                                    step="0.5"
                                    value={professionalDetails.last_salary}
                                    onChange={(e) =>
                                      handleLastSalaryChange(
                                        "last_salary",
                                        e.target.value
                                      )
                                    }
                                    className="profile_box_updated"
                                  />
                                  {errorMessageSalary != "" && (
                                    <FormText color="danger">
                                      {errorMessageSalary}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                            </li>
                            <li className="row" style={{ marginTop: "24px" }}>
                              <div className="col-md-12">
                                <span className="updated_heading_profile">
                                  Job Experience
                                </span>
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2"
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Company Name
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <Input
                                    disabled={!editable}
                                    type="text"
                                    name="text"
                                    id="name"
                                    value={
                                      professionalDetails.default_jobexp
                                        .company_name
                                    }
                                    onChange={(e) =>
                                      handleDefaultExperienceChange(
                                        "company_name",
                                        e.target.value
                                      )
                                    }
                                    className="profile_box_updated"
                                  />
                                  {errorMessageDefComName != "" && (
                                    <FormText color="danger">
                                      {errorMessageDefComName}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2"
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Job Title
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <div style={{ display: "flex" }}>
                                    <Input
                                      disabled={!editable}
                                      type="text"
                                      name="text"
                                      id="name"
                                      value={
                                        professionalDetails.default_jobexp
                                          .job_title
                                      }
                                      onChange={(e) =>
                                        handleDefaultExperienceChange(
                                          "job_title",
                                          e.target.value
                                        )
                                      }
                                      className="profile_box_updated"
                                    />
                                  </div>
                                  {errorMessageDefJobTitle != "" && (
                                    <FormText color="danger">
                                      {errorMessageDefJobTitle}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2"
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Job Location
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <div style={{ display: "flex" }}>
                                    <Input
                                      disabled={!editable}
                                      type="text"
                                      name="text"
                                      id="name"
                                      value={
                                        professionalDetails.default_jobexp
                                          .job_location
                                      }
                                      onChange={(e) =>
                                        handleDefaultExperienceChange(
                                          "job_location",
                                          e.target.value
                                        )
                                      }
                                      className="profile_box_updated"
                                    />
                                  </div>
                                  {errorMessageDefJobLocation != "" && (
                                    <FormText color="danger">
                                      {errorMessageDefJobLocation}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2 "
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Employment Type
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <select
                                    disabled={!editable}
                                    className="form-control profile_box_updated"
                                    value={
                                      professionalDetails.default_jobexp
                                        .employment_type
                                    }
                                    onChange={(e) =>
                                      handleDefaultExperienceChange(
                                        "employment_type",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {EMPLOYMENT_TYPE.map((cc, i) => {
                                      return (
                                        <option value={cc.value}>
                                          {cc.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {errorMessageDefEmploymentType != "" && (
                                    <FormText color="danger">
                                      {errorMessageDefEmploymentType}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                            </li>
                            <li>
                              <div
                                class="updated_heading_profile_2"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Enter Experience (in Years and Months)
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <FormGroup>
                                    <div
                                      style={{
                                        display: "flex",

                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Input
                                        disabled={!editable}
                                        type="number"
                                        name="text"
                                        id="name"
                                        max="50"
                                        min="0"
                                        step="0.01"
                                        value={
                                          professionalDetails.default_jobexp
                                            .year
                                        }
                                        onChange={(e) =>
                                          handleDefaultExperienceChange(
                                            "year",
                                            e.target.value
                                          )
                                        }
                                        style={{ width: "30%" }}
                                        className=""
                                      />
                                      <span className="prof-dtl-experience-span">
                                        Years
                                      </span>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className="col-md-3">
                                  <FormGroup>
                                    <div
                                      style={{
                                        display: "flex",

                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Input
                                        disabled={!editable}
                                        type="number"
                                        name="text"
                                        id="name"
                                        max="50"
                                        min="0"
                                        step="0.01"
                                        value={
                                          professionalDetails.default_jobexp
                                            .month
                                        }
                                        onChange={(e) =>
                                          handleDefaultExperienceChange(
                                            "month",
                                            e.target.value
                                          )
                                        }
                                        style={{ width: "30%" }}
                                        className=""
                                      />
                                      <span className="prof-dtl-experience-span">
                                        Months
                                      </span>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                            </li>
                            <li className="row">
                              <div className="col-md-12">
                                <label>
                                  <input
                                    disabled={!editable}
                                    type="checkbox"
                                    checked={
                                      professionalDetails.default_jobexp
                                        .curr_working
                                    }
                                    onChange={(e) =>
                                      handleDefaultExperienceChange(
                                        "curr_working",
                                        !professionalDetails.default_jobexp
                                          .curr_working
                                      )
                                    }
                                  />
                                  <span
                                    className="updated_heading_profile_2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    I currently work here.
                                  </span>
                                </label>
                              </div>
                            </li>
                            {professionalDetails.additional_jobexp &&
                              professionalDetails.additional_jobexp.length >
                                0 &&
                              professionalDetails.additional_jobexp.map(
                                (exp, index) => (
                                  <li
                                    className="row"
                                    key={index}
                                    style={{ marginTop: "24px" }}
                                  >
                                    <div className="col-md-6">
                                      <span className="prof-dtl-jobexp-heading">
                                        Job Experience {index + 2}
                                      </span>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      {editable && (
                                        <div
                                          className="link"
                                          onClick={() =>
                                            handleRemoveAdditionalJobDetails(
                                              index
                                            )
                                          }
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src="https://webcdn.imarticus.org/fsd-pro/Group938.svg"
                                            className="as-dl-logo-job"
                                            alt="Delete Post Grad"
                                          />
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        Company Name
                                        {/* <span
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          >
                                            *
                                          </span> */}
                                      </div>
                                      <FormGroup>
                                        <Input
                                          type="text"
                                          name="text"
                                          value={exp.company_name}
                                          onChange={(e) =>
                                            handleAdditionalExperienceChange(
                                              index,
                                              "company_name",
                                              e.target.value
                                            )
                                          }
                                          disabled={!editable}
                                          className="profile_box_updated"
                                        />
                                      </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        Job Title
                                        {/* <span
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          >
                                            *
                                          </span> */}
                                      </div>
                                      <FormGroup>
                                        <div style={{ display: "flex" }}>
                                          <Input
                                            disabled={!editable}
                                            type="text"
                                            name="text"
                                            value={exp.job_title}
                                            onChange={(e) =>
                                              handleAdditionalExperienceChange(
                                                index,
                                                "job_title",
                                                e.target.value
                                              )
                                            }
                                            className="profile_box_updated"
                                            style={{ width: "98%" }}
                                          />
                                        </div>
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        Job Location
                                        {/* <span
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          >
                                            *
                                          </span> */}
                                      </div>
                                      <FormGroup>
                                        <div style={{ display: "flex" }}>
                                          <Input
                                            type="text"
                                            name="text"
                                            value={exp.job_location}
                                            onChange={(e) =>
                                              handleAdditionalExperienceChange(
                                                index,
                                                "job_location",
                                                e.target.value
                                              )
                                            }
                                            className="profile_box_updated"
                                            style={{ width: "98%" }}
                                            disabled={!editable}
                                          />
                                        </div>
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        Employment Type
                                        {/* <span
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          >
                                            *
                                          </span> */}
                                      </div>
                                      <FormGroup>
                                        <select
                                          disabled={!editable}
                                          className="form-control profile_box_updated"
                                          value={exp.employment_type}
                                          onChange={(e) =>
                                            handleAdditionalExperienceChange(
                                              index,
                                              "employment_type",
                                              e.target.value
                                            )
                                          }
                                        >
                                          {EMPLOYMENT_TYPE.map((cc, i) => (
                                            <option key={i} value={cc.value}>
                                              {cc.label}
                                            </option>
                                          ))}
                                        </select>
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        Enter Experience (in Years and Months)
                                        {/* <span
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          >
                                            *
                                          </span> */}
                                      </div>
                                      <FormGroup>
                                        <div style={{ display: "flex" }}>
                                          <Input
                                            disabled={!editable}
                                            type="number"
                                            name="text"
                                            max="50"
                                            min="0"
                                            step="0.01"
                                            value={exp.year}
                                            className=""
                                            onChange={(e) =>
                                              handleAdditionalExperienceChange(
                                                index,
                                                "year",
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "30%" }}
                                          />
                                          <span className="prof-dtl-experience-span">
                                            Years
                                          </span>
                                        </div>
                                      </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className="updated_heading_profile_2"
                                        style={{ marginTop: "1rem" }}
                                      >
                                        &nbsp;
                                      </div>
                                      <FormGroup>
                                        <div style={{ display: "flex" }}>
                                          <Input
                                            disabled={!editable}
                                            type="number"
                                            name="text"
                                            max="50"
                                            min="0"
                                            step="0.01"
                                            value={exp.month}
                                            className=""
                                            onChange={(e) =>
                                              handleAdditionalExperienceChange(
                                                index,
                                                "month",
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "30%" }}
                                          />
                                          <span className="prof-dtl-experience-span">
                                            Months
                                          </span>
                                        </div>
                                      </FormGroup>
                                    </div>
                                  </li>
                                )
                              )}

                            {editable && (
                              <li className="row">
                                <div className="col-md-12">
                                  <div
                                    className="addques-newoption row "
                                    onClick={handleAddMoreWorkExperience}
                                    // disabled={
                                    //   professionalDetails.additional_jobexp
                                    //     ?.length >= 2
                                    // }
                                  >
                                    <span
                                      className=""
                                      style={{ cursor: "pointer" }}
                                    >
                                      + Add More Work Experience
                                    </span>
                                  </div>
                                </div>
                              </li>
                            )}

                            <li className="row">
                              <div className="col-md-6">
                                <div
                                  class="updated_heading_profile_2"
                                  htmlFor="thumbImg"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Select Notice Period (in months)
                                  <span
                                    style={{ color: "red", fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </div>
                                <FormGroup>
                                  <Input
                                    disabled={!editable}
                                    type="number"
                                    name="text"
                                    id="name"
                                    max="50"
                                    min="0"
                                    step="0.01"
                                    value={professionalDetails.notice_period}
                                    onChange={(e) =>
                                      handleNoticePeriodChange(
                                        "notice_period",
                                        e.target.value
                                      )
                                    }
                                    className="profile_box_updated"
                                  />
                                  {errorMessageSalary != "" && (
                                    <FormText color="danger">
                                      {errorMessageSalary}
                                    </FormText>
                                  )}
                                </FormGroup>
                              </div>
                            </li>
                            <li>
                              <div
                                class="updated_heading_profile_2"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Your Total Experience (Full-time + Internship)
                                is:
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <FormGroup>
                                    <div
                                      style={{
                                        display: "flex",

                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Input
                                        disabled={!editable}
                                        type="number"
                                        name="text"
                                        id="name"
                                        max="50"
                                        min="0"
                                        step="0.01"
                                        value={
                                          professionalDetails.total_experience
                                            .year
                                        }
                                        style={{ width: "30%" }}
                                        className=""
                                        readOnly
                                      />
                                      <span className="prof-dtl-experience-span">
                                        Years
                                      </span>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className="col-md-3">
                                  <FormGroup>
                                    <div
                                      style={{
                                        display: "flex",

                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Input
                                        disabled={!editable}
                                        type="number"
                                        name="text"
                                        id="name"
                                        max="50"
                                        min="0"
                                        step="0.01"
                                        value={
                                          professionalDetails.total_experience
                                            .month
                                        }
                                        style={{ width: "30%" }}
                                        readOnly
                                        className=""
                                      />
                                      <span className="prof-dtl-experience-span">
                                        Months
                                      </span>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                            </li>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid dashboard-content-2 p-0 m-0">
            <div className=" profile-setting-container-row p-0 m-0">
              <div className="profile-setting-container-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 m-0">
                <div className="justify-content-center p-0 m-0">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width p-0 m-0">
                    <div className="edit-window-profile-details p-0 m-0">
                      <li className="row p-0 m-0">
                        <div className="col-md-6 p-0 m-0">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Open to Relocate?
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                className="form-control profile_box_updated"
                                value={professionalDetails.preferences.relocate}
                                onChange={(e) =>
                                  handlePreferencesChange(
                                    "relocate",
                                    e.target.value
                                  )
                                }
                                // disabled={this.state.isVerify}
                              >
                                {BOOL_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}
                                      // disabled={cc.value == "_"}
                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageRelocate != "" && (
                              <FormText color="danger">
                                {errorMessageRelocate}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Location Preferences
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <Select
                              isDisabled={!editable}
                              options={JOB_LOCATIONS}
                              placeholder="Select Locations"
                              value={professionalDetails.preferences.loc_pref}
                              onChange={(e) => handleLocPrefChange(e)}
                              // isSearchable={true}
                              isMulti
                              className="profile_box_updated"
                            />
                            {errorMessageLocPref != "" && (
                              <FormText color="danger">
                                {errorMessageLocPref}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-6">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Open for Night Shifts/Rotational?
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                className="form-control profile_box_updated"
                                value={
                                  professionalDetails.preferences.night_shift
                                }
                                onChange={(e) =>
                                  handlePreferencesChange(
                                    "night_shift",
                                    e.target.value
                                  )
                                }
                                // disabled={this.state.isVerify}
                              >
                                {BOOL_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}
                                      // disabled={cc.value == "_"}
                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageNight != "" && (
                              <FormText color="danger">
                                {errorMessageNight}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-6">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Open for Internships?
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                className="form-control profile_box_updated"
                                value={professionalDetails.preferences.intern}
                                onChange={(e) =>
                                  handlePreferencesChange(
                                    "intern",
                                    e.target.value
                                  )
                                }
                                // disabled={this.state.isVerify}
                              >
                                {BOOL_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}
                                      // disabled={cc.value == "_"}
                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageIntern != "" && (
                              <FormText color="danger">
                                {errorMessageIntern}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-6">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Open for Contractual Roles?
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                className="form-control profile_box_updated"
                                value={professionalDetails.preferences.contract}
                                onChange={(e) =>
                                  handlePreferencesChange(
                                    "contract",
                                    e.target.value
                                  )
                                }
                                // disabled={this.state.isVerify}
                              >
                                {BOOL_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}
                                      // disabled={cc.value == "_"}
                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageContract != "" && (
                              <FormText color="danger">
                                {errorMessageContract}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid dashboard-content-2 p-0 m-0">
            <div className=" profile-setting-container-row p-0 m-0">
              <div className="profile-setting-container-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 m-0">
                <div className="justify-content-center p-0 m-0">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width p-0 m-0">
                    <div className="edit-window-profile-details p-0 m-0">
                      <li className="row p-0 m-0">
                        <div className="col-md-12 p-0 m-0">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            LinkedIn URL
                            {/* <span style={{ color: "red", fontSize: "18px" }}>
                            *
                          </span> */}
                          </div>
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id="name"
                              disabled={!editable}
                              value={professionalDetails.links.linkedin}
                              onChange={(e) =>
                                handleLinksChange("linkedin", e.target.value)
                              }
                              style={{ width: "98%" }}
                              className={
                                professionalDetails.links.linkedin != ""
                                  ? isValidLinkedinURL(
                                      professionalDetails.links.linkedin
                                    )
                                    ? "valid profile_box_updated"
                                    : "invalid profile_box_updated"
                                  : " profile_box_updated"
                              }
                            />
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-12">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Github Link
                          </div>
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id="name"
                              disabled={!editable}
                              value={professionalDetails.links.github}
                              onChange={(e) =>
                                handleLinksChange("github", e.target.value)
                              }
                              style={{ width: "98%" }}
                              className={
                                professionalDetails.links.github != ""
                                  ? isValidGithubURL(
                                      professionalDetails.links.github
                                    )
                                    ? "valid profile_box_updated"
                                    : "invalid profile_box_updated"
                                  : " profile_box_updated"
                              }
                            />
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-12">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Kaggle Link
                          </div>
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id="name"
                              value={professionalDetails.links.kaggle}
                              onChange={(e) =>
                                handleLinksChange("kaggle", e.target.value)
                              }
                              disabled={!editable}
                              className={
                                professionalDetails.links.kaggle != ""
                                  ? isValidKaggleURL(
                                      professionalDetails.links.kaggle
                                    )
                                    ? "valid profile_box_updated"
                                    : "invalid profile_box_updated"
                                  : " profile_box_updated"
                              }
                            />
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row">
                        <div className="col-md-12">
                          <div
                            class="updated_heading_profile_2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Signed ICR Agreement
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </div>
                          <FormGroup>
                            <div
                              className="upload-div-2 drop-zone"
                              onClick={() => setOpenICRUpload(true)}
                            >
                              <div className="upload-div-profile ">
                                <img
                                  src="https://webcdn.imarticus.org/Placement/material-symbols_upload1.svg"
                                  className="as-dl-logo-job-1"
                                  // onClick={() =>
                                  //   previewResumeClick(file.resume_url)
                                  // }
                                  alt=""
                                />
                              </div>
                              <div
                                className="upload-div-profile-content"
                                // onClick={() => fileInput.click()}
                              >
                                <div className="upload-div-txt">
                                  <div>Upload File</div>{" "}
                                  {/* <input
                                  type="file"
                                  name="file"
                                  id="fileInput"
                                  ref={(input) => {
                                    fileInput = input;
                                  }}
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    console.log("Uploaded file:", file);
                                    chooseAgreement(event);
                                  }}
                                /> */}
                                </div>
                                <div className="upload-div-txt-2">
                                  <p>
                                    Accepted format: .pdf .doc .docx | Max Size
                                    3MB
                                  </p>
                                </div>
                              </div>
                            </div>
                            {icrAgreeURL && (
                              <a
                                className="file-name"
                                href={icrAgreeURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Uploaded Document
                              </a>
                            )}
                            {errorMessageICR != "" && (
                              <FormText color="danger">
                                {errorMessageICR}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editable && (
            <div className="container-fluid dashboard-content-2 p-0 m-0">
              <div className=" profile-setting-container-row p-0 m-0">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 profile-next-btn p-0 m-0">
                  <div className="addcrit-footer">
                    <Button
                      className="jobs-table-btn-5"
                      // onClick={() => saveProfessionalDetails()}
                      onClick={() => handleSaveProfessionalDetails()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
          <Modal
            isOpen={openICRUpload}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "680px" }}
          >
            <ModalHeader>
              <div>
                <div className="job-aply-header">Upload ICR Agreement</div>
              </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <div>
                <div
                  className="resume-upload-img"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {!selectedFile ? (
                    <>
                      <Button
                        className="upload-resume-btn"
                        onClick={handleUploadClick}
                      >
                        <img
                          src="https://webcdn.imarticus.org/Placement/material-symbols_upload2.svg"
                          alt="Upload"
                          style={{ marginRight: "12px" }}
                        />
                        Upload
                      </Button>
                      <div className="resume-upload-txt">
                        Agreement should be in one of the valid formats only.
                        File size should be 3MB max
                      </div>
                    </>
                  ) : (
                    <iframe
                      src={URL.createObjectURL(selectedFile)}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        minHeight: "500px",
                      }}
                      frameBorder="0"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "right" }}>
              <Button className="apply-danger-btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="apply-success-btn" onClick={handleSubmit}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={openICRConfirm}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px" }}>
              <div>
                <p className="aply-success-txt ">
                  Agreement has been successfully uploaded!
                </p>
              </div>
              <div className="apply-success-div">
                <Button
                  onClick={() => {
                    setOpenICRConfirm(false);
                    setSelectedFile(null);
                  }}
                  className="apply-success-btn-1"
                >
                  Done
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, setCurrentTab };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfessionalDetails)
);
