import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIGS,
  SIDEBARS,
  GENDER_DROPDOWN,
} from "../../../constants/constants";
import pencil from "../../../images/pencil.svg";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import { withRouter, useHistory, useLocation } from "react-router";
import Loader from "../../utils/Loader.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveResumeDetails,
  deleteUserResume,
} from "../../../actions/profileAction";
import { ROUTES } from "../../../constants/routes.js";
import { setSidebar } from "../../../actions/utilAction.js";
import { Select } from "@material-ui/core";
import Profile_Edit from "../../../images/Profile_Edit.svg";
import EditProfileDetails from "../ProfileDetails/EditProfileDetails.js";
import BasicDetails from "../ProfileDetails/BasicDetails.js";
import EducationDetails from "../ProfileDetails/EducationDetails.js";
import ProfessionalDetails from "../ProfileDetails/ProfessionalDetails.js";
import ResumeDetails from "../ProfileDetails/ResumeDetails.js";
import PercentTooltipItem from "../../utils/PercentageTooltip.js";

const EditWindow = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const { primary_email } = useSelector((state) => state.primaryEmail);
  const [showIButton, setShowIButton] = useState(false);
  const [formData, setFormData] = useState({});
  const [percCompleted, setPercCompleted] = useState(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [courseChanged, setCourseChanged] = useState("");

  console.log({ profile });
  useEffect(() => {
    setIsLoading(true);

    if (searchParams.get("crs_pg_id")) {
      setCourseChanged(searchParams.get("crs_pg_id"));
    }

    var courseList = [];
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      tokn: utils.getAt(),
      d_id: utils.getDid(),
      cl: "W",
    };
    Axios.post(ROUTES.MY_CRS, data).then(async (my_courses) => {
      var res = my_courses.data.data;
      courseList = res.courses;
      if (Array.isArray(courseList)) {
        const enabledCourses = [];
        const seenIds = new Set();
        courseList.forEach((course) => {
          if (course.isPlacementEnabled && !seenIds.has(course.crs_pg_id)) {
            enabledCourses.push(course);
            seenIds.add(course.crs_pg_id);
          }
        });

        console.log({ enabledCourses });
        if (courseList.length > 0) {
          // if (enabledCourses.length === 1) {
          //   const crs_pg_id = enabledCourses[0].crs_pg_id;
          //   history.push(
          //     `/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`
          //   );
          // } else
          if (enabledCourses.length > 0) {
            const courseObjects = enabledCourses.map((course) => ({
              crs_pg_id: course.crs_pg_id,
              name: course.name,
            }));
            setCourses(courseObjects);
            setIsLoading(false);
            setTogglePDFDoc(true);
          } else {
            // setIsLoading(false);
            console.log("in else condition but why ");
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          }
        } else {
          setIsLoading(false);
        }
      }
    });
  }, [history]);

  let creationDate = new Date(profile.profile_map.cat).toString();

  const previewResumeClick = () => {
    setTogglePDFDoc(true);
  };

  const handleCourseClick = (crs_pg_id) => {
    console.log("HIT IT ::: ", crs_pg_id.target.value);
    if (crs_pg_id.target.value) {
      setCourseChanged(crs_pg_id.target.value);
      // Add or update the query parameter in the URL
      const params = new URLSearchParams(window.location.search);
      params.set("crs_pg_id", crs_pg_id.target.value);

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  };

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  // useEffect(() => {
  //   if (courseChanged) {
  //     console.log("This is it :: ", courseChanged);
  //     history.push(`/placement-module/profile_preview/?crs_pg_id=${courseChanged}`);
  //   }
  // }, [courseChanged]);

  const changeEmailSubscription = () => {
    console.log(" --- Changed the subscription --- ");
    Axios.post(`${CONFIGS.API_API_ENDPOINT}notifications/sbscrb_email`, {
      aid: utils.getAid(),
      tokn: utils.getAt(),
      ifSubscribed: !profile.ifSubscribed,
    })
      .then((res) => {
        console.log("Response successful!");
        dispatch({
          type: "CHANGE_SUBSCRIPTION",
          ifSubscribed: !profile.ifSubscribed,
        });
        return;
      })
      .catch((err) => {
        console.log("Error: ", err);
        return;
      });
  };

  useEffect(() => {
    console.log("useEffect ran");

    if (courseChanged != "") {
      getPlacementProfile("basic-details", courseChanged)
        .then(function (res) {
          console.log(res, ":response data");
          if (res && res.learnerInfo) {
            setFormData(res.learnerInfo);
            // setResume(res.learnerResume);
          }
          if (res && res.perc_completed) {
            setPercCompleted(res.perc_completed);
          }
          // setLearnerAccess(res.learnerAccess);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [courseChanged]);

  const [openProfileEditModal, setOpenProfileEditModal] = useState(false);
  const openProfileModal = (e) => {
    console.log("MyOpenEditProfileModal!");
    setOpenProfileEditModal(true);
  };

  console.log("\n This is just before render!\n");
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        {openProfileEditModal && (
          <EditProfileDetails
            openProfileEditModal={openProfileEditModal}
            setOpenProfileEditModal={setOpenProfileEditModal}
            pic={profile.ppic}
            name={profile.p_name}
            email={primary_email}
            mobile={profile.contact}
            timezone={profile.tz}
            uid={profile.uid}
            crs_pg_id={courseChanged}
          />
        )}
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            <div className=" profile-setting-container-row">
              <div
                className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                style={{ padding: "24px" }}
              >
                <div
                  className="row  displayOnWebOnly"
                  style={{ margin: "0px" }}
                >
                  <div
                    className="ps-main-content col-lg-12 col-md-12 col-sm-12 "
                    style={{ padding: "0px" }}
                  >
                    <p className="edit-profile-heading">
                      <span style={{ fontSize: "20px" }}>Basic Details </span>
                      <span
                        onClick={openProfileModal}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4px",
                          border: "solid 1px",
                          borderRadius: "8px",
                          borderColor: "rgba(60,72,82,0.8)",
                          fontSize: "12px",
                          padding: "8px 12px",
                          color: "rgba(60,72,82,0.8)",
                        }}
                      >
                        <img
                          width="12px"
                          height="12px"
                          src={Profile_Edit}
                          alt=""
                        />
                        Edit
                      </span>
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        className="img-container"
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="hello">
                          <img
                            className="profile-img"
                            src={profile.ppic}
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div
                        className="edit-window-profile-details"
                        style={{ paddingLeft: "48px" }}
                      >
                        <div className="row">
                          <div className="col-6">
                            <small
                              style={{
                                fontSize: "14px",
                                fontFamily: "Source Sans Pro",
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </small>
                            <p
                              style={{
                                fontSize: "18px",
                                fontFamily: "Source Sans Pro",
                                color: "#212a39",
                              }}
                            >
                              {profile.p_name}
                            </p>
                          </div>
                          <div className="col-6">
                            <small
                              style={{
                                fontSize: "14px",
                                fontFamily: "Source Sans Pro",
                                fontWeight: "600",
                              }}
                            >
                              UID
                            </small>
                            <p
                              style={{
                                fontSize: "18px",
                                fontFamily: "Source Sans Pro",
                                color: "#212a39",
                              }}
                            >
                              {profile.uid}
                            </p>
                          </div>
                          <div className="col-6">
                            <small
                              style={{
                                fontSize: "14px",
                                fontFamily: "Source Sans Pro",
                                fontWeight: "600",
                              }}
                            >
                              Primary Email
                            </small>
                            <p
                              style={{
                                fontSize: "18px",
                                fontFamily: "Source Sans Pro",
                                color: "#212a39",
                              }}
                            >
                              {primary_email}
                            </p>
                          </div>
                          <div className="col-6">
                            <small
                              style={{
                                fontSize: "14px",
                                fontFamily: "Source Sans Pro",
                                fontWeight: "600",
                              }}
                            >
                              Primary Mobile Number
                            </small>
                            <p
                              style={{
                                fontSize: "18px",
                                fontFamily: "Source Sans Pro",
                                color: "#212a39",
                              }}
                            >
                              {profile.ccode && "+"}
                              {profile.ccode} {profile.contact}
                            </p>
                          </div>
                          <div className="col-6">
                            <small
                              style={{
                                fontSize: "14px",
                                fontFamily: "Source Sans Pro",
                                fontWeight: "600",
                              }}
                            >
                              Preferred Timezone
                            </small>
                            <p
                              style={{
                                fontSize: "18px",
                                fontFamily: "Source Sans Pro",
                                color: "#212a39",
                              }}
                            >
                              {profile.tz}
                            </p>
                          </div>
                        </div>
                        {/* <ProfileDetailCard
                          detailName={"Name"}
                          detailValue={profile.p_name}
                          url={"/dashboard/edit/profile"}
                        />
                        <ProfileDetailCard
                          detailName={"Primary Email"}
                          detailValue={primary_email}
                          url={"/dashboard/edit/email"}
                          ifVerified={profile.ep_vrfy}
                        />

                        <ProfileDetailCard
                          detailName={"Registered Mobile Number"}
                          detailValue={profile.contact}
                          url={"/dashboard/edit/mobile"}
                          ifVerified={profile.contact != undefined}
                        />

                        <ProfileDetailCard
                          detailName={"Preferred Timezone"}
                          detailValue={profile.tz}
                          url={"/dashboard/edit/timezone"}
                        /> */}

                        {/* <p style={{ fontSize: "18px" }}>
                          <b>Subscribe to email notifications by Imarticus? </b>
                          {console.log("\n\n\n ifSubscribed :::: ", {
                            pifSub: profile.ifSubscribed,
                          })}
                          <input
                            checked={profile.ifSubscribed}
                            onChange={changeEmailSubscription}
                            className="form-check-input"
                            style={{ float: "right", position: "inherit" }}
                            type="checkbox"
                            name="subscribeToEmail"
                          />
                          (Currently {profile.ifSubscribed ? "On" : "Off"})
                        </p> */}
                        {/* <br /> */}
                        <div
                          style={{
                            color: "rgba(60, 72, 82, 0.75)",
                            textAlign: "center",
                          }}
                        >
                          <b>Account registered at </b>
                          {creationDate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 displayOnMobileOnly">
                  <p className="edit-profile-heading">
                    {/* Basic Details{" "} */}
                    <span
                      onClick={openProfileModal}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "14px",
                        color: "#636d75",
                        border: "1px solid #636d75",
                        borderRadius: "8px",
                        padding: "8px 12px",
                      }}
                    >
                      <img
                        width="15px"
                        height="15px"
                        src={Profile_Edit}
                        alt=""
                      />
                      &nbsp;&nbsp;Edit
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="img-container"
                      style={{ marginBottom: "16px" }}
                    >
                      <div className="hello">
                        <img
                          className="profile-img details"
                          src={profile.ppic}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <p>
                      UID: <b>{profile.uid}</b>
                    </p>
                  </div>
                  <div className="myDiv" style={{ marginTop: "10px" }}>
                    <label style={{ marginBottom: "0px" }}>Name</label>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {profile.p_name}
                    </p>
                  </div>
                  <div className="myDiv" style={{ marginTop: "10px" }}>
                    <label style={{ marginBottom: "0px" }}>Primary Email</label>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {primary_email}
                    </p>
                  </div>
                  <div className="myDiv" style={{ marginTop: "10px" }}>
                    <label style={{ marginBottom: "0px" }}>
                      Primary Mobile Number
                    </label>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {profile.contact}
                    </p>
                  </div>
                  <div className="myDiv" style={{ marginTop: "10px" }}>
                    <label style={{ marginBottom: "0px" }}>
                      Preferred Timezone
                    </label>
                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {profile.tz}
                    </p>
                  </div>
                  <div className="myDiv" style={{ margin: "10px auto" }}>
                    <p style={{ margin: "0 auto", width: "fit-content" }}>
                      <b>Account registered at</b>
                    </p>
                    <p style={{ margin: "0 auto", width: "fit-content" }}>
                      {creationDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="dashboard-content-2"
            style={{
              padding: "0px 48px",
            }}
          >
            <small
              style={{
                fontSize: "14px",
                fontFamily: "Source Sans Pro",
                fontWeight: "600",
              }}
            >
              Select to view course-specific profile
              <PercentTooltipItem
                id={"profil1"}
                text={"View Course Specifc Profile"}
              />
            </small>
            <select
              onChange={handleCourseClick}
              value={courseChanged}
              className="form-control"
              style={{
                width: "100%",
                height: "56px",
                marginTop: "8px",
                borderRadius: "8px",
              }}
            >
              <option value="">Select your course</option>
              {courses.map((c) => (
                <option value={c.crs_pg_id}>{c.name}</option>
              ))}
            </select>
          </div>
          {courseChanged && (
            <>
              <div
                className="container-fluid dashboard-content"
                style={{
                  paddingTop: "24px",
                  paddingBottom: "0px",
                }}
              >
                <div
                  className=" profile-setting-container-row"
                  style={{
                    borderRadius: "16px",
                  }}
                >
                  <div
                    className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{
                      borderRadius: "16px",
                    }}
                  >
                    <div
                      className="row justify-content-left profile-progress-bar"
                      style={{
                        padding: "24px",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#3C4852",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>Profile Completion</div>
                        <div className="">{percCompleted}%</div>
                      </div>
                      <div className="profile-bar-detail col-md-12">
                        <div className="row" style={{ height: "100%" }}>
                          <div className="profile-bar-progress col-md-12">
                            <div
                              className="profile-bar-completed"
                              style={{
                                width: `${percCompleted}%`,
                                height: "16px",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="container-fluid dashboard-content"
                style={{ paddingTop: "24px", paddingBottom: "0px" }}
              >
                <div className="profile-setting-container">
                  <BasicDetails key={courseChanged} crs_pg_id={courseChanged} />
                </div>
              </div>
              <div
                className="container-fluid dashboard-content"
                style={{ paddingTop: "24px", paddingBottom: "0px" }}
              >
                <div className="profile-setting-container">
                  <EducationDetails
                    key={courseChanged}
                    crs_pg_id={courseChanged}
                  />
                </div>
              </div>
              <div
                className="container-fluid dashboard-content"
                style={{ paddingTop: "24px", paddingBottom: "0px" }}
              >
                <div className="profile-setting-container">
                  <ProfessionalDetails
                    key={courseChanged}
                    crs_pg_id={courseChanged}
                  />
                </div>
              </div>
              <div
                className="container-fluid dashboard-content"
                style={{ paddingTop: "24px", paddingBottom: "0px" }}
              >
                <div className="profile-setting-container">
                  <ResumeDetails
                    key={courseChanged}
                    crs_pg_id={courseChanged}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
};
export default EditWindow;

const IButtonModal = ({ showIButton, setShowIButton }) => {
  return (
    <Modal
      toggle={(e) => setShowIButton(false)}
      isOpen={showIButton}
      size={"md"}
      style={{ minwidth: "400px" }}
      centered={true}
    >
      <ModalBody
        style={{
          maxHeight: "500px",
          justifyContent: "space-evenly",
          padding: "24px",
          textAlign: "center",
        }}
      >
        <h5
          style={{
            fontWeight: "bold",
            color: "black",
            marginBottom: "15px",
            letterSpacing: "0.16px",
            textAlign: "center",
          }}
        >
          Course Profiles
        </h5>
        <p style={{ textAlign: "center", color: "black" }}>
          Hello Learners!
          <br />
          We’ve updated our profile system. Now, your profiles are linked
          directly to the courses you're enrolled in, giving you a customized
          experience for each course. You can easily manage separate profiles
          for separate courses, entering data relevant only to them. This new
          feature ensures that your profile is personalized for every course you
          take and apply for jobs with.
        </p>

        <button
          onClick={(e) => setShowIButton(false)}
          style={{ background: "#035642", color: "white", textAlign: "center" }}
          className="btn"
        >
          Understood
        </button>
      </ModalBody>
    </Modal>
  );
};
