import React, { useEffect, useState, useRef } from "react";
import { withRouter, useHistory } from "react-router";
import { connect } from "react-redux";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./assignment.scss";
import Loader from "../utils/Loader.js";
import {
  updateQuizStatus,
  pluginIdentifier,
} from "../../actions/quizAction.js";
import {
  fetchSkillContentData,
  submitAssignmentData,
  updateAssignmentStatus,
  updateExternalAssignmentStatus,
} from "../../actions/assignmentAction.js";
import { Modal } from "reactstrap";

import { getSignedUrl } from "../../actions/docLectureActions.js";
import { downloadBase64File } from "../../utility/utils.js";
import { uploadDocumentsToAws } from "../../actions/assignmentAction.js";
import { CONFIGS } from "../../constants/constants.js";
import { Button } from "reactstrap";
import { BorderColor } from "@material-ui/icons";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { set } from "lodash";
const Assignment = ({
  course,
  getIndex,
  prevFid,
  setContentLoading,
  status,
  setNextButtonLoading,
}) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];
  console.log({ query });
  const history = useHistory();
  const fileInputRefP = useRef(null);
  const [assignment, setAssignment] = useState();
  const [userData, setUserData] = useState();
  const [isDeadlineExceeded, setIsDeadlineExceeded] = useState(false);
  const [assignmentDeadlineReached, setAssignmentDeadlineReached] =
    useState(false);
  const [isDeadlineSet, setIsDeadlineSet] = useState();
  const [remarks, setRemarks] = useState();
  const [plagCheck, setPlagCheck] = useState();
  const [wordCount, setWordCount] = useState();
  const [lastDt, setLastDt] = useState();
  const [serverTime, setServerTime] = useState();
  const [tz, setTz] = useState();
  const [subParameter, setSubParameter] = useState();
  const [resubmitButton, setResubmitButton] = useState(true);
  const [showSubmitAssignment, setShowSubmitAssignment] = useState();
  const [showDt, setShowDt] = useState();
  const [uploadAssignment, setUploadAssignment] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const [assignmentText, setAssignmentText] = useState("");
  const [fileName, setFileName] = useState();
  const [progress, setProgress] = useState(false);
  const [file, setFile] = useState();
  const [isLockedDeadline, setIsLockedDeadline] = useState(false);
  const [deadline, setDeadline] = useState();
  const [aboutResources, setAboutResources] = useState("About");
  const [resourceMap, setResourceMap] = useState();
  const [webModal, setWebModal] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [skill_tag_ids, setSkillTagIds] = useState([]);
  const [skillTagContent, setSkillTagContent] = useState({});
  const [chapterMap, setChapterMap] = useState({});
  const [showSkillDetail, setShowSkillDetail] = useState(false);
  const [curr_skill_tag_id, setCurrSkillTagId] = useState();
  const [tagRedirection, setTagRedirection] = useState(false);
  const [renderSkill, setRenderSkill] = useState(false);
  const [skillList, setSkillList] = useState();
  const [loading, setLoading] = useState(true);
  const [externAssignmentTitle, setExternAssignmentTitle] = useState("");
  const [externAssignmentLink, setExternAssignmentLink] = useState("");
  const [isGradeable, setIsGradeable] = useState(true);
  const [isNewTabOpen, setIsNewTabOpen] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);

  useEffect(() => {
    if (renderSkill) {
      renderSkillContent();
    }
  }, [renderSkill]);

  useEffect(() => {
    (async () => {
      setShowSkillDetail(false);
      setResubmitButton(true);
      setShowSubmitAssignment(false);
      setContentLoading(true);
      setLoading(true);
      getIndex();
      if (course) {
        await activateAssignment();
      }
      setLoading(false);
      setContentLoading(false);
    })();
  }, [prevFid]);
  const activateAssignment = async () => {
    var tempAssignmentData = course.assignments[fid];
    console.log({ tempAssignmentData });

    if (tempAssignmentData && tempAssignmentData.typ == 6) {
      console.log({ tempAssignmentData });
      setResubmitButton(false);
      setExternAssignmentTitle(tempAssignmentData.nm);

      setIsGradeable(tempAssignmentData.is_gradable);
      setIsNewTabOpen(tempAssignmentData.extern_open_newtab);
    }
    // if (tempAssignmentData.typ == 5) {
    //   setResubmitButton(false);
    // }
    if (
      tempAssignmentData &&
      tempAssignmentData.skill_tag_ids &&
      tempAssignmentData.skill_tag_ids.length > 0
    ) {
      setSkillTagIds([...tempAssignmentData.skill_tag_ids]);
    } else {
      setSkillTagIds([]);
    }

    setFileUrl(tempAssignmentData.file_url);
    setAssignmentText(tempAssignmentData.text);
    if (tempAssignmentData.etim && tempAssignmentData.etim != "") {
      var tempIsLockedDeadline =
        parseInt(tempAssignmentData.etim) < new Date().getTime() ? true : false;
      var tempFormattedDate = new Date(tempAssignmentData.etim).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      setIsLockedDeadline(tempIsLockedDeadline);
      setDeadline(tempFormattedDate);
    }
    const updateAssignmentStatusData = await updateAssignmentStatus(
      pid,
      cid,
      chid,
      fid
    );

    if (updateAssignmentStatusData.success && updateAssignmentStatusData.data) {
      setUserData(updateAssignmentStatusData.data);
      var tempUserData = updateAssignmentStatusData.data;
      console.log({ tempUserData });
      if (tempUserData) {
        setIsDeadlineExceeded(() => {
          return tempUserData.isDeadLineExceeded ? true : false;
        });
      }

      if (tempUserData.extern_assignment_link) {
        setExternAssignmentLink(tempUserData.extern_assignment_link);
      }

      setRemarks(tempUserData.remarks);
      setIsDeadlineSet(tempUserData.is_deadline_set);
      setPlagCheck(tempUserData.plag_check);
      setWordCount(tempUserData.word_count);
      setLastDt(tempUserData.last_dt);
      setServerTime(tempUserData.stim);
      setTz(tempUserData.tz);
      setSubParameter(tempUserData.sub_para_marks);
      if (!tempUserData.submission_date) {
        setResubmitButton(false);
        updateShowSubmitAssignment(tempUserData);
      } else {
        SubmitAssignment(tempUserData);
      }
      var serverTime = getEventDate(
        parseInt(tempUserData.stim),
        tempUserData.tz
      );
      if (tempUserData.last_dt) {
        setShowDt(() => {
          return getEventDate(parseInt(tempUserData.last_dt), tempUserData.tz);
        });
      }
      if (tempUserData.st_dl != "") {
        if (parseInt(tempUserData.st_dl) > parseInt(tempUserData.last_dt)) {
          setShowDt(() => {
            return getEventDate(parseInt(tempUserData.st_dl), tempUserData.tz);
          });
        }
      }
    }
    if (tempUserData.data && tempUserData.data.code == 207600) {
      setAssignmentDeadlineReached(true);
    }
    // if(tempAssignmentData && tempAssignmentData.extras && tempAssignmentData.extras.length > 0){
    //   for(var i = 0 ; i < tempAssignmentData.extras.length ; i++){
    //     await getSignedUrl
    //   }
    // }
    if (tempAssignmentData.extras && tempAssignmentData.extras.length > 0) {
      var tempResourceMap = {};
      for (var i = 0; i < tempAssignmentData.extras.length; i++) {
        var tempResource = await getSignedUrl(
          pid,
          gid,
          course.others[tempAssignmentData.extras[i]].url
        );
        if (tempResource.success) {
          tempResourceMap[tempAssignmentData.extras[i]] =
            course.others[tempAssignmentData.extras[i]];
          tempResourceMap[tempAssignmentData.extras[i]]["signed_url"] =
            tempResource.data.signed_url;
        }
      }
      setResourceMap(tempResourceMap);
    }
    setAssignment(tempAssignmentData);
  };

  const handleMouseEnter = () => {
    setShowPopUp(true);
  };

  const handleMouseLeave = () => {
    setShowPopUp(false);
  };

  const downLoadResources = function (url, name) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to Base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          console.log(base64Data); // Base64 representation of the image
          downloadBase64File(base64Data, name);
        };
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setLoading(false);
      });
  };

  const downloadFileAss = async (url) => {
    setLoading(true);
    getIndex();
    var assSignedUrl = await getSignedUrl(pid, gid, url);
    downLoadResources(assSignedUrl.data.signed_url, assSignedUrl.data.filename);
  };

  const updateShowSubmitAssignment = async (userdata) => {
    console.log("updateShowSubmitAssignment");
    if (userdata.upload_assignment) {
      setUploadAssignment(userdata.upload_assignment);
      // check if responded by mentor or not
      if (userdata.assignment_status) {
        // check if assignment is approved or not
        if (userdata.assignment_status == 1) {
          setShowSubmitAssignment(true);
        } else {
          setShowSubmitAssignment(false);
        }
      } else {
        setShowSubmitAssignment(true);
      }
    } else {
      setShowSubmitAssignment(false);
    }
  };

  const resubmitAssignment = async () => {
    setResubmitButton(false);
    setUploadAssignment(true);
    updateShowSubmitAssignment(userData);
  };

  const SubmitAssignment = async (userdata) => {
    if (userdata.upload_assignment) {
      setUploadAssignment(userdata.upload_assignment);
      if (userdata.assignment_status) {
        if (userdata.assignment_status == 1) {
          setShowSubmitAssignment(!resubmitButton);
        } else {
          setShowSubmitAssignment(false);
        }
      } else {
        setShowSubmitAssignment(!resubmitButton);
      }
    } else {
      setShowSubmitAssignment(false);
    }
  };

  const getEventDate = function (event_date, event_timezone_offset) {
    var d = new Date(event_date);

    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();

    return (
      day +
      " " +
      date +
      " " +
      month +
      " " +
      year +
      " " +
      hr +
      ":" +
      min +
      " " +
      ampm
    );
  };

  const deleteAttachment = function () {
    const response = window.confirm(
      " Are you sure you want to remove attachment ?"
    );
    console.log({ response });
    if (response) {
      fileInputRefP.current.value = "";
      setFile(null);
      setProgress(false);
      setFileUrl(null);
      setFileName(null);
    }
  };

  const handleFileSelect = async function (fileUp) {
    setProgress(true);
    var file = fileUp;
    setFileName(file.name);
    let fileData = await uploadDocumentsToAws(file);
    setUploadFileUrl(fileData.data.data);
  };

  const getExtension = function (filename) {
    return (
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename
    );
  };

  let openExternalAssignment = async () => {
    const updateAssignmentStatusData = await updateExternalAssignmentStatus(
      pid,
      cid,
      chid,
      fid,
      true
    );
    if (updateAssignmentStatusData.success && updateAssignmentStatusData.data) {
      const updateAssignmentStatusData = await updateAssignmentStatus(
        pid,
        cid,
        chid,
        fid
      );
      if (
        updateAssignmentStatusData.success &&
        updateAssignmentStatusData.data
      ) {
        setUserData(updateAssignmentStatusData.data);
      }
      setNextButtonLoading(true);
    }
  };

  const getWithoutExtension = function (filename) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  const handleInputFileClick = () => {
    fileInputRefP.current.click();
  };

  const submitAss = async () => {
    if (!assignmentText) {
      window.alert("Please complete the assignment before submitting!!!");
    } else {
      const data = await submitAssignmentData(
        pid,
        cid,
        fid,
        uploadFileUrl,
        assignmentText
      );
      console.log({ data });
      if (data.success) {
        window.alert("successfully submitted assignment!");
        SubmitAssignment(true);
        setNextButtonLoading(true);
        window.location.reload();
      } else {
        window.alert(data.response.data.message);
        return;
      }
    }
  };

  const fetchSkillContent = async (ev) => {
    setRenderSkill(false);
    var skillListDiv = document.getElementById("skillList");
    // Clear all existing elements in skillListDiv
    // while (skillListDiv.firstChild) {
    //   skillListDiv.removeChild(skillListDiv.firstChild);
    // }
    var curr_skill_tag_id = ev.target.value;
    setCurrSkillTagId(curr_skill_tag_id);
    var skill_name = ev.target.innerText;
    const skillListData = await fetchSkillContentData(
      pid,
      cid,
      curr_skill_tag_id
    );
    if (skillListData.success) {
      setSkillTagContent({ ...skillListData.data.skillById });
      setChapterMap({ ...skillListData.data.chapterLevelMap });
      setRenderSkill(true);
    }
  };

  const renderSkillContent = () => {
    setShowSkillDetail(false);
    let counter = 1;
    const skillList = [];

    for (const key in skillTagContent) {
      if (skillTagContent.hasOwnProperty(key)) {
        const content = skillTagContent[key];
        if (String(key) === String(curr_skill_tag_id)) {
          for (let i = 0; i < content.length; i++) {
            let levelId;
            if (chapterMap.hasOwnProperty(content[i].chapter_id)) {
              const levelDetail = chapterMap[content[i].chapter_id];
              levelId = levelDetail[0].levelId;
            }
            const contentId =
              content[i].type === "quiz"
                ? content[i].quiz_id
                : content[i].asgn_id;

            if (String(assignment.nm) !== String(content[i].name)) {
              skillList.push(
                <div
                  key={counter}
                  className="individualSkillContainer"
                  onClick={() => {
                    if (
                      !(
                        status.chapterLocking &&
                        status.chapterLocking[content[i].chapter_id] &&
                        (status.chapterLocking[content[i].chapter_id]
                          .isLocked ||
                          status.chapterLocking[content[i].chapter_id]
                            .isLockedDeadline)
                      )
                    ) {
                      var contentLock = false;
                      if (
                        status.contentLocking &&
                        status.contentLocking[content[i].chapter_id]
                      ) {
                        if (
                          status.contentLocking[content[i].chapter_id].length >
                          0
                        ) {
                          for (
                            var j = 0;
                            j <
                            status.contentLocking[content[i].chapter_id]
                              .length >
                            0;
                            j++
                          ) {
                            if (
                              status.contentLocking[content[i].chapter_id][j]
                                .fid == contentId &&
                              (status.contentLocking[content[i].chapter_id][j]
                                .isLocked ||
                                status.contentLocking[content[i].chapter_id][j]
                                  .isLockedDeadline)
                            ) {
                              contentLock = true;
                            }
                          }
                        }
                      }
                      if (!contentLock) {
                        window.open(
                          `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/${content[i].type}/${content[i].chapter_id}/${contentId}`,
                          "_self"
                        );
                      } else {
                        window.alert("The content is Locked");
                      }
                    }
                  }}
                >
                  <span className="individualSkillSpan">{counter}. </span>
                  <a className="individualSkill">{content[i].name}</a>
                  <img
                    src="https://webcdn.imarticus.org/aib/majesticons_open.svg"
                    alt="icon"
                    style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                    onClick={() => {
                      if (
                        !(
                          status.chapterLocking &&
                          status.chapterLocking[content[i].chapter_id] &&
                          (status.chapterLocking[content[i].chapter_id]
                            .isLocked ||
                            status.chapterLocking[content[i].chapter_id]
                              .isLockedDeadline)
                        )
                      ) {
                        var contentLock = false;
                        if (
                          status.contentLocking &&
                          status.contentLocking[content[i].chapter_id]
                        ) {
                          if (
                            status.contentLocking[content[i].chapter_id]
                              .length > 0
                          ) {
                            for (
                              var j = 0;
                              j <
                              status.contentLocking[content[i].chapter_id]
                                .length >
                              0;
                              j++
                            ) {
                              if (
                                status.contentLocking[content[i].chapter_id][j]
                                  .fid == contentId &&
                                (status.contentLocking[content[i].chapter_id][j]
                                  .isLocked ||
                                  status.contentLocking[content[i].chapter_id][
                                    j
                                  ].isLockedDeadline)
                              ) {
                                contentLock = true;
                              }
                            }
                          }
                        }
                        if (!contentLock) {
                          window.open(
                            `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/${content[i].type}/${content[i].chapter_id}/${contentId}`,
                            "_self"
                          );
                        } else {
                          window.alert("The content is Locked");
                        }
                      }
                    }}
                  />
                </div>
              );
              counter++;
            }
          }
          break;
        }
      }
    }
    setSkillList([...skillList]);
    setShowSkillDetail(true);
  };

  useEffect(() => {
    if (webModal) {
      document.body.style.overflow = "hidden";
    }
  }, [webModal]);
  return !loading ? (
    <div id="assignment-block">
      <div>
        {skill_tag_ids.length > 0 && (
          <div
            className="as-dl-skill"
            id="assignment-block-2"
            style={{ marginLeft: "56px" }}
          >
            {assignment.typ !== 4 &&
              skill_tag_ids.map((skill, index) => (
                <div key={index}>
                  <button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    id="skill-btn"
                    onClick={(event) => fetchSkillContent(event)}
                    value={skill.value}
                    label={skill.label}
                    style={{
                      display: "block",
                      float: "left",
                      padding: "4px 16px",
                      background: "rgba(1, 70, 68, 0.15)",
                      borderRadius: "100px",
                      color: "#212a39",
                      marginRight: "8px",
                      border: "none",
                      fontWeight: "500",
                    }}
                  >
                    {skill.label}
                  </button>
                  {showPopUp && (
                    <div className="showPopupSkill">
                      Click on skills to view linked contents.
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="assignment">
        {
          <div
            id="skillDetail"
            className="skillDetail"
            style={{
              display: !showSkillDetail ? "none" : "block",
              position: "absolute",
              zIndex: "1",
            }}
          >
            <div className="skillHeading">
              <p id="skillHeading">Explore all content of this skill</p>
              <div>
                <img
                  src="https://cdn.pegasus.imarticus.org/chapter-controller/closemodal.png"
                  style={{ paddingRight: "17px" }}
                  onClick={() => {
                    setShowSkillDetail(false);
                    setRenderSkill(false);
                  }}
                  alt="Close"
                />
              </div>
            </div>
            <div id="skillList">
              <br />
              {skillList}
            </div>
          </div>
        }
        {isDeadlineExceeded && !remarks && assignment.typ !== 6 && (
          <div className="as-dl-msg">
            The Assignment submission date has been expired on{" "}
            {showDt ? showDt : ""}
          </div>
        )}
        {isDeadlineExceeded && !remarks && assignment.typ === 6 && (
          <div>
            <div className="no-asgn-box">
              <div className="asgn-text"></div>
              <div className="asgn-text"></div>
              <div
                className="as-dl-msg-ext"
                style={{
                  marginTop: "5px",
                  width: "90%",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                The Assignment submission date has been expired on{" "}
                {showDt ? showDt : ""}
              </div>
              <div className="asgn-text">
                <div style={{ marginTop: "108px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      {" "}
                      <p>
                        {externAssignmentTitle ? externAssignmentTitle : ""}
                      </p>
                    </div>
                    <Button className="btn-text">
                      {userData ? "View Again" : "Start"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
          </div>
        )}

        {isDeadlineExceeded == false &&
          isDeadlineSet == true &&
          (!userData.submission_date || !userData.assignment_status != 4) && (
            <div className="as-tt-msg">
              Your assignment deadline is approaching. Kindly submit before the
              deadline. Due Date : {showDt}
            </div>
          )}
        {!userData.isNoSubmissionAssignment &&
          userData.submission_date &&
          !userData.assignment_data &&
          userData.assignment_status === 4 && (
            <div className="as-tt-msg-2">
              You have not submitted this assignment
            </div>
          )}

        {assignment.typ !== 4 && assignment.typ !== 6 && (
          <div className="a-card">
            <h2 className="assignment-heading">Instructor Notes</h2>
            <p dangerouslySetInnerHTML={{ __html: assignment.dsc }}></p>
            {assignment.url && (
              <div>
                <a
                  href={assignment.url}
                  className="docLectureResouceListItemLink"
                  target="_blank"
                >
                  {assignment.url}
                </a>
              </div>
            )}
            <br />
            {assignment.extras.length > 0 && (
              <div>
                <h2 className="assignment-heading">Resources</h2>
                <ul className="docLectureResouceList">
                  {assignment.extras.map((extra, index) => (
                    <li key={index} className="docLectureResouceListItem">
                      {course.others[extra].url && (
                        <div
                          className="docLectureResouceListItemLink"
                          onClick={() => {
                            setLoading(true);
                            downLoadResources(
                              resourceMap[extra].signed_url,
                              course.others[extra].nm
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Download{" "}
                          {assignment.nm == course.others[extra].nm
                            ? `Resource ${assignment.nm}`
                            : course.others[extra].nm}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {(isDeadlineExceeded == false ||
          (isDeadlineExceeded == true && remarks)) && (
          <div>
            {userData.show_assignment_details && assignment.typ != 6 && (
              <div className="a-card">
                <h2 className="assignment-heading">My Result</h2>

                {userData.upload_assignment &&
                  userData.assignment_data &&
                  (userData.assignment_data.file ||
                    userData.assignment_data.text) && (
                    <div className="latest-submission-block">
                      <div className="row">
                        <div className="col-sm-8 col-xs-8">
                          <h5 className="latest-submission-text">
                            Latest Submission
                          </h5>
                        </div>
                        <div className="col-sm-4 col-xs-4 view-button">
                          <button
                            className="btn view"
                            onClick={() => {
                              setWebModal(true);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                      <hr className="horizontal-line" />
                      <div className="icon-block">
                        <img
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/timeicon.png"
                          style={{ paddingRight: "7px" }}
                        />
                        {userData.submission_time}
                        <img
                          className="splitter"
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/path-3.png"
                          style={{ padding: "7px" }}
                        />
                        <directive className="date-alignment">
                          <img
                            src="https://cdn.pegasus.imarticus.org/chapter-controller/dateicon.png"
                            style={{ paddingRight: "7px" }}
                          />
                          {userData.submission_date}
                        </directive>
                      </div>
                    </div>
                  )}

                {assignment.typ !== 6 && (
                  <>
                    {userData.assignment_status ? (
                      <div>
                        <div
                          className="status-block"
                          style={{ marginBottom: "76px" }}
                        >
                          <div className="row">
                            <div className="col-md-8 col-xs-7">
                              {userData.assignment_status === 2 ? (
                                <div>
                                  <h5 className="score">
                                    Status :
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/chapter-controller/approve.png"
                                      style={{ padding: "2px" }}
                                    />
                                    <k style={{ color: "#6cb776" }}>Approved</k>
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  <h5 className="score">
                                    Status :
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/chapter-controller/rejecticon.png"
                                      style={{ padding: "2px" }}
                                    />
                                    <k style={{ color: "#e94b35" }}>
                                      Rejected{" "}
                                    </k>
                                  </h5>
                                </div>
                              )}
                            </div>
                            {userData.assignment_status === 1 &&
                              resubmitButton && (
                                <div className="col-md-4 col-xs-5 re-submit">
                                  <button
                                    className="btn btn-primary"
                                    onClick={resubmitAssignment}
                                    disabled={!resubmitButton}
                                  >
                                    Re-submit
                                  </button>
                                </div>
                              )}
                          </div>

                          <div>
                            <div style={{ display: "flex" }}>
                              <div>Grade :</div>
                              <div>
                                {userData.sub_para_marks &&
                                  userData.sub_para_marks.length > 0 &&
                                  userData.sub_para_marks.map(
                                    (sub_para, index) => (
                                      <p
                                        key={index}
                                        className="instructor-remarks-text"
                                        style={{ marginTop: "2%" }}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {sub_para.nm}
                                        &nbsp;&nbsp;:&nbsp;&nbsp;
                                        {sub_para.marks_obn}
                                      </p>
                                    )
                                  )}
                                {userData.grades !== 0 && (
                                  <h5
                                    className="score"
                                    style={{
                                      marginTop: "1%",
                                      color: "#39bb71",
                                    }}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Marks
                                    &nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b>{userData.grades}</b>
                                  </h5>
                                )}
                              </div>
                            </div>

                            {userData.grades === 0 &&
                              userData.assignment_status === 1 && (
                                <h5 className="score">
                                  Grade : <b>{userData.grades}</b>
                                </h5>
                              )}

                            <h5 className="instructor-remarks">
                              Instructor remarks
                            </h5>
                            <div
                              style={{ whiteSpace: "pre-wrap" }}
                              className="instructor-remarks-text"
                            >
                              {userData.remarks}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "12px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <h5 className="note-style">
                          Your assignment is being reviewed by our mentors. You
                          can check the status within a day.
                        </h5>
                        {resubmitButton && (
                          <button
                            className="btn btn-primary"
                            onClick={resubmitAssignment}
                            disabled={!resubmitButton}
                          >
                            Re-submit
                          </button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div>
              {webModal && (
                <div
                  id="overlay"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    left: "0px",
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="md-dialog-container"
                    id="myDialog"
                    style={{
                      position: "absolute",
                      background: "white",
                    }}
                  >
                    <md-dialog>
                      <div
                        className="row"
                        style={{
                          borderBottom: "1px solid #c8c8c9",
                          padding: "1px",
                        }}
                      >
                        <div className="col-md-6">
                          <h4
                            style={{
                              color: "#5386c5",
                              fontFamily: "Source Sans Pro",
                              fontSize: "18px",
                            }}
                          >
                            My Result
                          </h4>
                        </div>
                        {userData.upload_assignment && (
                          <div
                            className="col-md-6 icon-style"
                            style={{ textAlign: "right" }}
                          >
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/timeicon.png"
                              style={{ paddingRight: "7px" }}
                            />
                            {userData.submission_time}
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/path-3.png"
                              style={{ padding: "7px" }}
                            />
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/dateicon.png"
                              style={{ paddingRight: "7px" }}
                            />
                            {userData.submission_date}
                            <button
                              className="btn close-button"
                              onClick={() => {
                                document.body.style.overflow = "auto";
                                setWebModal(false);
                              }}
                            >
                              Close
                            </button>
                          </div>
                        )}
                      </div>
                      {userData.assignment_data.text && (
                        <div className="submitted-text">
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {userData.assignment_data.text}
                          </div>
                        </div>
                      )}
                      {userData.assignment_data.file && (
                        <div className="submitted-attachment">
                          <div className="col-md-10">
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/attachmenticon.png"
                              style={{ padding: "10px" }}
                            />
                            Attachment
                            <span
                              onClick={() =>
                                downloadFileAss(userData.assignment_data.file)
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/chapter-controller/downloadicon.png"
                                style={{ padding: "20px" }}
                              />
                            </span>
                            <a id="downloadPreviousFile_link"></a>
                          </div>
                        </div>
                      )}
                    </md-dialog>
                  </div>
                </div>
              )}
            </div>
            {showSubmitAssignment && assignment.typ != 6 ? (
              <div className="a-card">
                <h2 className="assignment-heading">My Assignment</h2>
                <textarea
                  placeholder="Write Here"
                  value={assignmentText}
                  onChange={(e) => {
                    setAssignmentText(e.target.value);
                    // Update assignment text
                    // You need to implement the logic to update the assignment text
                  }}
                  rows="10"
                ></textarea>
                <div
                  className=" attachments"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {!plagCheck && (
                    <div style={{ display: "flex", columnGap: "5%" }}>
                      <div className="col-md-3 col-xs-6">
                        <div className="attach" onClick={handleInputFileClick}>
                          <h3>
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/attachmenticon.png"
                              alt="attachment"
                            />
                            Attach Files
                          </h3>
                          <input
                            type="file"
                            ref={fileInputRefP}
                            id="photo"
                            hidden
                            className="attach"
                            onChange={(e) => {
                              handleFileSelect(e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                      <div className="hidden-xs col-md-3">
                        {fileName && (
                          <h3 className="mw80">
                            {getWithoutExtension(fileName)}
                          </h3>
                        )}
                        {fileName && <h3>{"." + getExtension(fileName)}</h3>}
                      </div>
                      <div className="hidden-xs col-md-3">
                        {progress && (
                          <progress
                            value={`${uploadFileUrl ? 100 : 0}`}
                          ></progress>
                        )}
                        {fileName && (
                          <img
                            onClick={deleteAttachment}
                            style={{ cursor: "pointer" }}
                            src="https://cdn.pegasus.imarticus.org/chapter-controller/cancel.svg"
                            className="cancel"
                            alt="cancel"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="submit">
                    <button className="btn btn-primary" onClick={submitAss}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {assignment.typ == 6 && (
                  <div>
                    {!openIframe && (
                      <div className="no-asgn-box">
                        <div className="asgn-text">
                          <div style={{ marginTop: "108px" }}>
                            {isNewTabOpen ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  {" "}
                                  <p>
                                    {externAssignmentTitle
                                      ? externAssignmentTitle
                                      : ""}
                                  </p>
                                </div>
                                <Button
                                  className="btn-text"
                                  onClick={async () => {
                                    {
                                      window.open(
                                        `${externAssignmentLink}`,
                                        "_blank"
                                      );
                                      await openExternalAssignment();
                                    }
                                  }}
                                >
                                  {userData && userData.stts == 4
                                    ? "View Again"
                                    : "Start"}
                                </Button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  {" "}
                                  <p>
                                    {externAssignmentTitle
                                      ? externAssignmentTitle
                                      : ""}
                                  </p>
                                </div>
                                <Button
                                  className="btn-text"
                                  onClick={async () => {
                                    {
                                      setOpenIframe(!openIframe);
                                      await openExternalAssignment();
                                    }
                                  }}
                                >
                                  {userData && userData.stts == 4
                                    ? "View Again"
                                    : "Start"}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                    )}
                    {openIframe && (
                      <div>
                        <Modal isOpen={openIframe} size="xl" fullscreen>
                          <div
                            style={{
                              width: "100vw",
                              height: "100vh",
                              position: "fixed",
                              top: -2,
                              left: 0,
                            }}
                          >
                            <div>
                              <button
                                className="close"
                                style={{
                                  color: "white",
                                  marginRight: "15px",
                                  position: "fixed",
                                  top: "20px",
                                  right: "0",
                                }}
                                onClick={async () => {
                                  setOpenIframe(!openIframe);
                                  await openExternalAssignment();
                                }}
                              >
                                <span style={{ color: "white" }}>×</span>
                              </button>
                            </div>
                            <iframe
                              src={externAssignmentLink}
                              style={{
                                width: "100vw",
                                height: "100vh",
                              }}
                            ></iframe>
                          </div>
                        </Modal>
                        <br></br>
                        <br></br>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <br></br>
            <br></br>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assignment)
);
