import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

export const updateAssignmentStatus = (pid, cid, chid, fid, done) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_ASSIGNMENT_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          ag_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
          done: done,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const updateExternalAssignmentStatus = (pid, cid, chid, fid, done) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_EXTERNAL_ASSIGNMENT_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          ag_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
          done: done,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const uploadDocumentsToAws = (document) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();
    if (document) {
      data.set("document", document);
    }
    return axios({
      method: "POST",
      url: ROUTES.UPLOAD_DOCUMENTS_TO_AWS_2,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = progressEvent.loaded / progressEvent.total;
        return percentCompleted;
      },
      data,
    })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const submitAssignmentData = (pid, cid, fid, url, text) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.SUBMIT_ASSIGNMENT,
        {
          crs_id: cid,
          p_id: pid,
          ag_id: fid,
          a_id: cookies.get("aid"),
          dt: {
            file: url,
            text: text,
          },
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err.response.data);
        console.log({ err });
        return resolve(err);
      });
  });
};

export const fetchSkillContentData = (pid, cid, skill_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.FETCH_SKILL_LIST,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          skill_id,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
