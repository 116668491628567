import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import styles from "./LevelsView.scss";
//import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
//import Footer from "../../footers/Footer";
import { Link } from "react-router-dom";
//import Header from "../../headers/Header";
import CourseHeader from "../../headers/CourseHeader";
//import SidebarContainer from "../../sidebars/SidebarContainer";
//import ErrorModal from "../../modals/ErrorModal";
//import ContentContainer from "../ContentContainer";
//import ErrorPopover from "../../utils/Popover";
//import explore from "../../../images/explore.svg";
//import create_grp from "../../../images/home-create-grp.svg";
//import join_grp from "../../../images/home-join-grp.svg";
//import home_background from "../../../images/home.svg";
//import CardHeading from "../../utils/CardHeading";
//import CourseAndGroup from "../CourseAndGroup";
import CustomPopover from "../../utils/CustomPopover";
import momentjs from "moment-timezone";
import PoweredByImarticusFooter from "../../footers/PoweredByImarticusFooter";
import {
  setPageHeading,
  resetLoadingData,
  setLoadingData,
  showPopover,
} from "../../../actions/utilAction";
import {
  getProfileInfo,
  snoozeProfilePopup,
} from "../../../actions/profileAction";
import MyCourse from "../MyCourse";
import {
  BUFFER_TIME,
  PAGE_HEADINGS,
  TABS,
  URLS,
  SIDEBARS,
  SIDEBAR_WIDTH,
  CONFIGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
} from "../../../constants/constants";
import Loader from "../../utils/Loader";

import {
  setCurrentTab,
  resetProfileChangedCounter,
} from "../../../actions/utilAction";
import { showSidebarMB, resetBackBtn } from "../../../actions/utilAction";

import {
  changeEmailVerifyLink,
  isPrimaryEmailVerified,
} from "../../../actions/primaryEmailActions";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import {
  fetchMyCourses,
  authenticateCourse,
  getLevelData,
  setSelectedCourse,
  getParam,
  fetchZoomSignatureV3,
  checkNSDCDetails,
  submitNSDCDetails,
} from "../../../actions/courseAction";
import { dispatchIndividualBrand } from "../../../actions/brandAction";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import cookie from "react-cookies";
import PercentTooltipItem from "../../utils/PercentageTooltip";
let thumbs = [
  "https://cdn.pegasus.imarticus.org/courses/images/levelThumb1.svg",
  "https://cdn.pegasus.imarticus.org/courses/images/levelThumb2.svg",
  "https://cdn.pegasus.imarticus.org/courses/images/levelThumb3.svg",
  "https://cdn.pegasus.imarticus.org/courses/images/levelThumb4.svg",
];
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;

const cookies = new Cookies();

let LevelsView = (props) => {
  console.log({ props });
  const [individualBrand, setIndividualBrand] = useState({});
  const [level, setLevel] = useState({});
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    msg: "",
    type: POPOVER_TYPE.SUCCESS,
  });
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [levelView, setLevelView] = useState(true);
  const [breadCrumbsTemp, setBreadCrumbsTemp] = useState([]);
  const [data, setData] = useState([]);
  const [emptyText, setEmptyText] = useState("");
  const [backClicked, setBackClicked] = useState(false);
  const [pendingInstallment, setPendingInstallment] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [ongoing_live, set_ongoing_live] = useState([]);
  const [soon_live, set_soon_live] = useState([]);
  const [placementEnabled, setPlacementEnabled] = useState(false);
  const [nsdcEnabled, setNsdcEnabled] = useState(false);
  const [nsdcVerified, setNsdcVerified] = useState(false);
  const [openNSDCModal, setOpenNSDCModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [nsdcMobile, setNsdcMobile] = useState(undefined);
  const [nsdcUrl, setNsdcUrl] = useState();
  const [nsdcMobileError, setNsdcMobileError] = useState("");
  const [nsdcUrlError, setNsdcUrlError] = useState("");
  const [profileCompletedPerc, setProfileCompletedPerc] = useState(0);
  const [snoozePopup, setSnoozePopup] = useState(false);
  const [levelProgress, setLevelProgress] = useState({});
  window.addEventListener("pageshow", function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  });

  var ongoingLive = [];
  var soonLive = [];
  useEffect(() => {
    if (props.courses && props.courses.length && !dataFetched) {
      for (let i in props.live_lectures) {
        let liv_lec = props.live_lectures[i];
        if (liv_lec.type === 3) {
          continue;
        }
        if (props.selected_course._id != liv_lec.cid) continue;
        let lec_start = new Date(liv_lec.stm);
        let lec_end = liv_lec.etm ? new Date(liv_lec.etm) : null;
        if (lec_end) {
          let current_time = new Date().getTime();
          let start_time = lec_start.getTime();
          let end_time = lec_end.getTime() + BUFFER_TIME.ZOOM_BUFFER_END_TIME;
          liv_lec.lcdt = lec_start.toDateString();
          liv_lec.lctm = lec_start.toLocaleTimeString([], {
            timeStyle: "short",
            hour12: true,
          });
          liv_lec.lcedt = lec_end.toDateString();
          liv_lec.lcetm = lec_end.toLocaleTimeString([], {
            timeStyle: "short",
            hour12: true,
          });

          if (
            current_time < start_time &&
            parseInt(start_time - current_time) < 180 * 1000 &&
            parseInt(start_time - current_time) > 0
          ) {
            soonLive.push(liv_lec);
          } else if (
            current_time > start_time &&
            current_time < end_time &&
            parseInt(current_time - start_time) > 0 &&
            parseInt(end_time - current_time) > 0
          ) {
            ongoingLive.push(liv_lec);
          }
        }
      }
      if (props.profile && props.selected_course.isPlacementEnabled == true) {
        // setOpenProfileModal(props.profile.showProfilePrompt);
        console.log("CHECKING POPUP SNOOZE");
        if (props.profile.popup_snooze + TWO_HOURS_IN_MS > Date.now()) {
          console.log("SNOOZE POPUP TRUE");
          setSnoozePopup(true);
        }
      }
      set_ongoing_live(ongoingLive);
      set_soon_live(soonLive);
      setDataFetched(true);
    }
  }, [props]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--sidebar-width",
      SIDEBAR_WIDTH.HOME_SIDEBAR
    );
    window.history.pushState([], "LevelView", window.location.href);
    const unlisten = props.history.listen((location) => {
      console.log("listening to back interceptor");
      if (props.history.action === "POP") {
        // Do your stuff
        console.log("reloading levels");
        let levelId = getParam("levelId");
        if (levelId) {
          loadLevels(levelId);
        } else {
          loadLevels();
        }
      }
    });
    authenticate();
    return () => {
      setBackClicked(false);
      setLevel({});
      setBreadCrumbs([]);
      setLoading(false);
      setData([]);
      setPopoverOpen(false);
      setMessage({
        heading: "",
        msg: "",
        type: POPOVER_TYPE.SUCCESS,
      });
      setLevelView(true);
      setBreadCrumbsTemp([]);
      props.setSelectedCourse({});
      return unlisten;
    };
  }, []);
  // useEffect(() => {
  //   if (backClicked) {
  //     let localBreadcrumbs = getBreadcrumbs();
  //     let length = localBreadcrumbs.length;
  //     console.log({ localBreadcrumbs });
  //     if (length <= 2) {
  //       console.log("going back to dashboard");
  //       props.history.push("./dashboard");
  //       setBackClicked(false);
  //     } else if (length > 2) {
  //       console.log("going back to level");
  //       let url = "/view/?cid=" + props.selected_course._id;
  //       if (localBreadcrumbs[localBreadcrumbs.length - 2]._id) {
  //         url +=
  //           "&levelId=" + localBreadcrumbs[localBreadcrumbs.length - 2]._id;
  //       }
  //       console.log({ navigatingTo: url });
  //       props.history.push(url);
  //       loadLevels(localBreadcrumbs[localBreadcrumbs.length - 2]._id);
  //       setBackClicked(false);
  //     }
  //   }
  // }, [backClicked]);
  let getBreadcrumbs = () => {
    console.log({ breadCrumbs });
    return breadCrumbs;
  };
  const goToProfile = () => {
    window.location.href = `/dashboard/edit`;
  };
  let authenticate = async () => {
    try {
      let cid = getParam("cid");
      console.log({ cid });
      setLoading(true);
      let {
        success,
        message,
        name,
        location,
        course: courseFound,
        isPlacementEnabled,
        perc_completed,
        nsdcEnabled,
        nsdcVerified,
      } = await authenticateCourse(cid, props.dispatchIndividualBrand);
      console.log({ success, message });
      if (success == false) {
        if (message) {
          // window.alert(message);
          showPopover("Error", message, POPOVER_TYPE.ERROR);
          setLoading(false);
        }
      } else {
        // props.setPageHeading(name);
        setBreadCrumbsTemp([{ name, link: "/view/?cid=" + getParam("cid") }]);
        setBreadCrumbs([
          { name: "My Courses", link: "/" },
          { name, link: "/view/?cid=" + getParam("cid") },
        ]);
        // showPopover("Success", "Authenticated", POPOVER_TYPE.SUCCESS);
        if (location) {
          courseFound.loc_name = location;
        }

        if (courseFound) {
          props.setSelectedCourse(courseFound);
          if (courseFound && courseFound.nextInstallmentTime) {
            setPendingInstallment({
              _id: courseFound._id,
              nextInstallmentTime: courseFound.nextInstallmentTime,
              installmentPlan: courseFound.installmentPlan,
              installmentIndex: courseFound.installmentIndex,
              name: courseFound.name,
              currSymbol: courseFound.currSymbol,
              crs_pg_id: courseFound.crs_pg_id,
              endDate: courseFound.endDate,
              "1st Line": courseFound.installmentPlan["1st Line"],
              "2nd Line": courseFound.installmentPlan["2nd Line"],
              showPaymentButton: courseFound.showPaymentButton,
            });
          }
          console.log({ pendingInstallment });
        }
        if (isPlacementEnabled) {
          setPlacementEnabled(isPlacementEnabled);
          setProfileCompletedPerc(perc_completed);
          if (perc_completed === 0 && nsdcEnabled === false) {
            setOpenProfileModal(true);
          }
        }
        setNsdcEnabled(nsdcEnabled);
        setNsdcVerified(nsdcVerified);
        setLoading(false);
        return loadLevels();
      }
    } catch (err) {
      setLoading(false);
      console.log({ err });
      console.log(err);
      let message = err.statusText || err.message;
      showPopover("Error", message, POPOVER_TYPE.ERROR);
    }
    props.history.push("./dashboard");
  };

  const checkNSDCData = () => {
    const mobileRegex = /^[0-9]{10}$/;
    const isValidMobile = mobileRegex.test(nsdcMobile);
    // setNsdcMobileFormatError(!isValidMobile);

    const urlRegex =
      /^https:\/\/www\.skillindiadigital\.gov\.in\/user\/profile\/public\/detail\/.*$/;
    const isValidURL = urlRegex.test(nsdcUrl);
    // setNsdcUrlFormatError(!isValidURL);

    return isValidMobile && isValidURL;
  };

  const handleNSDCConfirm = () => {
    setLoading(true);
    setNsdcMobileError("");
    setNsdcUrlError("");
    checkNSDCDetails(nsdcMobile, nsdcUrl)
      .then((res) => {
        setOpenNSDCModal(false);
        setOpenConfirmModal(true);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setLoading(false);
          // alert(err.message);
          if (err.code === 204045 || err.code === 204043) {
            setNsdcMobileError(err.message);
          }
          if (err.code === 204046 || err.code === 204044) {
            setNsdcUrlError(err.message);
          }
        } else {
          setLoading(false);
          alert("Something went wrong. Please try again!");
        }
      });
  };

  const handleNSDCSubmit = () => {
    setLoading(true);
    submitNSDCDetails(nsdcMobile, nsdcUrl)
      .then((res) => {
        // window.open(joinUrl, "_self");
        setLoading(false);
        setOpenConfirmModal(false);
        setOpenSuccessModal(true);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setLoading(false);
          alert(err.message);
        } else {
          setLoading(false);
          alert("Something went wrong. Please try again!");
        }
      });
  };

  let joinZoomMeeting = (liveLectureId) => {
    if (!navigator.onLine) {
      return alert("You are offline. Please check your internet connection.");
    }
    setLoading(true);
    fetchZoomSignatureV3(liveLectureId)
      .then((joinUrl) => {
        window.open(joinUrl, "_self");
        // setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setLoading(false);
          alert(err.message);
        } else {
          setLoading(false);
          alert("Something went wrong. Please try again!");
        }
      });
  };

  let loadLevels = async (levelIdFound) => {
    console.log({ levelIdFound });
    let levelId = levelIdFound;
    console.log(levelIdFound == undefined);
    console.log(levelIdFound == 0);
    if (levelIdFound == undefined) {
      console.log({ levelId: getParam("levelId") });
      levelId = getParam("levelId");
    }
    console.log({ levelIdset: levelId });
    setLoading(true);
    let cid =
      props.selected_course && props.selected_course._id
        ? props.selected_course._id
        : getParam("cid");
    try {
      if (!levelId) {
        levelId = null;
      }
      var levels = await getLevelData(cid, levelId);
      console.log({ levels });
      if (levels) {
        var {
          brand,
          success,
          message,
          contents,
          breadCrumbs: breadCrumbsFound,
          levelView: levelViewFound,
          levelProgress,
        } = levels;
        console.log({
          contents,
          breadCrumbsFound,
          levelViewFound,
          levelProgress,
        });
        if (success == false) {
          if (message) {
            // window.alert(message);
            return showPopover("Error", message, POPOVER_TYPE.ERROR);
          }
        }
        setLoading(false);
        let breadCrumbstemp = breadCrumbsFound.map(({ name, _id }, ind) => {
          if (ind == 0) {
            return { name, link: "/view/?cid=" + cid };
          }
          return {
            name,
            link: "/view/?cid=" + cid + "?levelId=" + _id,
          };
        });
        let breadCrumbstemp2 = breadCrumbstemp.slice();
        breadCrumbstemp2.unshift({ name: "My Courses", link: "/" });
        setBreadCrumbs(breadCrumbstemp2);
        // setIndividualBrand(brand);
        // props.dispatchIndividualBrand(brand);
        setBreadCrumbsTemp([breadCrumbstemp[0]]);
        setLevel(breadCrumbstemp[breadCrumbstemp.length - 1]);
        setLevelProgress(levelProgress);

        console.log({ condition: levelViewFound != undefined });
        if (levelViewFound != undefined) {
          setLevelView(levelViewFound);
        }
        if (contents && levelViewFound) setData(contents);
      }
      setLoading(false);
    } catch (err) {
      console.log({ err });
      let message = err.statusText || err.message;
      showPopover("Error", message, POPOVER_TYPE.ERROR);
      setLoading(false);
    }
  };
  useEffect(() => {
    getLevelName();
  }, [level]);
  useEffect(() => {
    console.log({ levelViewchanged: levelView });
    if (levelView == false) {
      // console.log({ selected_course: props.selected_course });
      // '/course/:pid/:gid/:cid'
      let pid = cookies.get("pid");
      let gid = props.selected_course.gids
        ? props.selected_course.gids[0]
        : null;
      let cid = props.selected_course
        ? props.selected_course._id
        : getParam("cid");
      if (pid && gid && cid) {
        let url =
          "/batch" + "/" + pid + "/" + gid + "/" + cid + "/" + level._id;
        console.log({ url });
        props.history.push(url); //userversion key
        return;
      }
    }
  }, [levelView]);
  let showPopover = (heading, msg, type) => {
    setMessage({ heading, msg, type });
    togglePopover();
    setTimeout(function () {
      setPopoverOpen(false);
    }, POPOVER_HIDETIME);
  };
  let togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };
  let navigateToLevel = (level) => {
    console.log(level);
    setLevel(level);
    if (props.selected_course._id && level._id) {
      if (level.next_level_type == 9) {
        let pid = cookies.get("pid");
        let gid = props.selected_course.gids
          ? props.selected_course.gids[0]
          : null;
        let cid = props.selected_course ? props.selected_course._id : null;
        if (gid && cid) {
          let url =
            "/batch" + "/" + pid + "/" + gid + "/" + cid + "/" + level._id;
          console.log({ url });
          props.history.push(url); //userversion key
          return;
        }
      } else {
        let url =
          "/view/?cid=" + props.selected_course._id + "&levelId=" + level._id;
        console.log({ navigatingTo: url });
        props.history.push(url);
        loadLevels(level._id);
      }
    }
  };
  let navigateToLevelBread = (link, index) => {
    console.log({ link, index });
    if (index < 1) {
      props.history.push(link);
      return;
    }
    let tempArr = link.split("levelId=");
    let levelId = tempArr[tempArr.length - 1];
    console.log({ levelIdPassing: levelId });
    if (levelId.length > 24) {
      console.log("no levelId");
      return loadLevels(0);
    }
    loadLevels(levelId);
  };
  let getLevelName = () => {
    console.log("invoked::");
    let text = "Nothing to Show";
    console.log({ level, course: props.selected_course });
    if (props.selected_course.level_labels) {
      text =
        "No " +
        props.selected_course.level_labels[breadCrumbs.length - 2] +
        " to show";
    }
    console.log({ text });
    setEmptyText(text);
  };

  if (loading || !dataFetched) {
    return <Loader />;
  }

  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };
  let convertPriceToLocalString = function (amount) {
    // console.log({amount})
    return parseFloat(String(amount)).toLocaleString();
  };
  let getFormattedTime = (index) => {
    let time = pendingInstallment.endDate;
    return time;
    // let tz = momentjs.tz.guess();
    // let pTim = momentjs(time).valueOf();
    //console.log({ pTim })
    //let pDate = momentjs(pTim).format("Do MMM, YYYY");
    // let pDate = momentjs(pTim).format("LL");
    // return pDate;
  };

  const changeProfileSnooze = async () => {
    snoozeProfilePopup()
      .then(function (res) {
        console.log(res, ":response data");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <div className="levelContainer">
      {breadCrumbs && (
        <Breadcrumbs
          className="breadcrumbs"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadCrumbs.map(({ name, link }, index) => {
            if (index < breadCrumbs.length - 1) {
              return (
                <a
                  key={index}
                  onClick={() => navigateToLevelBread(link, index)}
                >
                  {" "}
                  {name}{" "}
                </a>
              );
            }
            return (
              <Typography color="textPrimary" key={index}>
                {name}
              </Typography>
            );
          })}
        </Breadcrumbs>
      )}
      {/* {!levelView && <Loader />} */}
      <div className="row">
        {pendingInstallment && (
          <>
            <div
              className="installmentContainer"
              key={pendingInstallment._id}
              style={{ marginBottom: "1rem" }}
            >
              <div className="statement">{pendingInstallment["1st Line"]}</div>
              <p className="secondaryStatement">
                {pendingInstallment["2nd Line"]}
              </p>
              <div className="actionRow">
                <div className="installmentRow" style={{ overflowX: "scroll" }}>
                  <p className="installmentHeader">Payments:</p>
                  <div className="installmentRow vertical">
                    <div
                      className="installment"
                      style={{
                        position: "relative",
                        top: "-5px",
                        width: "100%",
                      }}
                    >
                      {pendingInstallment.installmentPlan?.installments?.map(
                        (installment, ind) => {
                          console.log({ installment });
                          if (ind < pendingInstallment.installmentIndex) {
                            return (
                              <img
                                src="https://cdn.pegasus.imarticus.org/payments/done.svg"
                                alt="right"
                                height={14}
                                width={18}
                                key={ind}
                                style={{
                                  background: "white",
                                  padding: "0 2px",
                                }}
                              />
                            );
                          } else {
                            return (
                              <span
                                key={ind}
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  border: "1px solid black",
                                  // marginTop: "4px",
                                  background: "white",
                                  padding: "2px",
                                }}
                              />
                            );
                          }
                        }
                      )}
                    </div>
                    <div className="installment">
                      {pendingInstallment.installmentPlan?.installments?.map(
                        (installment, ind) => {
                          return (
                            <p className="amount" key={ind}>
                              {pendingInstallment.currSymbol}{" "}
                              {convertPriceToLocalString(
                                pendingInstallment.installmentPlan.installments[
                                  ind
                                ].amount
                              )}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="installmentHeader"
                  style={{ marginLeft: "1.5rem" }}
                >
                  Due Date:{" "}
                  <span style={{ color: "rgba(60, 72, 82, 0.7)" }}>
                    {getFormattedTime()}
                  </span>
                </div>
                {pendingInstallment.showPaymentButton !== false ? (
                  <button
                    className="pay"
                    onClick={() => {
                      window.open(
                        `${CONFIGS.PAYMENT_INSTALLMENT_URL}${pendingInstallment.crs_pg_id}`
                      );
                    }}
                  >
                    Pay {pendingInstallment.currSymbol}{" "}
                    {convertPriceToLocalString(
                      pendingInstallment.installmentPlan.installments[
                        pendingInstallment.installmentIndex
                      ].amount
                    )}
                  </button>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="all-lives">
        {placementEnabled && profileCompletedPerc < 100 && (
          <div className="profileComplete">
            <div className="profile-popup">
              <div className="profile-popup-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="justify-content-left profile-bar">
                  <p className="profile-popup-heading col-md-12">
                    Your Profile is incomplete
                  </p>
                  <div className="profile-main-div">
                    <div className="profile-bar-detail col-md-9 col-sm-12">
                      <p>Update your details & complete your profile</p>
                      <div
                        className="profile-bar-total"
                        style={{ height: "", marginLeft: "4px" }}
                      >
                        <div className="profile-bar-progress col-9 col-sm-11">
                          <div
                            className="profile-popup-completed"
                            style={{ width: `${profileCompletedPerc}%` }}
                          ></div>
                        </div>
                        <div
                          className="col-3 col-sm-1"
                          style={{ marginTop: "-5px" }}
                        >
                          {profileCompletedPerc}%
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 goto-profile-btn col-sm-12">
                      <button
                        className="btn rvm-rsm-start-crs-btn"
                        onClick={goToProfile}
                      >
                        Go to My Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {dataFetched &&
          ongoing_live.map((liv_lec) => {
            return (
              <div className="live-container" key={liv_lec._id}>
                <div className="live-status">
                  <img
                    className="icon"
                    src="https://cdn.pegasus.imarticus.org/courses/images/LiveIcon.svg"
                    alt=""
                  />
                  <span style={{ fontWeight: "600", color: "#dc3545" }}>
                    Ongoing Live Session
                  </span>
                </div>
                <div className="live-info">
                  <img
                    className="icon"
                    src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                    alt=""
                  />
                  <div className="detail">
                    <div>{liv_lec.nm}</div>
                    <div className="time">
                      Starts at {liv_lec.lcdt}&nbsp;{liv_lec.lctm} | Ends
                      at&nbsp;
                      {liv_lec.lcedt}&nbsp;{liv_lec.lcetm}
                    </div>
                  </div>
                  <button
                    className="join"
                    onClick={() => {
                      joinZoomMeeting(liv_lec._id);
                    }}
                  >
                    Join Now
                  </button>
                </div>
              </div>
            );
          })}
        {dataFetched &&
          soon_live.map((liv_lec) => {
            return (
              <div className="live-container" key={liv_lec._id}>
                <div className="live-status">Soon to be Live Session</div>
                <div className="live-info">
                  <img
                    className="icon"
                    src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                    alt=""
                  />
                  <div className="detail">
                    <div>{liv_lec.nm}</div>
                    <div className="time">
                      Starts at {liv_lec.lcdt}&nbsp;{liv_lec.lctm} | Ends
                      at&nbsp;
                      {liv_lec.lcedt}&nbsp;{liv_lec.lcetm}
                    </div>
                  </div>
                  <button
                    className="join"
                    onClick={() => {
                      joinZoomMeeting(liv_lec._id);
                    }}
                  >
                    Join Now
                  </button>
                </div>
              </div>
            );
          })}
      </div>

      {levelView && data && data.length > 0 ? (
        <div className="row">
          {data.map((entry, index) => {
            return (
              <div
                className={"entry entry" + entry.level_index}
                key={index}
                onClick={() => {
                  navigateToLevel(entry);
                }}
              >
                <div className="left"></div>
                <img
                  src={thumbs[entry.level_index - 1]}
                  alt="level thumbnail"
                  className="thumbnailpic"
                />
                <div style={{ width: "100%" }}>
                  <h3 className="label">{entry.name}</h3>
                  {levelProgress[entry._id] ||
                  levelProgress[entry._id] === 0 ? (
                    <div className="progressBar">
                      <progress
                        id="file"
                        value={levelProgress[entry._id]}
                        max="100"
                        className="levelProgress"
                      ></progress>
                      <span className="progressBar_desc">
                        {levelProgress[entry._id]} %
                        <PercentTooltipItem
                          id={entry._id}
                          text="Shows the combined percentage of all the chapters that are part of this level"
                        ></PercentTooltipItem>
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <a className="link">Explore </a>
                <NavigateNextIcon fontSize="small" />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="row">
          <img
            src="https://cdn.pegasus.imarticus.org/index/empty_level_neutral.svg"
            alt="empty state"
            style={{ margin: "auto auto 2rem auto", maxWidth: "100%" }}
          />
          <p className="empty">Nothing to display</p>
          <p className="emptyState">{emptyText}</p>
        </div>
      )}
      {individualBrand &&
        individualBrand.poweredByEnabled == true &&
        individualBrand.hyperlinkEnabled == true && (
          <div
            className="powered-by-container"
            style={{ bottom: "24px", position: "relative" }}
          >
            <a className="powered-by" href={"https://www.imarticus.org"}>
              Powered By Imarticus Learning
            </a>
          </div>
        )}
      {individualBrand &&
        individualBrand.poweredByEnabled == true &&
        !individualBrand.hyperlinkEnabled && (
          <div
            className="powered-by-container"
            style={{ bottom: "24px", position: "relative" }}
          >
            <div className="powered-by">Powered By Imarticus Learning</div>
          </div>
        )}
      <CustomPopover
        open={popoverOpen}
        heading={message.heading}
        msg={message.msg}
        type={message.type}
        toggleOpen={togglePopover}
      />
      <CourseHeader
        breadCrumbs={breadCrumbsTemp}
        individualBrand={individualBrand}
      />
      {console.log({ individualBrand })}
      {props.profile && (
        <>
          <Modal
            isOpen={openProfileModal && !snoozePopup && !nsdcEnabled}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
          >
            <ModalBody style={{ paddingBottom: "0px", marginBottom: "48px" }}>
              <div className="prof-remind-header">
                <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  alt="cross"
                  onClick={() => {
                    changeProfileSnooze();
                    setOpenProfileModal(false);
                  }}
                />
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2
                    className="prof-dtl-header"
                    style={{
                      width: "60%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Welcome to Pegasus by Imarticus Learning!
                  </h2>
                </div>
                <div className="prof-dtl-img ">
                  <img
                    src="https://webcdn.imarticus.org/Demo/prof_dtl.svg"
                    alt="cross"
                    // onClick={closeRefer}
                  />
                </div>
                <p className="prof-dtl-txt">
                  To make the most of your learning experience, please take a
                  moment to complete your profile.
                </p>
              </div>
              <div className="btn-section-2">
                <Link
                  to={`/dashboard/edit`}
                  className="crs-resume-btn-2"
                  onClick={() => changeProfileSnooze()}
                >
                  Start Creating your Profile &nbsp;
                  <img
                    src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"
                    alt=""
                  ></img>
                </Link>
              </div>
            </ModalBody>
            {/* <ModalFooter style={{ justifyContent: "center" }}></ModalFooter> */}
          </Modal>

          <Modal
            isOpen={nsdcEnabled && !nsdcVerified}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "640px" }}
          >
            <ModalBody style={{ padding: "48px" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2
                    className="nsdc-dtl-header"
                    style={{
                      width: "60%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Please link your NSDC profile!
                  </h2>
                </div>
                <div className="nsdc-logo-img">
                  <img
                    src="https://webcdn.imarticus.org/Placement/NSDC-Logo-Vector-cdr1.png"
                    alt="cross"
                    style={{ width: "300px", height: "100px" }}
                    // onClick={closeRefer}
                  />
                </div>
                <p className="nsdc-dtl-txt">
                  Since you’ve enrolled for a Job Assured/Assistance Program, it
                  is mandatory to create a NSDC profile and link it to Pegasus.
                  Please note that access to Pegasus will be restricted until
                  this is completed.
                </p>
              </div>
              <div className="row nsdc-btn-gap">
                <div className="btn-section-2">
                  <a
                    href="https://www.skillindiadigital.gov.in/home"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nsdc-create-btn"
                  >
                    Create NSDC Profile &nbsp;
                    <img src="https://webcdn.imarticus.org/Placement/majesticons_open.svg"></img>
                  </a>
                </div>
                <div className="btn-section-2">
                  <Button
                    className="nsdc-update-btn"
                    onClick={() => {
                      setNsdcEnabled(false);
                      setOpenNSDCModal(true);
                    }}
                  >
                    I already have a NSDC Profile &nbsp;
                  </Button>
                </div>
              </div>
              <div>
                <p className="nsdc-dtl-txt-2">
                  If you don’t know how to create your profile
                </p>
                <div className="btn-section-2">
                  <Button
                    className="nsdc-tutorial-btn"
                    onClick={() => {
                      window.open(
                        "https://cdn.pegasus.imarticus.org/pdf/nsdc_profile_guide.pdf",
                        "_blank"
                      );
                    }}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openNSDCModal}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "520px" }}
          >
            <ModalBody style={{ padding: "32px" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <h2
                    className="nsdc-dtl-header"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Enter your NSDC profile details here
                  </h2>
                </div>
              </div>
              <div>
                <div className="nsdc-profile-link-heading">
                  Enter your 10-digit mobile number linked to NSDC:
                </div>
                <div className="nsdc-mobile-box">
                  <div className="nsdc-ccod-box">
                    <img src="https://webcdn.imarticus.org/Placement/twemoji_flag-india.svg"></img>
                    <span className="nsdc-ccod-txt">+91</span>
                  </div>
                  <div className="nsdc-number-box">
                    <Input
                      type="text"
                      name="text"
                      id="name"
                      value={nsdcMobile}
                      onChange={(event) => {
                        const value = event.target.value;
                        console.log({ value });
                        if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                          setNsdcMobile(value);
                        }
                      }}
                      placeholder="X X X X X X X X X X"
                      style={{ width: "98%" }}
                      className={
                        nsdcMobileError != ""
                          ? "nsdc-number-error-box-txt"
                          : "nsdc-number-box-txt"
                      }
                    />
                  </div>
                </div>
                {nsdcMobileError != "" && (
                  <p className="nsdc-mobile-error">{nsdcMobileError}</p>
                )}
              </div>
              <div>
                <div className="nsdc-profile-link-heading">
                  Enter your NSDC profile link:
                </div>
                <div className="nsdc-profile-format-box">
                  <p className="nsdc-profile-format-heading">
                    The profile link should be in the following format:
                  </p>
                  <p className="nsdc-profile-format-txt">
                    https://www.skillindiadigital.gov.in/user/profile/public/detail/...
                  </p>
                </div>
                <div className="nsdc-url-box">
                  <Input
                    type="text"
                    name="text"
                    id="name"
                    value={nsdcUrl}
                    onChange={(event) => {
                      setNsdcUrl(event.target.value);
                    }}
                    placeholder="https://www.skillindiadigital.gov.in/user/..."
                    style={{ width: "98%" }}
                    className={
                      nsdcUrlError != ""
                        ? "nsdc-number-error-box-txt"
                        : "nsdc-number-box-txt"
                    }
                  />
                </div>
                {nsdcUrlError != "" && (
                  <p className="nsdc-mobile-error">{nsdcUrlError}.</p>
                )}
              </div>

              <div>
                <div className="nsdc-reminder-box">
                  <p className="nsdc-reminder-txt">
                    Please make sure the mobile number you entered here and the
                    link you copied are correct before submitting. If you enter
                    inaccurate information, you may lose access to Pegasus and
                    the course materials.
                  </p>
                </div>
                <div className="btn-section-2">
                  <Button
                    className="nsdc-submit-btn"
                    onClick={() => {
                      handleNSDCConfirm();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openConfirmModal}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "560px" }}
          >
            <ModalBody style={{ padding: "48px", paddingTop: "60px" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <h2
                    className="nsdc-dtl-header-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    ARE YOU SURE YOU WANT TO SUBMIT?
                  </h2>
                </div>
                <div className="nsdc-confirm-txt">
                  <span className="nsdc-span-txt">
                    Please make sure the mobile number you entered here and the
                    link you copied are correct before submitting. If you enter
                    inaccurate information, you may lose access to Pegasus and
                    the course materials.
                  </span>
                </div>
              </div>
              <div>
                <div className="nsdc-profile-link-heading">
                  Mobile number linked to your NSDC Profile (should be 10
                  digits):
                </div>
                <div className="nsdc-mobile-box">
                  <div className="nsdc-ccod-box">
                    <img src="https://webcdn.imarticus.org/Placement/twemoji_flag-india.svg"></img>
                    <span className="nsdc-ccod-txt">+91</span>
                  </div>
                  <div className="nsdc-number-box">
                    <Input
                      type="text"
                      name="text"
                      id="name"
                      value={nsdcMobile}
                      disabled
                      placeholder="X X X X X X X X X X"
                      style={{ width: "98%" }}
                      className="nsdc-number-box-txt"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="nsdc-profile-link-heading">
                  Your NSDC profile link:
                </div>
                <div className="nsdc-url-box">
                  <Input
                    type="text"
                    name="text"
                    id="name"
                    value={nsdcUrl}
                    disabled
                    placeholder="https://www.skillindiadigital.gov.in/user/..."
                    style={{ width: "98%" }}
                    className="nsdc-number-box-txt"
                  />
                </div>
              </div>

              <div className="row nsdc-btn-gap">
                <div className="btn-section-2">
                  <Button
                    className="nsdc-sure-btn"
                    onClick={() => {
                      handleNSDCSubmit();
                    }}
                  >
                    Yes, I'm sure
                  </Button>
                </div>
                <div className="btn-section-2">
                  <Button
                    className="nsdc-unsure-btn"
                    onClick={() => {
                      setOpenNSDCModal(true);
                      setOpenConfirmModal(false);
                    }}
                  >
                    No, I want to change
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openSuccessModal}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "640px" }}
          >
            <ModalBody style={{ padding: "48px" }}>
              <div>
                <div
                  className="prof-remind-header"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="https://webcdn.imarticus.org/Placement/Group926.svg"
                    alt="cross"
                    onClick={() => {
                      setOpenSuccessModal(false);
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2
                    className="nsdc-dtl-header-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Your response has been successfully recorded!
                  </h2>
                </div>
                {props.selected_course.isPlacementEnabled == true ? (
                  <p className="nsdc-dtl-txt">
                    You can view your entered NSDC details in the profile
                    section later. Meanwhile, you can complete your profile to
                    start applying for jobs when Career Services will be
                    activated.
                  </p>
                ) : (
                  <p className="nsdc-dtl-txt">
                    You can view your entered NSDC details in your profile
                    section later.
                  </p>
                )}
                <div className="nsdc-logo-img">
                  <img
                    src="https://webcdn.imarticus.org/Placement/Ljg0Cv.tif_1_1.svg"
                    alt="cross"
                    // onClick={closeRefer}
                  />
                </div>
              </div>
              <div className="btn-section-2">
                {props.selected_course.isPlacementEnabled == true ? (
                  <Link to={`/dashboard/edit`} className="crs-resume-btn-2">
                    Complete my profile &nbsp;
                    <img src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"></img>
                  </Link>
                ) : (
                  <Link
                    to={`/view?cid=${props.selected_course.crs_pg_id}`}
                    className="crs-resume-btn-2"
                  >
                    Go to My Course &nbsp;
                    <img src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"></img>
                  </Link>
                )}
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  profile: state.profile,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  changeEmailVerifyLink,
  setCurrentTab,
  showSidebarMB,
  resetProfileChangedCounter,
  resetBackBtn,
  isPrimaryEmailVerified,
  getProfileInfo,
  fetchMyCourses,
  setPageHeading,
  resetLoadingData,
  setLoadingData,
  showPopover,
  setSelectedCourse,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LevelsView)
);
