import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import backArrow from "../../images/backArrow.svg";
import CountryCodes from "../../constants/countryCodes";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { CONFIGS, LABS_REDIRECT_LINK } from "../../constants/constants";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import eye from "../../images/eye.svg";
import noEye from "../../images/noEye.svg";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import { InputAdornment, MenuItem, Select, TextField } from "@material-ui/core";
import styles from "../../styles/styles_new.module.scss";

const cookies = new Cookies();

let errorMessages = {
  incorrectPassword: "Incorrect Password",
  incorrectNumber:
    "This number is not linked with any account. Kindly try another method.",
  noPass:
    "Your account doesn’t have a password set up, please use Forgot Password option to set up your password",
  bruteforce:
    "You've exceeded the maximum number of incorrect password attempts. Please reset your password or opt for logging in via OTP",
  invalidNumber: "Invalid Mobile Number, kindly check it again.",
};

class LoginWithMobile extends Component {
  constructor(props) {
    super(props);
    let query = new URLSearchParams(this.props.location.search);
    let brand = props.match.params.brand;
    this.redirectValue = query.get("redirect");
    console.log({ brand });
    if (brand) {
      this.state = {
        password: "",
        mobile: "",
        ccod: "91",
        incorrectPassword: false,
        incorrectNumber: false,
        noPass: false,
        bruteforce: false,
        invalidNumber: false,
        isLoading: true,
        showPass: false,
      };

      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success && brandData?.mobileAllowed == true) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login/mobile`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/login/mobile`);
          window.location.reload();
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push(`/${brand}/dashboard`);
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      // }
      let auth_cookies = query.get("auth_cookies");
      if (auth_cookies) {
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = JSON.parse(auth_cookies);
        // utils.setLoginCookies(
        //   a_t,
        //   aid,
        //   secret,
        //   rt,
        //   true,
        //   pid,
        //   sid,
        //   user,
        //   Profile
        // );
      }
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            let brandData = res.data?.data?.brand;
            console.log({ brandData });
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      this.state = {
        password: "",
        mobile: "",
        ccod: "91",
        incorrectPassword: false,
        incorrectNumber: false,
        noPass: false,
        bruteforce: false,
        invalidNumber: false,
        isLoading: false,
        showPass: false,
      };

      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 0;
  };

  validatePassword = (password) => {
    return password.length >= 8 && password.length <= 50;
  };

  formValidation = () => {
    // console.log({
    //   "this.validateMobile(this.state.mobile) ": this.validateMobile(
    //     this.state.mobile
    //   ),
    //   "this.validatePassword(this.state.password)": this.validatePassword(
    //     this.state.password
    //   ),
    // });
    return (
      this.validateMobile(this.state.mobile) &&
      this.validatePassword(this.state.password)
    );
  };

  passwordChange = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
      incorrectPassword: false,
    });
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState({
      ccod,
      incorrectNumber: false,
    });
  };

  togglePass = () => {
    this.setState({
      showPass: !this.state.showPass,
    });
  };

  mobileChange = (e) => {
    let mobile = e.target.value;
    if (mobile.length > 15) return;
    let regExp = new RegExp("^[0-9]*$");
    if (mobile != "" && !regExp.test(mobile)) {
      return;
    }
    this.setState({
      mobile,
      incorrectNumber: false,
      invalidNumber: false,
    });
  };

  // setCookies = (at, aid, secret, rt, isLoggedIn, pid, sid, user, Profile) => {
  //   let expirydate = new Date();
  //   expirydate.setDate(expirydate.getDate() + 180);
  //   let cookies = new Cookies();

  //   cookies.set("at", at, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("aid", aid, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("secret", secret, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("rt", rt, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("isLoggedIn", isLoggedIn, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("pid", pid, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("s_id", sid, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("user", user, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  //   cookies.set("Profile", Profile, {
  //     expires: expirydate,
  //     domain: CONFIGS.DOMAIN,
  //     path: "/",
  //   });
  // };

  loginResponseHandler = (res) => {
    let data = res.data.data;

    // utils.setLoginCookies(
    //   data.at,
    //   data.aid,
    //   data.secret,
    //   data.rt,
    //   true,
    //   data.pid,
    //   data.sid,
    //   data.user,
    //   data.Profile[0]
    // );

    this.setState({
      incorrectNumber: false,
      incorrectPassword: false,
    });

    if (this.redirectValue === "labs")
      window.location.href = LABS_REDIRECT_LINK;

    let brand = this.props.match.params.brand;
    if (brand) {
      this.props.history.push(`/${brand}/dashboard`);
    } else {
      this.props.history.push("/dashboard");
    }
  };

  loginWithNumber = () => {
    console.log(`loginWithNumber invoked::`);
    this.setState({ isLoading: true });
    const headers = {
      ...Axios.defaults.headers.common,
      "User-Agent": navigator.userAgent,
      "Accept-Language": navigator.languages,
    };

    headers["resolution"] = `${window.screen.width}x${window.screen.height}`;
    Axios.post(
      ROUTES.LOGIN_ROUTE,
      {
        auth_method: "phone",
        ccod: this.state.ccod,
        mobile: this.state.mobile,
        password: this.state.password,
        device_id: window.fingerprint.md5hash,
      },
      { headers }
    )
      .then((res) => {
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 206000) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectNumber: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div
        className={`auth-main ${styles.login__new_parent_1}`}
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div
          className="auth-block "
          style={{
            position: "relative",
            // minHeight: "85%",
            justifyContent: "flex-start",
            overflowX: "hidden",
          }}
        >
          <img
            className="auth-logo"
            width={200}
            height={100}
            alt="logo"
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>

          <form>
            <div className="loginBackBtn">
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/login" +
                  (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
                }
                className="reset-back"
                style={{ cursor: "pointer" }}
              >
                <img src={backArrow} alt=""></img>
              </Link>
            </div>
            <br />
            <div className="auth-h2 titleText">Login With Mobile Number</div>
            <br />

            <div className="auth-box">
              {/* <div className="auth-ttl">Enter Mobile Number</div> */}
              <div className="auth-container">
                <div style={{ display: "flex", gap: "8px" }}>
                  <Select
                    style={{ width: "40%", padding: 0 }}
                    // className="auth-inp dropdown myDropDown"
                    onChange={this.ccodChange}
                    value={this.state.ccod}
                    // label="Code"
                    variant="outlined"
                  >
                    {CountryCodes.map((cc, i) => {
                      return (
                        <MenuItem
                          key={cc.code + String(i)}
                          disabled={cc.value == "_"}
                          value={cc.value}
                        >
                          {cc.display}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    variant="outlined"
                    // className={`auth-inp ${
                    //   this.state.incorrectPassword ? "error-outline" : ""
                    // }`}
                    label="Mobile No."
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="tel"
                    onChange={this.mobileChange}
                    value={this.state.mobile}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && this.formValidation()) {
                        this.loginWithNumber();
                      }
                    }}
                    style={{ width: "100%", background: "transparent" }}
                  />
                </div>
              </div>
              {this.state.customError && (
                <div className="auth-error">{this.state.customError}</div>
              )}
              {this.state.incorrectNumber && (
                <div className="auth-error">
                  {errorMessages["incorrectNumber"]}
                </div>
              )}
              {this.state.invalidNumber && (
                <div className="auth-error">
                  {errorMessages["invalidNumber"]}
                </div>
              )}
            </div>

            <div className="auth-box">
              {/* <div className="auth-ttl">Enter Password</div> */}
              <div className="auth-container">
                <TextField
                  type={!this.state.showPass ? "password" : "text"}
                  style={{ width: "100%", background: "transparent" }}
                  // className={`auth-inp ${
                  //   this.state.incorrectPassword ? "error-outline" : ""
                  // }`}
                  variant="outlined"
                  name="password"
                  label="Enter your password"
                  onChange={this.passwordChange}
                  value={this.state.password}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && this.formValidation()) {
                      this.loginWithNumber();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={this.togglePass}
                          src={this.state.showPass ? noEye : eye}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {/* <div
                  className={`line ${
                    this.validatePassword(this.state.password) &&
                    !this.state.incorrectPassword
                      ? "lgreen"
                      : "lred"
                  }`}
                ></div> */}
              </div>
              {this.state.incorrectPassword && (
                <div className="auth-error">
                  {errorMessages["incorrectPassword"]}
                </div>
              )}
              {this.state.noPass && (
                <div className="auth-error">{errorMessages["noPass"]}</div>
              )}
              {this.state.bruteforce && (
                <div className="auth-error">{errorMessages["bruteforce"]}</div>
              )}
            </div>

            <div
              className="auth-txt"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/login/otp" +
                  (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
                }
                className="green"
                style={{
                  // textDecoration: "underline",
                  display: "inline",
                  cursor: "pointer",
                  color: "rgb(4, 142, 108)",
                  fontWeight: "600",
                  // color: `${
                  //   this.state.brand &&
                  //   this.state.brand.themeColorIndex &&
                  //   this.state.brand.themeColorIndex
                  // }`,
                }}
              >
                Login With OTP
              </Link>
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/forgot?type=mobile"
                }
                className="green right"
                style={{
                  // textDecoration: "underline",
                  display: "inline",
                  cursor: "pointer",
                  color: "rgb(4, 142, 108)",
                  fontWeight: "600",
                  // color: `${
                  //   this.state.brand &&
                  //   this.state.brand.themeColorIndex &&
                  //   this.state.brand.themeColorIndex
                  // }`,
                }}
              >
                Forgot Password
              </Link>
            </div>

            <br />
            <Button
              type="submit"
              className={`${this.formValidation() ? "" : "disable"} ${
                this.state.brand && this.state.brand.themeColorIndex
                  ? "auth-btn-brand"
                  : "auth-btn"
              }`}
              onClick={(event) => {
                event.preventDefault();
                if (this.formValidation()) this.loginWithNumber();
              }}
              style={
                {
                  // background: `var(--themeColor)`,
                  // color:'var(--textColor)'
                }
              }
            >
              Log in
            </Button>
            <br />
            {(!this.state.brand ||
              this.state.brand.createAccAllowed == true) && (
              <>
                {/* <div className="OR">
                  <div className="line"></div>
                  OR
                  <div className="line"></div>
                </div> */}
                {/* <div className="signup-block">
                  <div className="auth-h2">Sign up</div> */}
                <div className="auth-txt">
                  Don't have an account?
                  <Link
                    to={
                      (this.state.brand ? `/${this.state.brand.utag}` : "") +
                      "/signup"
                    }
                    style={{
                      color: "rgb(4, 142, 108)",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    &nbsp;Sign up for free
                  </Link>
                </div>
                {/* </div> */}
              </>
            )}

            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == true && (
                <div
                  className="powered-by-container"
                  style={
                    this.state.brand.createAccAllowed == true
                      ? { position: "relative", bottom: "0" }
                      : { position: "absolute", bottom: "0" }
                  }
                >
                  <a className="powered-by" href={"https://www.imarticus.org"}>
                    Powered By Imarticus Learning
                  </a>
                </div>
              )}
            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == false && (
                <div
                  className="powered-by-container"
                  style={
                    this.state.brand.createAccAllowed == true
                      ? { position: "relative", bottom: "0" }
                      : { position: "absolute", bottom: "0" }
                  }
                >
                  <div className="powered-by">
                    Powered By Imarticus Learning
                  </div>
                </div>
              )}
          </form>
        </div>
      </div>
    );
  }
}

// export default LoginWithMobile;

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
  brand: state.brand?.brand || state.course.brand,
});
const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginWithMobile)
);
