import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import EditProfileModal from "../../modals/EditProfileModal";
import { useState } from "react";
import pencil from "../../../images/pencil.svg";
import { connect, useDispatch } from "react-redux";
import {
  updatePicURL,
  updateProfileDetailsToBackend,
} from "../../../actions/profileAction";
import { showPopover } from "../../../actions/utilAction";
import { setPageHeading } from "../../../actions/utilAction";
import { useEffect } from "react";
import Axios from "axios";
import { ROUTES } from "../../../constants/routes";
import { utils } from "../../../utility/utils";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  TIMEZONES,
} from "../../../constants/constants";
import SendOTPModal from "../../modals/SendOTPModal";
import CountryCodes from "../../../constants/countryCodes";
import {
  editPrimaryMailSendOtp,
  mailOtpConfirmApi,
  mobileOtpConfirmApi,
} from "../../../apis/editapis";

import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../../services/helper";

const EditProfileDetails = ({
  openProfileEditModal,
  setOpenProfileEditModal,
  pic,
  uid,
  name,
  email,
  ccod = "91",
  mobile,
  timezone,
  gender = "",
  crs_pg_id,
  ...props
}) => {
  console.log({ crs_pg_id });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isDpmOpen, setIsDpmOpen] = useState(false);
  const [isDpmgOpen, setIsDpmgOpen] = useState(false);
  const [current_ppic, setCurrentPic] = useState(pic || "");
  const [edit_ppic, setEditPpic] = useState(pic || "");
  const [data, setData] = useState({
    name,
    email,
    mobile,
    gender,
    timezone,
    ccod,
  });
  const [otpSentOn, setOtpSentOn] = useState({
    for: "", // email/mobile,
    to: "",
  });

  const [old, setOld] = useState({
    old_m: mobile,
    old_e: email,
  });

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otp, setOtp] = useState("");

  const toggleOtpModal = (e) => {
    setOpenOtpModal(!openOtpModal);
  };
  const handleSendOtpButton = (data) => {
    setLoading(true);
    console.log(data);
    setOtpSentOn({
      for: data.for,
      to: data.to,
    });
    // return;
    if (data.for === "number") {
      RequestOTPForMobile();
    } else {
      RequestOTPForEmail();
    }
  };
  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };
  const toggleDeletePhotoModal = () => {
    setIsDpmOpen(!isDpmOpen);
  };
  const toggleDeletePGModal = () => {
    setIsDpmgOpen(!isDpmgOpen);
  };
  const handleDataChanges = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    console.log("props in EditProfileDetail :: ", { props });
    props.updatePicURL(pic);
  }, []);

  const saveProfileChanges = async (e) => {
    console.log({ data });
    saveMyData(data);
  };
  const saveMyData = async (data) => {
    if (
      (name =
        data.name &&
        email === data.email &&
        mobile === data.mobile &&
        timezone === data.time_zone)
    ) {
      return false;
    }
    setLoading(true);
    let configs = {
      headers: {
        "Content-Type": "application/json",
      },
      tokn: utils.getAt(),
    };
    let response = await Axios.post(
      ROUTES.SAVE_PROFILE_DETAILS,
      { ...data, tokn: utils.getAt() },
      configs
    );
    // if(err){
    //     console.log(err)
    // }
    console.log("response :: ", response);
    if (response.data.data) {
      setLoading(false);
      var action = {};
      action.type = "UPDATE_PROFILE_PIC";
      action.ppic = data.ppic;

      // var loading_action = {};
      // loading_action.type = "SET_LOADING_MODAL";
      // loading_action.value = false;
      // loading_action.lm_msg = "";
      // dispatch(loading_action);
      // action.profiles = profiles;
      dispatch(action);
      utils.showPopover(
        POPOVER_HEADINGS.PPIC_UPLOAD_SUCCESS,
        POPOVER_CONTENT.PPIC_UPLOAD_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
      window.location.reload();
      return response.data.data;
    } else {
      setLoading(false);
      return false;
    }
    // })
  };

  const validateEmail = (email) => {
    return (
      email.length <= 60 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };
  const [endTime, setEndTime] = useState(5 * 60);
  const getOTPTimeRemaining = () => {
    setTimeout(() => {
      setEndTime(endTime > 0 ? endTime - 1 : 0);
      getOTPTimeRemaining();
    }, 1000);
  };

  const RequestOTPForEmail = () => {
    let email = data.email;
    try {
      console.log("RequestOTPForEmail :: ", email);
      // setLoading(true);
      //const data = await editPrimaryMailSendOtp({ email });
      editPrimaryMailSendOtp({ email })
        .then(() => {
          setLoading(false);
          utils.showPopover(
            "Email Sent!",
            "Please check your email",
            POPOVER_TYPE.SUCCESS,
            dispatch
          );

          setOpenOtpModal(true);
          // setLoading(false);
          // setStep(step + 1);
        })
        .catch((err) => {
          setLoading(false);
          utils.showPopover(
            "EMAIL VERIFICATION FAILED",
            err,
            POPOVER_TYPE.ERROR,
            dispatch
          );
          // setLoading(false);
          // setError(error);
        });
    } catch (error) {
      // setLoading(false);
      // setError(error);
    }
  };
  const validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 0;
  };
  const RequestOTPForMobile = () => {
    let mobile = data.mobile;
    let c_code = data.ccod;

    if (!validateMobile(mobile)) return;
    let dataObj = {
      mob: mobile,
      ccod: c_code,
    };
    // if (
    //   Number(c_code) !== 91 &&
    //   this.state.token !== "" &&
    //   this.state.captchaVerified
    // ) {
    //   dataObj["token"] = this.state.token;
    // }
    // this.setState({ isLoading: true });

    Axios.post(ROUTES.CONFIRM_MOBILE, dataObj)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        // this.getOTPTimeRemaining();
        // this.setState({
        //   aid: res.data.data.aid,
        //   isVerify: true,
        //   incorrectEmail: false,
        //   incorrectNumber: false,
        //   invalidOTP: false,
        //   isLoading: false,
        //   invalidEmail: false,
        //   invalidNumber: false,
        // });
        setOpenOtpModal(true);
      })
      .catch((err) => {
        setLoading(false);
        let ecode = err.response.data.code;
        console.log(err.response.data);
        // if (ecode == 100) {
        //   this.setState({
        //     incorrectEmail: false,
        //     incorrectNumber: true,
        //     invalidOTP: false,
        //     isLoading: false,
        //     invalidEmail: false,
        //     invalidNumber: false,
        //   });
        // } else if (ecode == 206000) {
        //   this.setState({
        //     incorrectEmail: false,
        //     incorrectNumber: false,
        //     invalidOTP: false,
        //     isLoading: false,
        //     invalidEmail: false,
        //     invalidNumber: true,
        //   });
        // } else if (
        //   err.response.data.message ||
        //   err.response.data.data?.message
        // ) {
        //   this.setState({
        //     customError:
        //       err.response.data.message || err.response.data.data?.message,
        //     isLoading: false,
        //   });
        // }
      });
  };

  const validateEnteredOtp = async (e) => {
    try {
      if (otpSentOn.for === "number") {
        const data = await mobileOtpConfirmApi({
          otp,
          mobile: otpSentOn?.to,
          ccod: ccod,
        });
        dispatch({ type: "MOBILE_UPDATE", mobile: otpSentOn?.to });
        let webEngageData = {
          name: name,
          email: email,
          mobile: mobile,
          timeZone: timezone,
        };

        WebEngageDataLayer(
          webEngageData,
          WebEngageRegisteredEvents.PROFILEUPDATED
        );
      } else {
        const data = await mailOtpConfirmApi({ otp, email: otpSentOn?.to });
        dispatch({
          type: "PRIMARY_EMAIL_UPDATE",
          primary_email: otpSentOn?.to,
        });
        let webEngageData = {
          name: name,
          email: otpSentOn?.to,
          mobile: mobile,
          timeZone: timezone,
        };

        WebEngageDataLayer(
          webEngageData,
          WebEngageRegisteredEvents.PROFILEUPDATED
        );
      }

      setOpenOtpModal(false);
      setOpenProfileEditModal(false);
      window.location.reload();

      // setStep(step + 1);
    } catch (err) {
      console.log(err);
      setOpenOtpModal(false);
      utils.showPopover(err, err, POPOVER_TYPE.ERROR, dispatch);
    }
  };

  let delinkMobile = async () => {
    let dataObj = {
      aid: utils.getAid(),
      tokn: utils.getAt(),
    };
    Axios.post(ROUTES.REMOVE_MOBILE, dataObj).then((res) => {
      window.location.reload();
    });
  };

  return (
    <>
      <EditProfileModal
        open={openEditModal}
        toggleEditModal={toggleEditModal}
        toggleDeletePhotoModal={toggleDeletePhotoModal}
        is_dpm_open={isDpmOpen}
        is_dpgm_open={isDpmgOpen}
        current_ppic={current_ppic || ""}
        setOpenProfileEditModal={setOpenProfileEditModal}
      />
      {openOtpModal && (
        <SendOTPModal
          open={openOtpModal}
          toggleOtpModal={toggleOtpModal}
          otpSentOn={otpSentOn}
          validateEnteredOtp={validateEnteredOtp}
          otp={otp}
          old={old}
          setOtp={setOtp}
        />
      )}
      <Modal
        className="editPopup__new"
        toggle={(e) => setOpenProfileEditModal(false)}
        isOpen={openProfileEditModal}
        centered={true}
      >
        {/* <ModalHeader
          toggle={(e) => setOpenProfileEditModal(false)}
        ></ModalHeader> */}

        <div class="modalContainer">
          {/* <div className="myRow"> */}
          <div
            className="img-container"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div>
              <img
                alt="profileImage"
                onClick={toggleEditModal}
                className="profile-img"
                src={edit_ppic || ""}
                style={{
                  width: "120px",
                  height: "120px",
                }}
              />
            </div>
            <div className="edit-img-container">
              <div
                className="edit-img"
                onClick={toggleEditModal}
                style={{
                  borderRadius: "50%",
                  boxShadow: "0 2px 4px 0 #212a3940",
                  color: "#ffffffe6",
                  cursor: "pointer",
                  display: "flex",
                  height: "33px",
                  background: "#fff",
                  justifyContent: "center",
                  // margin: -40px auto 0,
                  position: "absolute",
                  bottom: "10%",
                  right: "40%",
                  width: "33px",
                  zIndex: "51",
                }}
              >
                <img width="18px" src={pencil} alt="" />
              </div>
            </div>
            <div>
              <img
                src={
                  "https://cdn.pegasus.imarticus.org/courses/images/ProfileCross.svg"
                }
                alt=""
                onClick={() => {
                  setOpenProfileEditModal(false);
                }}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />
            </div>
          </div>

          <p
            style={{
              textAlign: "center",
              marginTop: "24px",
              fontSize: "14px",
              color: "#3C4852",
            }}
          >
            UID: {uid}
          </p>
          {/* <br/> */}
          <div className="row w-100 m-0">
            <div className="col-lg-12 p-0 m-0">
              <label
                style={{
                  fontSize: "12px",
                  color: "#3C4852",
                  marginBottom: "4px",
                }}
              >
                Your Name
              </label>
              <input
                onChange={handleDataChanges}
                className="form-control"
                name="name"
                value={data.name}
                style={{
                  borderRadius: "8px",
                  height: "56px",
                  lineHeight: "20px",
                  fontSize: "16px",
                }}
              />
            </div>

            <div className="col-lg-12 p-0">
              <label
                style={{
                  fontSize: "12px",
                  color: "#3C4852",
                  marginBottom: "4px",
                  marginTop: "16px",
                }}
              >
                Primary Email
              </label>
              <div style={{ display: "flex", gap: "8px" }}>
                <input
                  onChange={handleDataChanges}
                  className="form-control"
                  name="email"
                  value={data.email}
                  style={{
                    borderRadius: "8px",
                    height: "56px",
                    lineHeight: "20px",
                    fontSize: "16px",
                    width: "80%",
                  }}
                />

                <button
                  disabled={
                    email === data.email ||
                    (loading && otpSentOn.for === "email")
                  }
                  onClick={(e) =>
                    handleSendOtpButton({ for: "email", to: data.email })
                  }
                  style={{
                    width: "20%",
                    float: "right",
                    padding: "0",
                    background: "#035642",
                    borderRadius: "8px",
                  }}
                  className="btn btn-success fade_new"
                  color="success"
                >
                  {loading && otpSentOn.for === "email"
                    ? "Loading...!"
                    : "Send OTP"}
                </button>
              </div>
            </div>

            <div className="col-lg-12 p-0">
              <label
                style={{
                  fontSize: "12px",
                  color: "#3C4852",
                  marginBottom: "4px",
                  marginTop: "16px",
                }}
              >
                Primary Mobile Number
              </label>
              <div style={{ display: "flex", gap: "8px" }}>
                <select
                  className="form-control"
                  onChange={handleDataChanges}
                  name="ccod"
                  value={data.ccod}
                  style={{
                    borderRadius: "8px",
                    height: "56px",
                    lineHeight: "20px",
                    fontSize: "16px",
                    width: "20%",
                    padding: "9px 5px",
                  }}
                >
                  {CountryCodes.map((cc, i) => {
                    return (
                      <option
                        key={cc.code + String(i)}
                        disabled={cc.value == "_"}
                        value={cc.value}
                      >
                        {cc.display}
                      </option>
                    );
                  })}
                </select>
                <input
                  onChange={handleDataChanges}
                  className="form-control"
                  name="mobile"
                  value={data.mobile}
                  style={{
                    borderRadius: "8px",
                    height: "56px",
                    lineHeight: "20px",
                    fontSize: "16px",
                    width: "60%",
                  }}
                />

                <button
                  disabled={
                    mobile === data.mobile ||
                    (loading && otpSentOn.for === "mobile")
                  }
                  onClick={(e) =>
                    handleSendOtpButton({ for: "number", to: data.mobile })
                  }
                  style={{
                    width: "20%",
                    float: "right",
                    padding: "0",
                    background: "#035642",
                    borderRadius: "8px",
                  }}
                  className="btn btn-success fade_new"
                  color="success"
                >
                  {loading && otpSentOn.for === "mobile"
                    ? "Loading...!"
                    : "Send OTP"}
                </button>
              </div>
            </div>

            {/* {/* <div className="col-lg-12">
                                <label>Your Gender</label>
                                <select onChange={handleDataChanges} className="form-control" name="gender" value={data.gender}>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/> */}
            <div className="col-lg-12 p-0">
              <label
                style={{
                  fontSize: "12px",
                  color: "#3C4852",
                  marginBottom: "4px",
                  marginTop: "16px",
                }}
              >
                Your Preferred Timezone
              </label>
              <select
                onChange={handleDataChanges}
                className="form-control"
                name="timezone"
                value={data.timezone}
                style={{
                  borderRadius: "8px",
                  height: "56px",
                  lineHeight: "20px",
                  fontSize: "16px",
                }}
              >
                {TIMEZONES.map((item) => (
                  <option key={item} onClick={() => this.setTimezone(item)}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            disabled={
              (name === data.name.trim() &&
                timezone === data.timezone.trim()) ||
              loading
            }
            style={{
              margin: "24px 0px 16px 0px",
              background: "#035642",
              width: "100%",
              height: "52px",
              borderRadius: "8px",
            }}
            onClick={saveProfileChanges}
            className="btn btn-success fade_new"
          >
            Save Changes
          </button>

          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "600",
              cursor: "pointer",
              color: "#dc3545",
            }}
            onClick={() => {
              delinkMobile();
            }}
          >
            Click here to De-link your Mobile Number
          </div>
          {/* </div> */}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  show_menu: state.ac_dd.show_menu,
  profiles: state.profile.profiles,
  p_name: state.profile.p_name,
  ppic: state.profile.ppic,
  edit_ppic: state.profile.edit_ppic,
  profile_map: state.profile.profile_map,
  ppic_ext: state.profile.ppic_ext,
  // edit_ppic_file : state.profile.edit_ppic_file,
  email: state.profile.email,
  contact: state.profile.contact,
  time_zone: state.profile.tz,
});

const mapDispatchToProps = {
  updatePicURL,
  updateProfileDetailsToBackend,
  showPopover,
  setPageHeading,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileDetails);
