import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import { utils } from "../../utility/utils";
import { Redirect } from "react-router";

import MD_IPAD from "../../images/ManageDevices_iPad.png";
import { setLoading } from "../../actions/utilAction";
import Loader from "../utils/Loader";
import Cookies from "universal-cookie";

const MD_WEB =
  "https://cdn.pegasus.imarticus.org/courses/images/manage_desktop.svg";
const MD_Mobile =
  "https://cdn.pegasus.imarticus.org/courses/images/manage_mobile.svg";

const cookies = new Cookies();
const findTypeOfDevice = (device) => {
  if (device === "W") {
    return MD_WEB;
  } else if (device === "I" || device === "A") {
    return MD_Mobile;
  }
};

const findNameOfDevice = (device, client) => {
  console.log("heyy", { device });
  if (device.includes("Macintosh") && client == "W") {
    return "MacBook";
  } else if (
    (device.includes("Windows") || device.includes("Linux")) &&
    client == "W"
  ) {
    console.log("12345  ");
    return "Web";
  } else if (device.includes("Android") && client == "A") {
    return device.split("Model")[1].replaceAll(":", "");
  } else if (device.includes("iPhone") && client == "I") {
    return "iPhone";
  }
};

function timeSince(date) {
  date = new Date(date);
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return (
      Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return (
      Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return (
      Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  return (
    Math.floor(seconds) + " second" + (Math.floor(interval) > 1 ? "s" : "")
  );
}

const ManageDevices = () => {
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("This is the start of useEffect!");
    callListOfDevices();
    console.log("This is the end of useEffect!");
  }, []);
  const callListOfDevices = () => {
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/mng_dvcs`, {
        aid: utils.getAid(),
        device_id: window.fingerprint.md5hash,
        tokn: utils.getAt(),
        rt: cookies.get("rt", { domain: CONFIGS.DOMAIN, path: "/" }),
      })
      .then((res) => {
        console.log(res.data.data);
        console.log(res.data.data.activeDevices);
        console.log(res.data.data.currentDevice?.[0] || {});
        setList(res.data.data.activeDevices || []);
        setCurrent(res.data.data.currentDevice || {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeDevice = (i) => {
    console.log("\n ========== Remove my device ============");
    // if (list.length === 0) return false;
    setLoading(true);
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/removeDevice`, {
        aid: utils.getAid(),
        device_id: window.fingerprint.md5hash,
        endpointId: (i && i.endpointId) || "",
        removeAll: i.removaAll || false,
        tokn: utils.getAt(),

        rt: cookies.get("rt", { domain: CONFIGS.DOMAIN, path: "/" }),
      })
      .then((res) => {
        console.log("Res from removeDevice ::: ", res.data.data);
        alert("Removed device successfully!");
        setLoading(false);
        // window.location.reload();
      })
      .catch((err) => {
        console.log("Err from removeDevice ::: ", err);
        setLoading(false);
      });
  };
  if (loading) return <Loader />;
  return (
    <div
      className="group-db-content-wrapper"
      style={{ fontFamily: "Source Sans Pro" }}
    >
      <div
        className="container-fluid dashboard-content"
        style={{ padding: "48px" }}
      >
        <div className=" profile-setting-container-row">
          <h4 style={{ fontSize: "20px", fontWeight: "600", color: "#212A39" }}>
            This Device
          </h4>
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                padding: "8px",
              }}
            >
              {current && (
                <div
                  className="card"
                  style={{
                    borderRadius: "8px",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ display: "flex", padding: "16px" }}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#f2f6f9",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        src={
                          "clnt" in current
                            ? findTypeOfDevice(current.clnt)
                            : MD_WEB
                        }
                        alt="deviceImage"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                      <h5
                        style={{
                          color: "rgb(60, 72, 82)",
                          fontWeight: 600,
                          marginBottom: "5px",
                          fontSize: "16px",
                        }}
                      >
                        {"clv" in current
                          ? findNameOfDevice(current.clv, current.clnt)
                          : "Web Browser"}
                      </h5>
                      {/* <p>
                          {"createdAt" in current &&
                            timeSince(current.createdAt) + " ago"}
                        </p> */}
                      <p style={{ marginBottom: 0 }}>
                        <span
                          style={{
                            padding: "5px 10px",
                            borderRadius: "40px",
                            background: "rgba(3, 86, 66, 0.15)",
                            color: "var(--themeColor)",
                            fontSize: "12px",
                          }}
                        >
                          Active Session
                        </span>
                      </p>
                    </div>
                    <button
                      onClick={(e) => removeDevice(current)}
                      style={{
                        margin: "auto 0 auto auto",
                        height: "24px",
                        width: "80px",
                        borderRadius: "4px",
                        border: "1px solid rgba(220, 53, 69, 0.08)",
                        background: "rgba(220, 53, 69, 0.08)",
                        color: "rgba(220, 53, 69, 0.80)",
                      }}
                    >
                      Log out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ marginTop: "48px" }}>
            <h4
              style={{ fontSize: "20px", fontWeight: "600", color: "#212A39" }}
            >
              Other Devices
            </h4>
            <div className="row">
              {list.length > 0 &&
                list.map((l, indx) => (
                  <div
                    key={indx}
                    className="col-6"
                    style={{
                      padding: "8px",
                    }}
                  >
                    <div
                      className="card"
                      style={{
                        display: "inline-block",
                        width: "100%",

                        borderRadius: "8px",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ display: "flex", padding: "16px" }}
                      >
                        {/* <div> */}
                        <div
                          style={{
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#f2f6f9",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            src={findTypeOfDevice(l.clnt)}
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            alt=""
                          />
                        </div>
                        <div style={{ marginLeft: "8px" }}>
                          <h5
                            style={{
                              marginBottom: 0,
                              fontWeight: 600,
                              color: "#3C4852",
                              fontSize: "16px",
                            }}
                          >
                            {findNameOfDevice(l.clv, l.clnt)}
                          </h5>
                          <p style={{ marginBottom: 0 }}>
                            Last Used: {timeSince(l.createdAt) + " ago"}
                          </p>
                        </div>
                        {/* </div> */}
                        <button
                          onClick={(e) => removeDevice(l)}
                          style={{
                            margin: "auto 0 auto auto",
                            height: "24px",
                            width: "80px",
                            borderRadius: "4px",
                            border: "1px solid rgba(220, 53, 69, 0.08)",
                            background: "rgba(220, 53, 69, 0.08)",
                            color: "rgba(220, 53, 69, 0.80)",
                          }}
                        >
                          Log out
                        </button>
                      </div>
                      {/* <p>
                          <span
                            style={{
                              padding: "5px 10px",
                              borderRadius: "40px",
                              background: "rgba(3, 86, 66, 0.15)",
                              color: "var(--themeColor)",
                            }}
                          >
                            Other Device Session
                          </span>
                        </p> */}
                      {/* <p style={{ cursor: "pointer", color: "white" }}>""</p> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <br />
          <br />
          <div align="center" style={{ margin: "20px auto" }}>
            <button
              onClick={(e) => removeDevice({ removaAll: true })}
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(220, 53, 69, 0.08)",
                background: "rgba(220, 53, 69, 0.08)",
                fontFamily: "Source Sans Pro",
                cursor: list.length > 0 ? "pointer" : "not-allowed",
                color: list.length > 0 ? "red" : "#ff000047",
                fontSize: "16px",
                padding: "12px 16px",
              }}
            >
              Log me out of all devices
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDevices;
