import React, { Component } from "react";
import backArrow from "../../images/backArrow.svg";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import tick from "../../images/tick.svg";
import greenTick from "../../images/green-tick.svg";
import eye from "../../images/eye.svg";
import noEye from "../../images/noEye.svg";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import { InputAdornment, TextField } from "@material-ui/core";
const cookies = new Cookies();

class SetPassword extends Component {
  constructor(props) {
    super(props);
    let brand = props.match.params.brand;
    console.log({ brand });
    if (brand) {
      this.state = {
        aid: this.props.aid,
        backRoute: this.props.backRoute,
        incorrectPassword: false,
        password: "",
        cnfPassword: "",
        incorrectCnf: false,
        showPass: false,
        showCnf: false,
        isLoading: true,
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/login`);
          window.location.reload();
        });
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        aid: this.props.aid,
        backRoute: this.props.backRoute,
        incorrectPassword: false,
        password: "",
        cnfPassword: "",
        incorrectCnf: false,
        showPass: false,
        showCnf: false,
        isLoading: false,
      };
      window.FreshworksWidget?.("hide");
      // if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  togglePass = () => {
    this.setState({
      showPass: !this.state.showPass,
    });
  };

  toggleCnf = () => {
    this.setState({
      showCnf: !this.state.showCnf,
    });
  };

  validatePasswordLength = (passwrd) => {
    const re = /[^-\s]{8,}/;
    return re.test(passwrd);
  };

  validatePasswordNum = (passwrd) => {
    const re = /[0-9]/;
    return re.test(passwrd);
  };

  validatePasswordSymbol = (passwrd) => {
    const re = /[#?!@$%^&*-]/;
    return re.test(passwrd);
  };

  validatePassword = () => {
    return (
      this.validatePasswordLength(this.state.password) &&
      this.validatePasswordNum(this.state.password) &&
      this.validatePasswordSymbol(this.state.password)
    );
  };

  validateCnfPassword = () => {
    return this.state.password == this.state.cnfPassword;
  };

  formValidation = () => {
    return (
      this.validatePassword() && this.validateCnfPassword() && this.state.aid
    );
  };

  changePass = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
    });
  };

  changeCnf = (e) => {
    let cnfPassword = e.target.value;
    if (cnfPassword.length > 50) return;
    this.setState({
      cnfPassword,
    });
    if (this.state.incorrectCnf && cnfPassword == this.state.password) {
      this.setState({
        incorrectCnf: false,
      });
    }
  };

  cnfFocusOut = () => {
    this.setState({
      incorrectCnf: !this.validateCnfPassword(),
    });
  };

  getStrength = () => {
    return (
      ((this.validatePasswordLength(this.state.password) +
        this.validatePasswordNum(this.state.password) +
        this.validatePasswordSymbol(this.state.password)) *
        5) /
      3
    );
  };

  getStrengthText = () => {
    return ["Password Strength", "Weak", "Weak", "Fair", "Strong", "Strong"][
      Math.floor(this.getStrength())
    ];
  };

  getStrengthStyle = () => {
    return ["none", "weak", "weak", "moderate", "strong", "strong"][
      Math.floor(this.getStrength())
    ];
  };

  updatePassword = () => {
    if (this.state.aid == null) return;

    let dataObj = {
      aid: this.state.aid,
      passwrd: this.state.password,
      cnfPass: this.state.cnfPassword,
    };
    let brand = this.props.match.params.brand;

    this.setState({ isLoading: true });
    Axios.post(ROUTES.SET_PASSWORD, dataObj)
      .then((res) => {
        this.setState({ isLoading: false });
        if (this.props.cookiesToSet) {
          let {
            rt,
            secret,
            at: a_t,
            aid,
            pid,
            user,
            sid,
            Profile,
          } = this.props.cookiesToSet;
          // utils.setLoginCookies(
          //   a_t,
          //   aid,
          //   secret,
          //   rt,
          //   true,
          //   pid,
          //   sid,
          //   user,
          //   Profile[0]
          // );
          if (brand) {
            this.props.history.push(`/${brand}/dashboard `);
          } else {
            this.props.history.push("/dashboard");
          }
        } else {
          this.props.history.push(
            (this.state.brand ? `/${this.state.brand.utag}` : "") + "/login"
          );
        }
      })
      .catch((err) => {
        this.props.history.push(this.state.backRoute);
        window.location.reload();
      });
  };

  backBtnPressed = () => {
    this.props.history.push(this.state.backRoute);
    window.location.reload();
  };

  render() {
    // return this.state.isLoading ? (
    //   <div className="loader loader-center">
    //     <div className="loader-msg-container">
    //       <Loader brand={this.state.brand} />
    //     </div>
    //   </div>
    // ) : (
    return (
      <div
      // style={{
      //   position: "relative",
      //   display: "flex",
      //   flexDirection: "column",
      //   minHeight: "85%",
      //   justifyContent: "flex-start",
      //   overflowX: "hidden",
      // }}
      >
        <div className="loginBackBtn">
          <div
            onClick={this.backBtnPressed}
            className="reset-back"
            style={{ cursor: "pointer" }}
          >
            <img src={backArrow} alt=""></img>
          </div>
        </div>
        <div
          className="auth-h"
          style={{ textAlign: "left", fontSize: "32px", marginTop: "1rem" }}
        >
          Create New Password
        </div>

        <div className="auth-box">
          {/* <div
            className="auth-ttl"
            style={{
              textAlign: "left",
            }}
          >
            Create Password
          </div> */}
          <div style={{ display: "flex", marginTop: "16px" }}>
            <div className="pass-container">
              {/* <input
                type={!this.state.showPass ? "password" : "text"}
                className="auth-inp auth-pass"
                onChange={this.changePass}
                value={this.state.password}
              ></input> */}

              <TextField
                type={!this.state.showPass ? "password" : "text"}
                style={{ width: "100%", background: "transparent" }}
                className={`auth-inp ${
                  this.state.incorrectPassword ? "error-outline" : ""
                }`}
                variant="outlined"
                name="password"
                label="Password"
                onChange={this.changePass}
                value={this.state.password}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && this.formValidation()) {
                //     this.loginWithNumber();
                //   }
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={this.togglePass}
                        src={this.state.showPass ? noEye : eye}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
            {/* <div className="auth-inp show-btn" onClick={this.togglePass}>
              <img src={this.state.showPass ? noEye : eye} alt=""></img>
            </div> */}
          </div>
          {this.state.incorrectPassword && (
            <div className="auth-error">Password must be valid</div>
          )}
        </div>
        <div className="password-strength">
          <div style={{ width: "75%", display: "flex" }}>
            <div
              className={`strength-dash ${
                this.getStrength() > 0 ? "weak" : "none"
              }`}
            ></div>
            <div
              className={`strength-dash ${
                this.getStrength() > 1 ? "weak" : "none"
              }`}
            ></div>
            <div
              className={`strength-dash ${
                this.getStrength() > 2 ? "moderate" : "none"
              }`}
            ></div>
            <div
              className={`strength-dash ${
                this.getStrength() > 3 ? "strong" : "none"
              }`}
            ></div>
            <div
              className={`strength-dash ${
                this.getStrength() > 4 ? "strong" : "none"
              }`}
            ></div>
          </div>
          <div className={`strength-text ${this.getStrengthStyle()}`}>
            {this.getStrengthText()}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="check-pass">
            <img
              src={
                this.validatePasswordLength(this.state.password)
                  ? greenTick
                  : tick
              }
              alt=""
            ></img>
            Atleast 8 characters
          </div>
          <div className="check-pass">
            <img
              src={
                this.validatePasswordNum(this.state.password) ? greenTick : tick
              }
              alt=""
            ></img>
            One Number
          </div>
          <div className="check-pass">
            <img
              src={
                this.validatePasswordSymbol(this.state.password)
                  ? greenTick
                  : tick
              }
              alt=""
            ></img>
            One Symbol
          </div>
        </div>

        <div className="auth-box">
          {/* <div
            className="auth-ttl"
            style={{
              textAlign: "left",
            }}
          >
            Confirm Password
          </div> */}
          <div style={{ display: "flex" }}>
            <div className="pass-container">
              {/* <input
                type={!this.state.showCnf ? "password" : "text"}
                className="auth-inp auth-pass"
                onChange={this.changeCnf}
                onBlur={this.cnfFocusOut}
                value={this.state.cnfPassword}
              ></input> */}

              <TextField
                type={!this.state.showCnf ? "password" : "text"}
                style={{ width: "100%", background: "transparent" }}
                className={`auth-inp`}
                variant="outlined"
                name="password"
                label="Password"
                onChange={this.changeCnf}
                value={this.state.cnfPassword}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && this.formValidation()) {
                //     this.loginWithNumber();
                //   }
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={this.toggleCnf}
                        src={this.state.showCnf ? noEye : eye}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
            {/* <div className="auth-inp show-btn" onClick={this.toggleCnf}>
              <img src={this.state.showCnf ? noEye : eye} alt=""></img>
            </div> */}
          </div>
          {this.state.incorrectCnf && (
            <div className="auth-error">Password Does not match</div>
          )}
        </div>

        <div
          className={`${this.formValidation() ? "" : "disable"} ${
            this.state.brand && this.state.brand.themeColorIndex
              ? "auth-btn-brand"
              : "auth-btn"
          }`}
          onClick={this.formValidation() ? this.updatePassword : undefined}
          style={{
            background: `${
              this.state.brand &&
              this.state.brand.themeColorIndex &&
              this.state.brand.themeColorIndex
            }`,
          }}
        >
          {" Set Password"}
        </div>
        {this.state.brand &&
          this.state.brand.poweredByEnabled == true &&
          this.state.brand.hyperlinkEnabled == true && (
            <div
              className="powered-by-container"
              style={{ position: "absolute", bottom: "0" }}
            >
              <a className="powered-by" href={"https://www.imarticus.org"}>
                Powered By Imarticus Learning
              </a>
            </div>
          )}
        {this.state.brand &&
          this.state.brand.poweredByEnabled == true &&
          this.state.brand.hyperlinkEnabled == false && (
            <div
              className="powered-by-container"
              style={{ position: "absolute", bottom: "0" }}
            >
              <div className="powered-by">Powered By Imarticus Learning</div>
            </div>
          )}
      </div>
    );
    // );
  }
}

// export default withRouter(SetPassword);
const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetPassword)
);
