import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const PercentTooltipItem = (props) => {
  const { text, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span id={"Tooltip-" + id}>
      <img
        src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
        alt="info"
        style={{
          margin: 0,
          width: "20px",
          height: "20px",
        }}
      />
      <Tooltip
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
        placement={"bottom"}
      >
        {text}
      </Tooltip>
    </span>
  );
};

export default PercentTooltipItem;
