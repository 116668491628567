import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import Footer from "../footers/Footer";
import { Link } from "react-router-dom";
import explore from "../../images/explore.svg";
import create_grp from "../../images/home-create-grp.svg";
import join_grp from "../../images/home-join-grp.svg";
import home_background from "../../images/home.svg";
import CardHeading from "../utils/CardHeading";
import CourseAndGroup from "./CourseAndGroup";
import { setPageHeading } from "../../actions/utilAction";
import { getProfileInfo } from "../../actions/profileAction";
import { fetchMyCourses } from "../../actions/courseAction";
import MyCourse from "../homecontents/MyCourse";
import { PAGE_HEADINGS, SIDEBAR_WIDTH } from "../../constants/constants";
import MyGroup from "../homecontents/MyGroup";
import LoadingModal from "../modals/LoadingModal";
import Loader from "../utils/Loader";
import cookie from "react-cookies";
import { logoutUser } from "../../actions/accountDropdownAction";

const cookies = new Cookies();

class EmptyState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_crs_grp: true,
      // loading: true
    };
    if (!cookies.get("isLoggedIn")) {
      logoutUser();
      window.location.href = "/login";
    }
    this.props.setPageHeading(PAGE_HEADINGS.HOME);
  }
  componentDidUpdate(prev_props) {
    // if(this.props.loading != prev_props.loading && !this.props.loading){
    //     alert()
    //     var e = document.getElementById("home-bg-id");
    //         e.style.marginBottom = "0px";
    // }
  }
  // componentDidMount(){
  //     if(document.getElementById("home-bg-id")){
  //         setTimeout(
  //             function() {
  //                 var e = document.getElementById("home-bg-id");
  //                 e.style.marginBottom = "0px";
  //             }
  //              .bind(this),
  //             1500
  //         );
  //     }
  //     else{
  //         if(this.props.loading){

  //         }
  //     }

  // }

  render() {
    return (
      <div className="dashboard-content-wrapper">
        <div
          className="container-fluid dashboard-content EmptyStateContainerNew"
          style={{
            background: `url(${home_background})`,
          }}
        >
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">My Courses</p>
          </div>
          <Fragment>
            <div className="container home-custom-align">
              <div className="home-btn-row row">
                {(this.props.brand == undefined ||
                  this.props.brand.exploreEnabled == true) && (
                  <div className="col-12 col-md-4 home-btn-wrapper-first">
                    <Link to="/dashboard/explore">
                      <div className="home-btn">
                        <div className="home-btn-content">
                          <img className="home-btn-img" src={explore}></img>
                          <p className="home-btn-title">Explore Courses</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}

                {/* <div className="col-12 col-md-4 home-btn-wrapper-mid">
                                    <Link to="/dashboard/jgroup">
                                        <div className="home-btn">
                                        <div className="home-btn-content">
                                            <img className="home-btn-img" src={join_grp}></img>
                                            <p className="home-btn-title">Join Group via Code</p>
                                        </div>
                                    </div>
                                    </Link>
                                </div> */}
                {/* <div className="col-12 col-md-4 home-btn-wrapper-last">
                                <Link to="/dashboard/cgroup">
                                    <div className="home-btn">
                                        <div className="home-btn-content">
                                            <img className="home-btn-img" src={create_grp}></img>
                                            <p className="home-btn-title">Create Group</p>
                                        </div>
                                    </div>
                                </Link>
                                </div> */}
              </div>
              {/* <div className="home-bg-img-wrapper"> */}
              {/* <div className="home-bg-img-container">
                <img
                  src={home_background}
                  className="home-bg-img"
                  id="home-bg-id"
                />
              </div> */}
              {/* </div> */}
            </div>
          </Fragment>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  getProfileInfo,
  fetchMyCourses,
  setPageHeading,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmptyState)
);
