import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../footers/Footer";
import { withRouter } from "react-router";
//import PropTypes from 'prop-types';
import back_btn from "../../images/back-btn.svg";
import { getProfileInfo } from "../../actions/profileAction";
import { setBackBtn } from "../../actions/utilAction";
import queryString from "query-string";
import course_default from "../../images/course-default.svg";
import { utils } from "../../utility/utils";
import {
  toggleJoinGroupModal,
  getGroupInfo,
  redirectToCourseDashboardWithoutJoining,
} from "../../actions/joinGroupAction";
import { fetchMyCourses, addChildCourses } from "../../actions/courseAction";
import JoinGroupModal from "../modals/JoinGroupModal";
import MyCourseSS from "../skeletonscreens/MyCourseSS";
import { dispatchIndividualBrand } from "../../actions/brandAction";

class ComboCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.setBackBtn("/dashboard/course");
    var query_params = queryString.parse(this.props.location.search);
    this.props.fetchMyCourses({
      pid: utils.getPid(),
      combo_id: query_params.cid,
      dispatchIndividualBrand: this.props.dispatchIndividualBrand,
    });
  }
  openCourse = (course) => {
    var crs_gid = course.gids ? course.gids[0] : null;
    var is_crs_grp_joined = false;
    var crs_grp = {};
    if (crs_gid) {
      for (var i = 0; i < this.props.groups.length; i++) {
        if (
          this.props.groups &&
          this.props.groups[i] &&
          crs_gid == this.props.groups[i]._id
        ) {
          is_crs_grp_joined = true;
          break;
        }
      }
      for (var i = 0; i < this.props.course_groups.length; i++) {
        if (
          this.props.course_groups &&
          this.props.course_groups[i] &&
          crs_gid == this.props.course_groups[i]._id
        ) {
          crs_grp = this.props.course_groups[i];
          break;
        }
      }
    }
    if (!is_crs_grp_joined) {
      this.props.getGroupInfo(crs_grp.code, course._id);
      // this.props.openJoinGroupModal(crs_grp);
    } else if (crs_gid && course._id) {
      this.props.redirectToCourseDashboardWithoutJoining(
        crs_gid,
        course._id,
        this.props.crs_pid
      );
    }
  };
  goBack = () => {
    this.props.history.goBack();
  };
  showImg = (e) => {
    e.target.style.display = "block";
  };
  render() {
    if (this.props.is_gcode_valid) {
      this.props.toggleJoinGroupModal(true);
    }
    if (this.props.child_courses && this.props.child_courses.length > 0) {
      return (
        <div className="dashboard-content-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <div className="row tab-row"> */}
            <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <p onClick={this.goBack} className="content-header back-btn">
                <img src={back_btn} className="back-img"></img>Back
              </p>
            </div>
            <div className="course-container">
              <div className="course-content">
                {/* <div className="crs-heading">
                                    Combo Course
                                </div> */}
                {this.props.child_courses &&
                  this.props.child_courses.length > 0 &&
                  this.props.child_courses.map(
                    (
                      { _id, name, crs_thumb_url, course_completion },
                      crs_index
                    ) => {
                      return (
                        <div
                          className="ic-container"
                          onClick={() =>
                            this.openCourse(this.props.courses_map[_id])
                          }
                        >
                          <div className="crs-cover"></div>
                          <div className="mb-section">
                            <div className="mb-section-cover"></div>
                            <p className="mb-heading">
                              {course_completion > 0
                                ? "Continue"
                                : "Start Course"}
                            </p>
                            <p className="crs-name">{name} </p>
                            <div className="progress-heading">
                              <span className="progress-label">Progress</span>
                              <span className="percent-mb">
                                {course_completion}%
                              </span>
                            </div>
                            <div className="progress-bar-container-mb">
                              <div
                                className="progress-bar-mb"
                                style={{ width: course_completion + "%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="img-section">
                            <div className="crs-img-container">
                              <img
                                className="crs-img"
                                onLoad={(e) => this.showImg(e)}
                                src={
                                  crs_thumb_url ? crs_thumb_url : course_default
                                }
                                alt=""
                              ></img>
                            </div>
                          </div>
                          <div className="progress-section">
                            <div className="content-container">
                              <p className="crs-name">{name}</p>
                              <div className="pb-container">
                                <div className="progress-bar">
                                  <div
                                    className="active-status"
                                    style={{ width: course_completion + "%" }}
                                  ></div>
                                </div>
                                <span className="percent">
                                  {course_completion}%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="btn-section">
                            <button
                              className={
                                course_completion == 0
                                  ? "crs-start-btn"
                                  : "crs-resume-btn"
                              }
                            >
                              {course_completion == 0
                                ? "Start Course"
                                : "Continue"}
                            </button>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>

            {/* </div> */}
          </div>
          <Footer />
          <JoinGroupModal />
        </div>
      );
    } else {
      return <MyCourseSS />;
    }
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  child_courses: state.course.child_courses,
  courses_map: state.course.courses_map,
  groups: state.j_grp.groups,
  is_pr_sw: state.profile.is_pr_sw,
  is_gcode_valid: state.j_grp.is_gcode_valid,
  crs_pid: state.course.crs_pid,
});

const mapDispatchToProps = {
  getProfileInfo,
  fetchMyCourses,
  addChildCourses,
  setBackBtn,
  toggleJoinGroupModal,
  getGroupInfo,
  redirectToCourseDashboardWithoutJoining,
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ComboCourse)
);
