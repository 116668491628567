import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import backArrow from "../../images/backArrow.svg";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import { LABS_REDIRECT_LINK } from "../../constants/constants";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import { TextField } from "@material-ui/core";
import styles from "../../styles/styles_new.module.scss";
const cookies = new Cookies();

let errorMessages = {
  incorrectPassword: "Incorrect Password",
  incorrectEmail:
    "No account is linked with this Username. Kindly try another method.",
  noPass:
    "Your account doesn’t have a password set up, please use 'Forgot Password' option to set up your password",
  bruteforce:
    "You've exceeded the maximum number of incorrect password attempts. Please reset your password or opt for logging in via OTP",
  invalidEmail: "Invalid Email ID, kindly check it again.",
};

class LoginWithUsername extends Component {
  constructor(props) {
    super(props);
    let query = new URLSearchParams(this.props.location.search);
    let brand = props.match.params.brand;
    this.redirectValue = query.get("redirect");
    console.log({ brand });
    if (brand) {
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        isLoading: true,
        invalidEmail: false,
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success && brandData?.usernameEnabled) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login/username`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/login/username`);
          window.location.reload();
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push(`/${brand}/dashboard`);
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        isLoading: false,
        invalidEmail: false,
      };
      let auth_cookies = query.get("auth_cookies");
      if (auth_cookies) {
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = JSON.parse(auth_cookies);
        // utils.setLoginCookies(
        //   a_t,
        //   aid,
        //   secret,
        //   rt,
        //   true,
        //   pid,
        //   sid,
        //   user,
        //   Profile
        // );
      }
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            console.log({ brandData });
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  validateEmail = (email) => {
    return email.length <= 60 && email.length > 0;
  };

  validatePassword = (password) => {
    return password.length >= 8 && password.length <= 50;
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 61) return;
    this.setState({
      email,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  passwordChange = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
      incorrectPassword: false,
    });
  };

  loginResponseHandler = (res) => {
    let data = res.data.data;

    // utils.setLoginCookies(
    //   data.at,
    //   data.aid,
    //   data.secret,
    //   data.rt,
    //   true,
    //   data.pid,
    //   data.sid,
    //   data.user,
    //   data.Profile[0]
    // );

    this.setState({
      incorrectEmail: false,
      incorrectPassword: false,
      isLoading: false,
    });

    if (this.redirectValue === "labs")
      window.location.href = LABS_REDIRECT_LINK;
    let brand = this.props.match.params.brand;
    if (brand) {
      this.props.history.push(`/${brand}/dashboard`);
    } else {
      this.props.history.push("/dashboard");
    }
  };

  loginWithEmail = () => {
    this.setState({ isLoading: true });
    const headers = {
      ...Axios.defaults.headers.common,
      "User-Agent": navigator.userAgent,
      "Accept-Language": navigator.languages,
    };

    headers["resolution"] = `${window.screen.width}x${window.screen.height}`;
    Axios.post(
      ROUTES.LOGIN_ROUTE,
      {
        auth_method: "username",
        email: this.state.email,
        password: this.state.password,
        device_id: window.fingerprint.md5hash,
      },
      { headers }
    )
      .then((res) => {
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectEmail: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };
  formValidation = () => {
    return (
      this.validateEmail(this.state.email) &&
      this.validatePassword(this.state.password)
    );
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div className={`auth-main ${styles.login__new_parent_1}`}>
        <div className="auth-block auth-block-signup">
          <img
            className="auth-logo"
            width={200}
            height={100}
            alt="logo"
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>

          <form>
            {/* <div style={{ display: "flex" }}>
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/login" +
                  (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
                }
                className="reset-back"
                style={{ cursor: "pointer", marginRight: "8px" }}
              >
                <img src={backArrow} style={{ marginRight: "8px" }}></img>
              </Link>
              <div className="auth-h2">Login With Username</div>
            </div> */}
            <div className="loginBackBtn">
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/login" +
                  (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
                }
                className="reset-back"
                style={{ cursor: "pointer" }}
              >
                <img src={backArrow} alt=""></img>
              </Link>
            </div>
            <br />
            <div className="auth-h2 titleText">Login With Username</div>
            {/* <br /> */}
            <br />

            <div className="auth-box">
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="auth-ttl">Enter Username</div>
              </div> */}
              <div className="auth-container">
                {/* <Input
                  type="email"
                  className="auth-inp"
                  name="email"
                  onChange={this.emailChange}
                  value={this.state.email}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && this.formValidation()) {
                      this.loginWithEmail();
                    }
                  }}
                ></Input> */}
                <TextField
                  className="auth-inp"
                  name="email"
                  label="Enter your username"
                  variant="outlined"
                  onChange={this.emailChange}
                  value={this.state.email}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && this.formValidation()) {
                      this.loginWithEmail();
                    }
                  }}
                  style={{ width: "100%", background: "transparent" }}
                />
                {/* <div
                  className={`line ${
                    this.validateEmail(this.state.email) &&
                    !this.state.incorrectEmail
                      ? "lgreen"
                      : "lred"
                  }`}
                ></div> */}
              </div>
              {this.state.incorrectEmail && (
                <div className="auth-error">
                  {errorMessages["incorrectEmail"]}
                </div>
              )}
              {this.state.invalidEmail && (
                <div className="auth-error">
                  {errorMessages["invalidEmail"]}
                </div>
              )}
              {this.state.customError && (
                <div className="auth-error">{this.state.customError}</div>
              )}
            </div>

            <div className="auth-box">
              {/* <div className="auth-ttl">Enter Password</div> */}
              <div className="auth-container">
                {/* <Input
                  type="password"
                  className={`auth-inp ${
                    this.state.incorrectPassword ? "error-outline" : ""
                  }`}
                  name="password"
                  onChange={this.passwordChange}
                  value={this.state.password}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && this.formValidation()) {
                      this.loginWithEmail();
                    }
                  }}
                ></Input>
                <div
                  className={`line ${
                    this.validatePassword(this.state.password) &&
                    !this.state.incorrectPassword
                      ? "lgreen"
                      : "lred"
                  }`}
                ></div> */}
                <TextField
                  type="password"
                  variant="outlined"
                  className={`auth-inp ${
                    this.state.incorrectPassword ? "error-outline" : ""
                  }`}
                  name="password"
                  label="Enter your password"
                  onChange={this.passwordChange}
                  value={this.state.password}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && this.formValidation()) {
                      this.loginWithEmail();
                    }
                  }}
                  style={{ width: "100%", background: "transparent" }}
                />
              </div>
              {this.state.incorrectPassword && (
                <div className="auth-error">
                  {errorMessages["incorrectPassword"]}
                </div>
              )}
              {this.state.noPass && (
                <div className="auth-error">{errorMessages["noPass"]}</div>
              )}
              {this.state.bruteforce && (
                <div className="auth-error">{errorMessages["bruteforce"]}</div>
              )}
            </div>

            <Button
              type="submit"
              className={`${this.formValidation() ? "" : "disable"} ${
                this.state.brand && this.state.brand.themeColorIndex
                  ? "auth-btn-brand"
                  : "auth-btn"
              }`}
              onClick={(event) => {
                event.preventDefault();
                if (this.formValidation()) this.loginWithEmail();
              }}
              style={{
                background: `${
                  this.state.brand &&
                  this.state.brand.themeColorIndex &&
                  this.state.brand.themeColorIndex
                }`,
                color: "var(--textColor)",
              }}
            >
              Log in
            </Button>
            <br />
            {/* {(!this.state.brand ||
              this.state.brand.createAccAllowed == true) && (
              <>
                <div className="OR">
                  <div className="line"></div>
                  OR
                  <div className="line"></div>
                </div>
                <div className="signup-block">
                  <div className="auth-h2">Sign up</div>
                  <div className="auth-txt">Don't have an account?</div>
                  <br />
                  <Link
                    to={
                      (this.state.brand ? `/${this.state.brand.utag}` : "") +
                      "/signup"
                    }
                    className="create-ac-btn"
                  >
                    Create Account
                  </Link>
                </div>
              </>
            )} */}
            {(!this.state.brand ||
              this.state.brand.createAccAllowed == true) && (
              <>
                <div
                  className="auth-txt"
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  Don't have an account?{" "}
                  <span>
                    <Link
                      style={{ color: "#048E6C" }}
                      to={
                        (this.state.brand ? `/${this.state.brand.utag}` : "") +
                        "/signup"
                      }
                      // className="create-ac-btn"
                    >
                      Sign up for free
                    </Link>
                  </span>
                </div>
              </>
            )}

            {this.state.brand && this.state.brand.poweredByEnabled == true && (
              <div className="powered-by-container">
                <a className="powered-by" href={"https://www.imarticus.org"}>
                  Powered By Imarticus Learning
                </a>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

// export default LoginWithUsername;

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
});
const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginWithUsername)
);
