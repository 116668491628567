import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const PercentDetailTooltip = (props) => {
  const { text, id, elem } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span id={"Tooltip-" + id} style={{ height: "20px" }}>
      {elem}
      <Tooltip
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
        placement={"bottom"}
      >
        {text}
      </Tooltip>
    </span>
  );
};

export default PercentDetailTooltip;
