import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const TooltipItem = (props) => {
  const { batch, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <div id={"Tooltip-" + id} className="batchname">
        Batch: {batch}
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={"Tooltip-" + id}
          toggle={toggle}
        >
          {batch}
        </Tooltip>
      </div>
    </div>
  );
};

export default TooltipItem;
