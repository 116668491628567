import { connect } from "react-redux";
import React, { useState } from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import close_img from "../../images/close-btn.svg";
import { showPopover } from "../../actions/utilAction";
import { updatePicURL } from "../../actions/profileAction";
import OtpMobile from "../../images/otpMobile.svg";
import OtpMail from "../../images/otpMail.svg";
import OtpInput from "../login/OTPInput";

const SendOTPModal = ({
  open,
  toggleOtpModal,
  otpSentOn,
  validateEnteredOtp,
  otp,
  setOtp,
  old,
}) => {
  const OTPChange = (e) => {
    console.log({ e });
    let otp = e;
    // let otp = e.target.value;
    if (otp.length > 6) return;
    let regExp = new RegExp("^[0-9]*$");
    if (otp != "" && !regExp.test(otp)) {
      return;
    }
    setOtp(otp);
    setClicked(false);
  };
  console.log({ otp });

  const [clicked, setClicked] = useState(false);
  return (
    <div>
      <Modal
        isOpen={open}
        className={"otp-modal-container editPopup__new_2"}
        centered={true}
        toggle={toggleOtpModal}
      >
        <div className="firstContainer">
          <img
            src={otpSentOn.for === "number" ? OtpMobile : OtpMail}
            alt=""
            width={120}
            style={{
              marginBottom: "24px",
            }}
            height={120}
          />
          <div>
            <img
              src={
                "https://cdn.pegasus.imarticus.org/courses/images/ProfileCross.svg"
              }
              alt=""
              onClick={() => {
                toggleOtpModal(false);
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "24px",
                right: "24px",
              }}
            />
          </div>

          <p className="verificationCodeP">
            A verification code has been sent to &nbsp;
            <span style={{ color: "#035642" }}>
              {otpSentOn.for === "number" ? old.old_m : old.old_e}
            </span>
          </p>
          <small className="EnteredWrongNumberP">
            Entered a wrong {otpSentOn.for}?{" "}
            <span
              onClick={toggleOtpModal}
              className="ChangeNumberP"
              style={{ color: "#048E6C", cursor: "pointer" }}
            >
              Change {otpSentOn.for}
            </span>
          </small>

          <OtpInput length={6} onChangeOtp={OTPChange} />

          <button
            className="ValidateOtpButton btn"
            onClick={() => {
              validateEnteredOtp();
              setClicked(true);
            }}
            disabled={clicked || otp.length < 6}
            style={{
              marginTop: "24px",
              height: "52px",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {clicked ? "Please Wait" : "Validate"}
          </button>

          {/* <small className="DidnotRecieveP">
            Didn't recieve the OTP, &nbsp;
            <span
              className="DidnotRecieveP"
              style={{ color: "#048E6C", cursor: "pointer" }}
            >
              Resend OTP.
            </span>
          </small> */}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  //   ppic: state.profile.ppic,
  //   cropped_ppic: state.profile.cropped_ppic,
  //   edit_ppic: state.profile.edit_ppic,
});

const mapDispatchToProps = {
  showPopover,
  updatePicURL,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SendOTPModal)
);
