export const lecturePerc = {
  0: "0% 'Not Started'",
  1: "25% 'Started Watching'",
  2: "50% 'Video in progress'",
  3: "75% 'Video in progress'",
  4: "100% 'Completed'",
};

export const QuizPerc = {
  0: "0% 'Not Started'",
  1: "25% 'Opened but not Submitted'",
  2: "50% 'Opened but not Submitted'",
  3: "75% 'Opened but not Submitted'",
  4: "100% 'Completed'",
};

export const AssignmentPerc = {
  0: "0% 'Not Started'",
  1: "25% 'Opened but not Submitted'",
  2: "50% 'Submitted, yet to be Marked'",
  3: "75% 'Submitted, yet to be Marked'",
  4: "100% 'Completed & Marked by Trainer'",
};

export const NoSubAssignmentPerc = {
  0: "0% 'Not Started'",
  1: "25% 'Opened'",
  2: "50% 'Yet to be Marked'",
  3: "75% 'Yet to be Marked'",
  4: "100% 'Completed & Marked by Trainer'",
};

export const DocLecturePerc = {
  0: "0% 'Not Started'",
  1: "25% 'Completed'",
  2: "50% 'Completed'",
  3: "75% 'Completed'",
  4: "100% 'Completed'",
};

export const ScromPerc = {
  0: "0% 'Not Started'",
  1: "25% 'Opened'",
  2: "50% 'Opened'",
  3: "75% 'Opened'",
  4: "100% 'Completed'",
};

export const MMSSToDuration = (timeString) => {
  const parts = timeString?.split(":").map(Number);
  if (parts?.length === 3) {
    // Format: "HH:MM:SS"
    let [hours, minutes, seconds] = parts;
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return 1;
    }
    return hours * 3600 + minutes * 60 + seconds;
  } else if (parts?.length === 2) {
    // Format: "MM:SS"
    let [minutes, seconds] = parts;
    if (isNaN(minutes) || isNaN(seconds)) {
      return 1;
    }
    return minutes * 60 + seconds;
  } else if (parts?.length === 1) {
    // Format: "MM:SS"
    let [minutes, seconds] = parts;
    if (isNaN(minutes) || isNaN(seconds)) {
      return 1;
    }
    return minutes * 60 + seconds;
  } else {
    return 1;
  }
};

export const MinsToHHMM = (timeString) => {
  let newTime = 0;
  let mins = 0;
  let hours = Math.floor(timeString / 60);
  if (hours >= 1) {
    mins = timeString - 60 * hours;
  } else {
    mins = timeString;
  }
  newTime = (hours ? hours + " hr " : "") + mins + " mins";
  return newTime;
};

export const perCalc = (status, topic, course, type) => {
  const calc =
    Number(
      Math.floor(
        (
          ((status.status[topic.fid]?.max_video_watched || 0) /
            (MMSSToDuration(course[type][topic.fid].vlen) || 1)) *
          100
        ).toFixed(0)
      )
    ) || 0;

  if (calc >= 100) {
    return 100;
  } else {
    return calc;
  }
};
