import { CONFIGS } from "./constants";

export const ROUTES = {
  // group routes
  G_INFO: CONFIGS.API_API_ENDPOINT + "group/g_info",
  MEMBER_CHECK: CONFIGS.API_API_ENDPOINT + "groupmember/check",
  JOINGROUP: CONFIGS.API_API_ENDPOINT + "groupmember/joingroup",
  FETCH_GROUP: CONFIGS.API_API_ENDPOINT + "group/fetch",
  GROUP_PLUGIN: CONFIGS.API_API_ENDPOINT + "plugin/g_pl_cf",
  USER_PLUGIN_IDNT: CONFIGS.API_API_ENDPOINT + "plugin/usr_plg_idnt",
  CHANGE_GROUP_TYPE: CONFIGS.API_API_ENDPOINT + "group/changetype",
  CREATE_GROUP: CONFIGS.API_API_ENDPOINT + "group/index",
  LEAVE_GROUP: CONFIGS.API_API_ENDPOINT + "groupmember/g_l",
  GROUP_NAME_EDIT: CONFIGS.API_API_ENDPOINT + "group/index",
  GROUP_PIC_EDIT: CONFIGS.API_API_ENDPOINT + "group/gpic_e",
  GROUP_PIC_REMOVE: CONFIGS.API_API_ENDPOINT + "group/gpic_r",
  GROUP_REMOVE: CONFIGS.API_API_ENDPOINT + "group/grp_d",
  GROUP_PIC_WEB: CONFIGS.API_API_ENDPOINT + "media/gp_w",
  GET_GRPIDS_SEQ: CONFIGS.API_API_ENDPOINT + "group/gt_g_sq",
  UPDATE_GRPIDS_SEQ: CONFIGS.API_API_ENDPOINT + "group/up_g_sq",
  GROUP_SETTINGS: CONFIGS.API_API_ENDPOINT + "group/settings",
  // profile routes
  PROFILE_INFO: CONFIGS.API_API_ENDPOINT + "profile/find",
  PROFILE_DETAILS: CONFIGS.API_API_ENDPOINT + "profile/gt_profile_details",
  ADD_PROFILE: CONFIGS.API_API_ENDPOINT + "profile/index",
  PROFILE_PIC_WEB: CONFIGS.API_API_ENDPOINT + "media/pp_w",
  PROFILE_NAME_EDIT: CONFIGS.API_API_ENDPOINT + "profile/pnam_e",
  UPLOAD_PPIC_AWS: CONFIGS.API_API_ENDPOINT + "media/up_pp",
  PROFILE_PIC_EDIT: CONFIGS.API_API_ENDPOINT + "profile/ppic_e",
  PROFILE_PIC_REMOVE: CONFIGS.API_API_ENDPOINT + "profile/ppic_r",
  PROFILE_REMOVE: CONFIGS.API_API_ENDPOINT + "profile/profile_r",

  PLACEMENT_JOB_DETAILS: CONFIGS.API_ENDPOINT + "courselib/get_learnerjob",
  PLACEMENT_SINGLE_JOB_DETAILS:
    CONFIGS.API_ENDPOINT + "courselib/get_single_job",
  PLACEMENT_APPLY_JOB: CONFIGS.API_ENDPOINT + "courselib/apply_job",
  PLACEMENT_SAVE_JOB: CONFIGS.API_ENDPOINT + "courselib/save_job",
  PLACEMENT_PROFILE_DETAILS:
    CONFIGS.API_ENDPOINT + "courselib/get_learnerprofile_details",
  PLACEMENT_PROFILE_SCHEMA:
    CONFIGS.API_ENDPOINT + "course/get_learnerprofile_schema",
  PLACEMENT_PROFILE_PREVIEW:
    CONFIGS.API_ENDPOINT + "course/get_learnerprofile_preview",
  UPLOAD_USER_RESUME: CONFIGS.API_ENDPOINT + "course/upload_user_resume",
  UPLOAD_USER_PROFILE_PIC:
    CONFIGS.API_ENDPOINT + "courselib/upload_user_profilepic",
  UPLOAD_USER_AGREEMENT:
    CONFIGS.API_ENDPOINT + "courselib/upload_user_agreement",
  SAVE_PROFILE_DATA: CONFIGS.API_ENDPOINT + "course/save_userprofile_data",
  SAVE_USER_RESUME: CONFIGS.API_ENDPOINT + "course/save_user_resume",
  DELETE_USER_RESUME: CONFIGS.API_ENDPOINT + "course/delete_user_resume",
  PLACEMENT_ADD_LEARNER_REMARKS:
    CONFIGS.API_ENDPOINT + "courselib/add_learner_remarks",
  PLACEMENT_SNOOZE_POPUP:
    CONFIGS.API_ENDPOINT + "courselib/snooze_profile_popup",

  //Plugin routes
  INITIALIZE_TRAN: CONFIGS.API_API_ENDPOINT + "transaction/i_trxn_v4",

  // courses routes
  MY_CRS: CONFIGS.API_API_ENDPOINT + "course/my_crs",
  FETCH_EXPLORE_DATA: CONFIGS.API_API_ENDPOINT + "trend/ex_sc_w",
  EXPLORE_GROUP_INFO: CONFIGS.API_API_ENDPOINT + "group/ex_g_info",
  LEVELS_DATA: CONFIGS.API_API_ENDPOINT + "courselib/levelContent",
  ZOOM_SIGNATURE_V3: CONFIGS.API_API_ENDPOINT + "courselib/gen_zm_sgn_v3",
  G_CONTENT_V5: CONFIGS.API_API_ENDPOINT + "courselib/gcontent_v5",
  G_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/gstatus",
  PLUGIN_IDNT: CONFIGS.API_API_ENDPOINT + "plugin/usr_plg_idnt",
  REGISTER_USER_QUIZ: CONFIGS.QUIZ_BACKEND_URL + "/user/register",
  INIT_USER_QUIZ: CONFIGS.QUIZ_BACKEND_URL + "/user/init",
  START_F_USER_QUIZ: CONFIGS.QUIZ_BACKEND_URL + "/usertest/start_f",
  GET_TEST_QUESTION: CONFIGS.QUIZ_BACKEND_URL + "/testquestion/get_m",
  END_V2: CONFIGS.QUIZ_BACKEND_URL + "/usertest/end_v2",
  CERTIFICATE_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/cert_req_status_v2",
  CERTI_REQ: CONFIGS.API_API_ENDPOINT + "courselib/cert_req_status",
  APPLY_CERTIFICATE: CONFIGS.API_API_ENDPOINT + "courselib/aply_crtf_v3",
  PLACEMENT_REDIRECT: CONFIGS.API_API_ENDPOINT + "course/placementredirect",
  IMPARTUS_LOGIN:
    CONFIGS.API_API_ENDPOINT + "courselib/offline_class_recordings_login",
  CHAPTER_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_cp",
  FEEDBACK_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_fdbk",
  GET_WEBSITE_COURSE: CONFIGS.API_API_ENDPOINT + "course/get_website_courses",
  GET_GRADUATION_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/grad_sts",
  UPLOAD_GRADUATION_DETAIL: CONFIGS.API_API_ENDPOINT + "courselib/u_grd_dtl",

  //nsdc routes
  CHECK_NSDC_DATA: CONFIGS.API_API_ENDPOINT + "courselib/check_nsdc_data",
  SUBMIT_NSDC_DATA: CONFIGS.API_API_ENDPOINT + "courselib/submit_nsdc_data",

  //gradebook routes
  GET_GRADEBOOK_DATA: CONFIGS.API_API_ENDPOINT + "courselib/gt_grades",
  GET_GRADEBOOK_TIERS: CONFIGS.API_API_ENDPOINT + "courselib/gt_tiers",

  //Payment routes
  RAZORPAY_CAPTURE: CONFIGS.API_API_ENDPOINT + "razorpay/capture",
  APPLY_COUPON: CONFIGS.API_API_ENDPOINT + "transaction/a_coupon_v4",
  PENDING_INSTALLMENTS:
    CONFIGS.API_API_ENDPOINT + "course/my_pending_installments",

  //auth routes
  RENEW_ACCESS_TOKEN: CONFIGS.API_API_ENDPOINT + "account/g_at_w",
  LOGOUT: CONFIGS.API_API_ENDPOINT + "auth/logout",
  AUTHENTICATE_COURSE: CONFIGS.API_API_ENDPOINT + "auth/at_crs",
  HCAPTCHA_KEY_FETCH: CONFIGS.API_API_ENDPOINT + "api/fetch_hcaptcha_key",

  // password routes
  GET_OTP: CONFIGS.API_API_ENDPOINT + "account/dashboard_req_otp_v18",
  VERIFY_OTP: CONFIGS.API_API_ENDPOINT + "account/eckhp_v_otp_w/",
  GET_OTP_CALL: CONFIGS.API_API_ENDPOINT + "account/koo",
  RESEND_OTP: CONFIGS.API_API_ENDPOINT + "account/resend_otp",
  // primary email Routes
  IS_EMAIL_VERIFIED: CONFIGS.API_API_ENDPOINT + "account/pe_chk",
  IS_MOBILE_VERIFIED: CONFIGS.API_API_ENDPOINT + "account/m_chk",
  GET_SC_EMAILS: CONFIGS.API_API_ENDPOINT + "account/gt_sc_email", // get all secondary emails
  ADD_NEW_SC_EMAIL: CONFIGS.API_API_ENDPOINT + "account/ad_sc_email", // add new secondary email
  CONFIRM_PRIMARY_EMAIL: CONFIGS.API_API_ENDPOINT + "account/pe_cnf", // confirm primary email
  CONFIRM_MOBILE: CONFIGS.API_API_ENDPOINT + "account/mob_cnf", // confirm primary email
  VERIFY_PRIMARY_EMAIL: CONFIGS.API_API_ENDPOINT + "account/pe_vrfy", // verify primary email
  EMAIL_REQ_OTP: CONFIGS.API_API_ENDPOINT + "account/sc_req_otp", // req otp to remove secondary email
  EMAIL_VERIFY_OTP: CONFIGS.API_API_ENDPOINT + "account/sc_v_otp", // verify email remove otp
  CHANGE_SECONDARY_EMAIL: CONFIGS.API_API_ENDPOINT + "account/sc_updt", // update secondary email to backend'

  //Auth Routes
  OAUTH_LOGIN: CONFIGS.API_API_ENDPOINT + "account/login_oauth",
  SAML_LOGIN: CONFIGS.API_API_ENDPOINT + "api/api/samlassert",
  VERIFY_E_M: CONFIGS.API_API_ENDPOINT + "account/signup_send_otp",
  LOGIN_ROUTE: CONFIGS.API_API_ENDPOINT + "account/login",
  REQUEST_EMAIL_OTP: CONFIGS.API_API_ENDPOINT + "account/req_otp_email",
  REQUEST_MOBILE_OTP: CONFIGS.API_API_ENDPOINT + "account/req_otp_mobile",
  VERIFY_OTPS: CONFIGS.API_API_ENDPOINT + "account/verify_otps",
  SET_PASSWORD: CONFIGS.API_API_ENDPOINT + "account/set_password",
  REMOVE_MOBILE: CONFIGS.API_API_ENDPOINT + "account/remove_mobile",

  //Referral routes
  CREATE_REFERRAL:
    CONFIGS.API_API_ENDPOINT + "referral/referral_lead_and_activity",

  //certificate routes
  UPLOAD_DOCUMENTS_TO_AWS:
    CONFIGS.API_API_ENDPOINT + "courselib/upload_documents_to_aws",
  DOWNLOAD_CRTF: CONFIGS.API_API_ENDPOINT + "courselib/download_crtf",
  GENERATE_CERTIFICATE: CONFIGS.API_API_ENDPOINT + "courselib/gen_crtf",

  DEEP_LINK: CONFIGS.API_ENDPOINT,

  //Brand Routes
  FETCH_BRAND_DATA:
    CONFIGS.API_API_ENDPOINT + "pegasusbrandconfigs/fetch_brand_data",
  FETCH_BRAND_DATA_BY_URL:
    CONFIGS.API_API_ENDPOINT + "pegasusbrandconfigs/fetchBrandDataByReferrer",
  FETCH_BRAND_DATA_CRS:
    CONFIGS.API_API_ENDPOINT + "pegasusbrandconfigs/fetch_brand_data_crs",

  //QUIZ ROUTES
  UPDATE_QUIZ_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_cp_qz",

  //DocLecture Routes
  GET_SIGNED_URL: CONFIGS.API_API_ENDPOINT + "media/g_crs_med",
  UPDATE_DOC_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_cp_doc_lc",

  //getScorm Routes
  GET_SCORM_DATA: CONFIGS.API_API_ENDPOINT + "courselib/gt_scorm_data",
  UPDATE_SCORM_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_sc",

  //assignment routes
  UPDATE_ASSIGNMENT_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_cp_ag",
  UPDATE_EXTERNAL_ASSIGNMENT_STATUS:
    CONFIGS.API_API_ENDPOINT + "courselib/o_ex_as",
  SUBMIT_ASSIGNMENT: CONFIGS.API_API_ENDPOINT + "courselib/s_ag",
  FETCH_SKILL_LIST: CONFIGS.API_API_ENDPOINT + "courselib/gt_skill_data",
  UPLOAD_DOCUMENTS_TO_AWS_2:
    CONFIGS.API_API_ENDPOINT + "courselib/upload_documents_to_aws_2",

  //lecture routes
  UPDATE_LECTURE_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_lc",
  UPDATE_LIV_LEC_STATUS: CONFIGS.API_API_ENDPOINT + "courselib/o_liv_lec",
  UPDATE_LECTURE_BOOKMARK: CONFIGS.API_API_ENDPOINT + "courselib/updt_bkmrk",
  GET_LECTURE_BOOKMARK: CONFIGS.API_API_ENDPOINT + "courselib/gt_bkmrk",
  GET_CHAPTER_BOOKMARK: CONFIGS.API_API_ENDPOINT + "courselib/gt_bkmrk_cp",
  DELETE_BOOKMARK: CONFIGS.API_API_ENDPOINT + "courselib/rm_bkmrk",
  SUBMIT_IVQ: CONFIGS.API_API_ENDPOINT + "courselib/user_response_ivq",
  COMPLETE_FEEDBACK_LECTURE: CONFIGS.API_API_ENDPOINT + "courselib/o_fdbk_lec",
  COMPLETE_FEEDBACK_LIVE_LECTURE:
    CONFIGS.API_API_ENDPOINT + "courselib/o_fdbk_liv_lec",
  GET_LECTURE_TIMING: CONFIGS.API_API_ENDPOINT + "courselib/gt_lc_time",

  //home routes
  GET_COURSES: CONFIGS.API_API_ENDPOINT + "plugin/g_pl_cf",

  //verify certificates routes
  VERIFY_CERTIFICATE: CONFIGS.API_API_ENDPOINT + "search/vf_crty",

  GET_ATTENDANCE_METRICS: CONFIGS.API_ENDPOINT + "courselib/gt_attnce_mtrcs",
  GET_ATTENDANCE_DATA: CONFIGS.API_ENDPOINT + "courselib/gt_attnce_data",
  GET_CALENDAR_DATA: CONFIGS.API_ENDPOINT + "courselib/gt_clndr_data",
  SAVE_PROFILE_DETAILS: CONFIGS.API_ENDPOINT + "profile/sv_prfl_dtls",

  // Agreement routes
  GET_ALL_AGREEMENTS: CONFIGS.API_ENDPOINT + "courselib/get_all_agreements",
  AGREEMENT_RESPONSE: CONFIGS.API_ENDPOINT + "courselib/agreement_response",
  REACCEPT_AGREEMENT: CONFIGS.API_ENDPOINT + "courselib/reaccept_agreement",
  CHECK_AGREEMENT_STATUSES:
    CONFIGS.API_ENDPOINT + "courselib/checkAgreementStatus",
  GET_S_AGREEMENT_R:
    CONFIGS.API_ENDPOINT + "courselib/get_single_agreement_resp",
  STUDENT_REPONSES:
    CONFIGS.API_ENDPOINT + "courselib/get_all_agreement_responses",
};
