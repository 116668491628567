import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import Spinner from "../utils/Spinner";
import closeImg from "../../images/close-btn.svg";
import ImageCrop from "../utils/ImageCrop";
import ReactCropper from "../utils/ProfilePicCropper";
import { KEY_CODES, URLS } from "../../constants/constants";
import {
  toggleJoinGroupModal,
  cancelJoining,
} from "../../actions/joinGroupAction";
import {
  deletePPicBackend,
  updatePicURL,
  updateProfileDetailsToBackend,
} from "../../actions/profileAction";
import { showPopover } from "../../actions/utilAction";

const EditProfileModal = ({
  open,
  toggleEditModal,
  setOpenProfileEditModal,
}) => {
  const dispatch = useDispatch();

  const ppic = useSelector((state) => state.profile.ppic);
  const croppedPpic = useSelector((state) => state.profile.cropped_ppic);
  const ppic_ext = useSelector((state) => state.profile.ppic_ext);
  const editPpic = useSelector((state) => state.profile.edit_ppic);

  const [crop, setCrop] = useState({});
  const [originalPic, setOriginalPic] = useState("");
  const [currentPic, setCurrentPic] = useState("");

  useEffect(() => {
    setCurrentPic(ppic);
  }, [ppic]);

  const keydownHandler = useCallback(
    (e) => {
      if (e.keyCode === KEY_CODES.ESC_KEY && open) {
        dispatch(updatePicURL(originalPic));
        toggleEditModal();
      }
    },
    [dispatch, originalPic, open, toggleEditModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [keydownHandler]);

  const isImageCropped = () => {
    return crop && crop.width > 0 && crop.height > 0;
  };

  const choosePic = () => {
    const fileInput = document.getElementById("edit-file");
    if (fileInput) fileInput.click();
  };

  const saveProfilePic = () => {
    dispatch(
      updateProfileDetailsToBackend(
        { ppic: croppedPpic, ppic_ext: ppic_ext },
        []
      )
    );
    toggleEditModal();
    setOpenProfileEditModal(false);
  };

  const setOriginalPicAndCrop = (originalPic, crop) => {
    setOriginalPic(originalPic);
    setCrop(crop);
  };

  return (
    <div>
      <Modal
        isOpen={open}
        className="edit-modal-container editPopup__new_2"
        centered
      >
        <div className="edit-modal">
          <p className="modal-header">
            Edit Photo{" "}
            <img
              className="close-btn"
              onClick={toggleEditModal}
              src={closeImg}
              alt="Close"
            />
          </p>
          <div className="img-container">
            <div
              className={
                editPpic === ppic
                  ? "edit-modal-cover hide"
                  : "edit-modal-cover hide"
              }
            />
          </div>
          <ReactCropper setOriginalPicAndCrop={setOriginalPicAndCrop} />
          <p className="valid-msg">
            Update your profile picture for a more personalized experience.
          </p>
          <div className="footer">
            <button
              onClick={() => {
                toggleEditModal();
                setOpenProfileEditModal(false);
                dispatch(deletePPicBackend([]));
              }}
              className={ppic !== URLS.DEFAULT_PPIC_URL ? "del-btn" : "hide"}
              id="edit-modal-del-btn"
            >
              Delete Photo
            </button>
            <button
              onClick={saveProfilePic}
              className="save-btn"
              disabled={ppic === editPpic}
            >
              Save
            </button>
            <button onClick={choosePic} className="change-btn">
              Choose Photo
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditProfileModal;
