import React, { Component } from "react";
import { Input } from "reactstrap";
import backArrow from "../../images/backArrow.svg";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { utils } from "../../utility/utils";
import { withRouter } from "react-router-dom";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OtpInput from "./OTPInput";

let errorMessages = {
  incorrectNumber: "Mobile number already exists.",
  incorrectEmail:
    "Email already exists. Kindly try to login with this mail ID or enter a different mail ID.",
  invalidOTP: "Invalid OTP",
  tooManyRequests: "Too Many Requests, try Again later.",
  mVerified: "Your Mobile has been verified and your account has been created.",
  eVerified:
    "Your Email ID has been verified and your account has been created.",
};

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backFunction: this.props.backFunction,
      email: this.props.email ? this.props.email : "",
      mobile: this.props.mobile ? this.props.mobile : "",
      ccod: this.props.ccod ? this.props.ccod : "",
      isMobile: this.props.isMobile ? this.props.isMobile : false,
      isEmail: this.props.isEmail ? this.props.isEmail : false,
      incorrectEmail: false,
      incorrectNumber: false,
      invalidOTP_m: false,
      invalidOTP_e: false,
      emailEndTime: 5 * 60,
      mobileEndTime: 5 * 60,
      mobileOtp: "",
      emailOtp: "",
      aid: this.props.aid,
      // isLoading: false,
      tooManyRequests: false,
      mVerified: false,
      eVerified: false,
    };
    window.FreshworksWidget?.("hide");
    this.getEmailTimeRemaining();
    this.getMobileTimeRemaining();
  }

  validateEmail = (email) => {
    return (
      email.length <= 60 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 5;
    return mobile.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  RequestOTPForEmail = () => {
    let email = this.state.email;

    if (!this.validateEmail(email)) return;
    let dataObj = {
      email,
    };
    // this.setState({ isLoading: true });
    this.props.loader(true);
    Axios.post(ROUTES.REQUEST_EMAIL_OTP, dataObj)
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          emailEndTime: 5 * 60,
          incorrectEmail: false,
          incorrectNumber: false,
          invalidOTP_m: false,
          invalidOTP_e: false,
          // isLoading: false,
        });
        this.props.loader(false);
      })
      .catch((err) => {
        this.state.backFunction();
      });
  };

  RequestOTPForMobile = () => {
    let mobile = this.state.mobile;
    let c_code = this.state.ccod;

    if (!this.validateMobile(mobile)) return;
    let dataObj = {
      mobile,
      c_code,
    };
    // this.setState({ isLoading: true });
    this.props.loader(true);

    Axios.post(ROUTES.REQUEST_MOBILE_OTP, dataObj)
      .then((res) => {
        console.log(res.data);
        this.setState({
          mobileEndTime: 5 * 60,
          incorrectEmail: false,
          incorrectNumber: false,
          invalidOTP_m: false,
          invalidOTP_e: false,
          // isLoading: false,
        });
        this.props.loader(false);
      })
      .catch((err) => {
        this.state.backFunction();
      });
  };

  resendMobileOTP = () => {
    if (this.state.mobileEndTime > 60) return;
    console.log("sent mobile otp");
    this.RequestOTPForMobile();
    this.setState({
      mobileEndTime: 5 * 60,
    });
  };

  resendEmailOTP = () => {
    if (this.state.emailEndTime > 60) return;
    console.log("sent email otp");
    this.RequestOTPForEmail();
    this.setState({
      emailEndTime: 5 * 60,
    });
  };

  validateMobileOTP = () => {
    return !this.state.isMobile || this.state.mobileOtp.length == 6;
  };

  validateEmailOTP = () => {
    return !this.state.isEmail || this.state.emailOtp.length == 6;
  };

  formValidation = () => {
    return this.validateMobileOTP() && this.validateEmailOTP();
  };

  mobileChange = (e) => {
    console.log({ e });
    let otp = e;
    // let otp = e.target.value;
    if (otp.length > 6) return;
    let regExp = new RegExp("^[0-9]*$");
    if (otp != "" && !regExp.test(otp)) {
      return;
    }
    this.setState({
      mobileOtp: otp,
      invalidOTP_m: false,
    });
  };

  emailChange = (e) => {
    console.log({ e });
    let otp = e;
    // let otp = e.target.value;
    if (otp.length > 6) return;
    let regExp = new RegExp("^[0-9]*$");
    if (otp != "" && !regExp.test(otp)) {
      return;
    }
    this.setState({
      emailOtp: otp,
      invalidOTP_e: false,
    });
  };

  getEmailTimeRemaining = () => {
    setTimeout(() => {
      this.setState({
        emailEndTime:
          this.state.emailEndTime > 0 ? this.state.emailEndTime - 1 : 0,
      });
      this.getEmailTimeRemaining();
    }, 1000);
  };

  getMobileTimeRemaining = () => {
    setTimeout(() => {
      this.setState({
        mobileEndTime:
          this.state.mobileEndTime > 0 ? this.state.mobileEndTime - 1 : 0,
      });
      this.getMobileTimeRemaining();
    }, 1000);
  };

  verifyOTPs = () => {
    let dataObj = {
      aid: this.state.aid,
    };

    if (this.state.isEmail && !this.state.eVerified) {
      dataObj.e_otp = this.state.emailOtp;
    }

    if (this.state.isMobile && !this.state.mVerified) {
      dataObj.m_otp = this.state.mobileOtp;
    }

    console.log(dataObj);
    // this.setState({ isLoading: true });
    this.props.loader(true);

    Axios.post(ROUTES.VERIFY_OTPS, dataObj)
      .then((res) => {
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = res.data.data;

        // utils.setLoginCookies(
        //   a_t,
        //   aid,
        //   secret,
        //   rt,
        //   true,
        //   pid,
        //   sid,
        //   user,
        //   Profile[0]
        // );

        window.FreshworksWidget?.("show");
        if (utils.getLoggedIn()) this.props.history.push("/dashboard");
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 301) {
          if (this.state.isMobile) {
            this.setState({ mVerified: true });
          }
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP_m: false,
            invalidOTP_e: this.state.isEmail,
            // isLoading: false,
            tooManyRequests: false,
          });
          this.props.loader(false);
        } else if (ecode == 6100) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP_m: false,
            invalidOTP_e: false,
            // isLoading: false,
            tooManyRequests: true,
          });
          this.props.loader(false);
        } else if (ecode == 302) {
          if (this.state.isEmail) {
            this.setState({ eVerified: true });
          }
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP_m: this.state.isMobile,
            invalidOTP_e: false,
            // isLoading: false,
            tooManyRequests: false,
          });
          this.props.loader(false);
        } else if (ecode == 300) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP_m: this.state.isMobile,
            invalidOTP_e: this.state.isEmail,
            // isLoading: false,
            tooManyRequests: false,
          });
          this.props.loader(false);
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            // isLoading: false,
          });
          this.props.loader(false);
        }
      });
  };

  render() {
    return this.props.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div>
        <div className="loginBackBtn">
          <Link
            to={
              (this.state.brand ? `/${this.state.brand.utag}` : "") +
              "/login" +
              (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
            }
            className="reset-back"
            style={{ cursor: "pointer" }}
          >
            <img src={backArrow} alt=""></img>
          </Link>
        </div>
        <div
          className="auth-h"
          style={{ textAlign: "left", fontSize: "32px", marginTop: "1rem" }}
        >
          Verify your details
        </div>
        {this.state.isEmail && (
          <div
            className="auth-box"
            style={{
              textAlign: "left",
            }}
          >
            <div className="auth-ttl">
              Enter OTP sent on{" "}
              <p style={{ display: "inline" }} className="green">
                {this.state.email}
              </p>
            </div>
            <div className="auth-container">
              {/* <Input
                type="text"
                className="auth-inp"
                onChange={this.emailChange}
                value={this.state.emailOtp}
                disabled={this.state.eVerified}
                style={this.state.eVerified ? { background: "#00a4001f" } : {}}
              ></Input> */}
              <OtpInput length={6} onChangeOtp={this.emailChange} />
              {/* <div
                className={`line ${
                  this.validateEmailOTP() && !this.state.invalidOTP_e
                    ? "lgreen"
                    : "lred"
                }`}
              ></div> */}
            </div>
            {this.state.invalidOTP_e && (
              <div className="auth-error">{errorMessages["invalidOTP"]}</div>
            )}
            {this.state.eVerified && (
              <div className="auth-success">{errorMessages["eVerified"]}</div>
            )}
            {this.state.customError && (
              <div className="auth-error">{this.state.customError}</div>
            )}
            <div
              className="resend-otp-block"
              style={{
                marginTop: "16px",
              }}
            >
              {this.state.emailEndTime < 60 ? (
                <div
                  style={{ cursor: "pointer" }}
                  className="auth-txt"
                  onClick={this.resendEmailOTP}
                >
                  Resend OTP
                </div>
              ) : (
                <div className="auth-txt"></div>
              )}
              <div className="auth-timer">
                {`Remaining Time: ${Math.floor(
                  this.state.emailEndTime / 60
                )}:${("0" + String(this.state.emailEndTime % 60)).slice(-2)}`}
              </div>
            </div>
          </div>
        )}

        {this.state.isMobile && (
          <div className="auth-box">
            <div
              className="auth-ttl"
              style={{
                textAlign: "left",
              }}
            >
              Enter OTP sent on{" "}
              <p style={{ display: "inline" }} className="green">
                {this.state.mobile}
              </p>
            </div>
            <div className="auth-container">
              {/* <Input
                type="text"
                className="auth-inp"
                onChange={this.mobileChange}
                value={this.state.mobileOtp}
                disabled={this.state.mVerified}
                style={this.state.mVerified ? { background: "#00a4001f" } : {}}
              ></Input> */}
              <OtpInput length={6} onChangeOtp={this.mobileChange} />

              {/* <div
                className={`line ${
                  this.validateMobileOTP() && !this.state.invalidOTP_m
                    ? "lgreen"
                    : "lred"
                }`}
              ></div> */}
            </div>
            {this.state.invalidOTP_m && (
              <div className="auth-error">{errorMessages["invalidOTP"]}</div>
            )}
            {this.state.customError && (
              <div className="auth-error">{this.state.customError}</div>
            )}
            {this.state.mVerified && (
              <div className="auth-success">{errorMessages["mVerified"]}</div>
            )}
            <div
              className="resend-otp-block"
              style={{
                marginTop: "16px",
              }}
            >
              {this.state.mobileEndTime < 60 ? (
                <div
                  style={{ cursor: "pointer" }}
                  className="auth-txt"
                  onClick={this.resendMobileOTP}
                >
                  Resend OTP
                </div>
              ) : (
                <div className="auth-txt"></div>
              )}
              <div className="auth-timer">
                {`Remaining Time: ${Math.floor(
                  this.state.mobileEndTime / 60
                )}:${("0" + String(this.state.mobileEndTime % 60)).slice(-2)}`}
              </div>
            </div>
          </div>
        )}

        <div
          className={`auth-btn ${this.formValidation() ? "" : "disable"}`}
          onClick={this.formValidation() ? this.verifyOTPs : undefined}
        >
          {"Verify OTP"}
        </div>
        {this.state.tooManyRequests && (
          <div className="auth-error">{errorMessages["tooManyRequests"]}</div>
        )}
      </div>
    );
  }
}

export default withRouter(Verify);
