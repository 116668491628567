import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import small_left from "../../../images/left-arr-white.svg";
import { connect } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { CONFIGS, GENDER_DROPDOWN } from "../../../constants/constants";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../utils/Loader.js";
import "./placementprofile.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormText,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import Profile_Edit from "../../../images/Profile_Edit.svg";
import { CCODS, TABS } from "../../../constants/constants";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveProfileDetails,
} from "../../../actions/profileAction";
import { fetchMyCourses } from "../../../actions/courseAction";
import { setCurrentTab } from "../../../actions/utilAction";
import { uploadUserProfilePic } from "../../../actions/profileAction";

const BasicDetails = (props) => {
  console.log({ Myprops: props });
  const history = useHistory();
  let profile = props.profile;
  let courses = props.courses;
  let selected_course = props.selected_course;
  let fileInput = useRef(null);
  const location = useLocation();
  console.log({ selected_course });
  console.log({ profile });
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    aboutme: "",
    gender: "",
  });
  const [percCompleted, setPercCompleted] = useState(0);
  const [crs_pg_id, setCrsPgId] = useState(props.crs_pg_id);
  const [initialLoading, setIntitalLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [errorMessageGender, setErrorMessageGender] = useState("");
  const [errorMessageAboutMe, setErrorMessageAboutMe] = useState("");
  const [editable, setEditable] = useState(false);
  console.log({ stateVariable: crs_pg_id });
  let anyInvalid = false;

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const searchParams = new URLSearchParams(location.search);

  const validateBasicDetails = () => {
    validateGender();
    validateAboutMe();
  };

  const validateGender = () => {
    if (formData.gender && formData.gender === "0") {
      setErrorMessageGender("Gender cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGender("");
    }
  };
  const validateAboutMe = () => {
    if (formData.aboutme.trim() === "") {
      setErrorMessageAboutMe("About Me can't be blank");
      anyInvalid = true;
    } else if (formData.aboutme.trim().length > 300) {
      setErrorMessageAboutMe("About Me can't exceed 300 characters");
      anyInvalid = true;
    } else {
      setErrorMessageAboutMe("");
    }
  };

  const handleSaveBasicDetails = () => {
    console.log("Inside handle save");
    console.log({ anyInvalid });
    validateBasicDetails();
    console.log({ anyInvalid });
    if (!anyInvalid) {
      console.log({ anyInvalid });
      saveBasicDetails();
    } else {
      console.log({ anyInvalid });
      window.alert("Please fill all the required details before saving.");
    }

    setTimeout(() => {
      console.log({ anyInvalid });
    }, 3000);
  };

  const saveBasicDetails = async () => {
    if (formData.aboutme.trim() == "") {
      window.alert("About Me can't be blank");
      return;
    }
    if (formData.gender.trim() == "") {
      window.alert("Gender can't be blank");
      return;
    }
    saveProfileDetails(crs_pg_id, "basic-details", formData).then((res) => {
      if (res && res.data.success) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    setIntitalLoading(true);
  }, []);

  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfile("basic-details", crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          if (res && res.learnerInfo) {
            setFormData({
              aboutme: res.learnerInfo.aboutme ? res.learnerInfo.aboutme : "",
              gender: res.learnerInfo.gender ? res.learnerInfo.gender : "",
            });
            setLastFilledBlockIndex(res.lastFilledBlockIndex);
          }
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);
  return (
    <>
      {!loading ? (
        <>
          <p className="edit-profile-heading">
            <span style={{ fontSize: "20px" }}>Additional Details </span>
            <span
              onClick={(e) => setEditable(!editable)}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                border: "solid 1px",
                borderRadius: "8px",
                borderColor: "rgba(60,72,82,0.8)",
                fontSize: "12px",
                padding: "8px 12px",
                color: "rgba(60,72,82,0.8)",
              }}
            >
              <img width="12px" height="12px" src={Profile_Edit} alt="" />
              Edit
            </span>
          </p>
          <div className="container-fluid dashboard-content-2 p-0 m-0">
            <div className=" profile-setting-container-row p-0 m-0">
              <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 m-0">
                <div className="justify-content-center">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                    <div className="edit-window-profile-details">
                      <li className="row">
                        <div className="col-md-6 p-0 ">
                          <h5
                            class="modal-title profile-heading"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Gender
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </h5>
                          <FormGroup>
                            <div style={{ display: "flex" }}>
                              <select
                                disabled={!editable}
                                style={{ width: "98%" }}
                                className="form-control"
                                onChange={(e) =>
                                  handleFormChange("gender", e.target.value)
                                }
                                value={formData.gender}
                                // disabled={gender != ""}
                              >
                                {GENDER_DROPDOWN.map((cc, i) => {
                                  return (
                                    <option
                                      // key={cc.code + String(i)}

                                      value={cc.value}
                                    >
                                      {cc.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorMessageGender != "" && (
                              <FormText color="danger">
                                {errorMessageGender}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                      <li className="row p-0">
                        <div className="col-md-12 p-0">
                          <h5
                            class="modal-title profile-heading"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            About Me
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </h5>
                          <FormGroup
                            style={{
                              width: "100%",
                            }}
                          >
                            <Input
                              disabled={!editable}
                              type="textarea"
                              name="text"
                              id="name"
                              value={formData.aboutme}
                              onChange={(e) =>
                                handleFormChange("aboutme", e.target.value)
                              }
                              style={{ width: "100%" }}
                              rows={3}
                            />
                            {errorMessageAboutMe != "" && (
                              <FormText color="danger">
                                {errorMessageAboutMe}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editable && (
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 profile-next-btn">
                  <div className="addcrit-footer">
                    <Row className="adques-footer">
                      <Col md={2}></Col>
                      <Col md={3}></Col>
                      <Col md={1}></Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={2}>
                        <Button
                          className="jobs-table-btn-5"
                          onClick={() => handleSaveBasicDetails()}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, fetchMyCourses, setCurrentTab };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicDetails)
);
